import { CapacitorException } from '@capacitor/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CameraPreviewFlashMode } from '@capacitor-community/camera-preview';

import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { PictureFileType } from 'common/Types/Entities/PictureFile/PictureFileDto';

import { ShowHideAnimator } from '../../classes/Animation/ShowHideAnimator';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import {
  GetEntityInfos,
  PictureCreatorService
} from '../../classes/Picture/PictureCreatorService';
import { CameraOverlay } from '../camera-overlay/camera-overlay';
import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { FileUtils } from '../../classes/Utils/FileUtils/FileUtils';
import { PictureFileLocalFilesService } from '../../classes/EntityManager/entities/PictureFile/PictureFileLocalFilesService';
import { NativeCameraSuspendHelper } from './NativeCameraSuspendHelper';
import { NotificationHelper } from '../../classes/NotificationHelper';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

/**
 * embeds a camera if the native camera can't be used
 *
 * this is meant to be a single global instance (since this is fullscreen anyways)
 * so only append this once to the root of you application and call everything with the static functions
 *
 * @aggregatorevent embedded-camera:data-url-picture-available
 * @aggregatorevent embedded-camera:camera-closed - gets fired when the camera got closed by the user
 */
@autoinject()
export class EmbeddedCamera {
  private static lastDataUrlPicture: string | null = null;

  private static instance: EmbeddedCamera | null = null;

  private options: EmbeddedCameraCapturePictureOptions | null = null;

  private domElement: HTMLElement;

  private eventAggregator: EventAggregator;

  private showHideAnimator: ShowHideAnimator | null = null;

  protected cameraOverlay: CameraOverlay | null = null;

  private suspendHelper: NativeCameraSuspendHelper<EmbeddedCameraCapturePictureOptions | null>;

  protected availableFlashModes: Array<CameraPreviewFlashMode> = [];
  protected currentFlashMode: CameraPreviewFlashMode | null = null;

  private subscriptionManager: SubscriptionManager;

  /**
   * if no entity is given, the picture will be available as a dataUrl
   */
  public static async capturePicture(
    options: EmbeddedCameraCapturePictureOptions
  ): Promise<void> {
    // @ts-ignore - instance is always available
    await this.instance.capturePicture(options);
  }

  public static close(): void {
    // @ts-ignore - instance is always available
    this.instance.close();
  }

  public static hasLastDataUrlPicture(): boolean {
    return this.lastDataUrlPicture != null;
  }

  /**
   * retrieves the picture once, which will delete it's reference here to save some space
   */
  public static getLastDataUriPictureOnce(): string | null {
    const data = this.lastDataUrlPicture;
    this.lastDataUrlPicture = null;
    return data;
  }

  constructor(
    element: Element,
    eventAggregator: EventAggregator,
    private readonly i18n: I18N,
    private readonly entityManager: AppEntityManager,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly pictureFileLocalFilesService: PictureFileLocalFilesService,
    private readonly pictureCreatorService: PictureCreatorService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.domElement = element as HTMLElement;
    this.eventAggregator = eventAggregator;
    this.suspendHelper = new NativeCameraSuspendHelper({
      successCallback: this.nativeCameraSuccessCallback.bind(this),
      getCameraOptions: () => this.options,
      setCameraOptions: (options) => {
        this.options = options;
      }
    });

    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    if (EmbeddedCamera.instance) {
      console.error(
        'The EmbeddedCamera should only be included once in the page! Still replacing the existing instance'
      );
    }

    EmbeddedCamera.instance = this;

    this.showHideAnimator = new ShowHideAnimator(this.domElement);

    assertNotNullOrUndefined(
      this.cameraOverlay,
      'camera overlay is not attached'
    );
    this.subscriptionManager.addDisposable(
      this.cameraOverlay.subscribeToAvailableFlashModeChanges(
        ({ availableFlashModes }) => {
          this.availableFlashModes = availableFlashModes;
        }
      ),
      this.cameraOverlay.subscribeToCurrentFlashModeChanges(
        ({ currentFlashMode }) => {
          this.currentFlashMode = currentFlashMode;
        }
      )
    );
  }

  protected detached(): void {
    EmbeddedCamera.instance = null;
    this.subscriptionManager.disposeSubscriptions();
  }

  public async capturePicture(
    options: EmbeddedCameraCapturePictureOptions
  ): Promise<void> {
    this.options = options;

    if (this.useNativeCamera()) {
      await this.capturePictureWithNativeCamera();
    } else {
      await this.capturePictureWithCameraOverlay(options);
    }
  }

  public close(): void {
    void this.cameraOverlay?.close();
    this.reset();

    void this.showHideAnimator?.fadeOut();
  }

  protected handleCancelClick(): void {
    this.eventAggregator.publish('embedded-camera:camera-closed');
    this.close();
  }

  protected handleSwitchCameraClick(): void {
    void this.cameraOverlay?.switchStream();
  }

  protected handleCapturePictureClick(): void {
    const cameraOverlay = this.cameraOverlay;
    assertNotNullOrUndefined(
      cameraOverlay,
      'cannot capture picture without a camera-overlay'
    );

    void this.handlePictureCaptured(
      async (picture) => {
        const dataUrl = await cameraOverlay.takePicture();
        this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
          picture,
          dataUrl
        );
      },
      async () => {
        return cameraOverlay.takePicture();
      }
    ).then(() => {
      this.close();
    });
  }

  private reset(): void {
    this.options = null;
  }

  private useNativeCamera(): boolean {
    if (
      DeviceInfoHelper.isApp() &&
      !this.activeUserCompanySettingService.getSettingProperty(
        'ultraRapidFireWidget.useCameraOverlay'
      )
    ) {
      return true;
    }

    return false;
  }

  private async capturePictureWithCameraOverlay(
    options: EmbeddedCameraCapturePictureOptions
  ): Promise<void> {
    this.options = options;

    void this.cameraOverlay?.open();

    void this.showHideAnimator?.fadeIn();
  }

  private async capturePictureWithNativeCamera(): Promise<void> {
    await this.suspendHelper.saveState();

    try {
      const result = await Camera.getPhoto({
        quality: 80,
        allowEditing: false,
        correctOrientation: true,
        width: 2400,
        height: 2400,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera
      });

      if (result.path) this.nativeCameraSuccessCallback(result.path);
    } catch (error) {
      this.nativeCameraErrorCallback(error);
    }
  }

  private nativeCameraSuccessCallback(fileUri: string): void {
    void this.suspendHelper.deleteState();

    void this.handlePictureCaptured(
      async (picture) => {
        const pictureFile =
          this.entityManager.pictureFileRepository.createPictureFileForPicture(
            picture,
            PictureFileType.ORIGINAL
          );
        await this.pictureFileLocalFilesService.moveLocalFile(
          pictureFile,
          fileUri
        );
      },
      async () => {
        const dataUrl = await FileUtils.readFilePathAsDataUrl(fileUri);
        void FileUtils.deleteEntry(fileUri);
        return dataUrl;
      }
    );
  }

  private nativeCameraErrorCallback(error: unknown): void {
    this.eventAggregator.publish('embedded-camera:camera-closed');

    if (
      error instanceof CapacitorException &&
      error.message === 'User cancelled photos app'
    ) {
      return;
    }

    NotificationHelper.notifyDanger(
      this.i18n.tr('aureliaComponents.embeddedCamera.cameraError')
    );
    throw error;
  }

  /**
   * the save handler needs to create the original pictureFile and save the contents in there
   */
  private async handlePictureCaptured(
    saveHandler: (picture: Picture) => Promise<void>,
    generateBase64: () => Promise<string>
  ): Promise<void> {
    let picture;
    let pictures;

    if (this.options && this.options.getEntityInfos) {
      picture = this.pictureCreatorService
        .withEntityInfos(this.options.getEntityInfos)
        .createPicture();
      await saveHandler(picture);
      const entityInfos = this.options.getEntityInfos();
      pictures = this.entityManager.pictureRepository.getByEntityId(
        entityInfos.mainEntityIdField,
        entityInfos.mainEntityId,
        entityInfos.subEntityField,
        entityInfos.subEntityValue
      );
    } else {
      EmbeddedCamera.lastDataUrlPicture = await generateBase64();
      this.eventAggregator.publish(
        'embedded-camera:data-url-picture-available'
      );
    }
    this.options?.onCapture?.();

    if (picture && pictures) {
      this.autoSelectPicture(picture, pictures);
    }
  }

  /**
   * @param picture
   * @param pictures - all pictures on the same level/scope as the picture
   */
  private autoSelectPicture(picture: Picture, pictures: Array<Picture>): void {
    const selectedPicture = pictures.find((p) => p.selected);
    if (!selectedPicture) {
      // only auto select the picture
      this.entityManager.pictureRepository.setSelectedPicture(
        picture,
        pictures
      );
    }
  }

  protected handleChangeFlashMode(): void {
    void this.cameraOverlay?.switchFlashMode();
  }
}

/*
 * mainEntityIdField' designates the entity to which the picture will belong (can be a project or a thing at the moment)
 * the (optional) 'subEntityField' and 'subEntityValue' allow the assignment to another property of the picture
 * (e.g. entry, property, picture_of_project, is_global_project_picture, ...)
 */
export type EmbeddedCameraCapturePictureOptions = {
  getEntityInfos?: GetEntityInfos;
  /**
   * Callback called when a picture is captured.
   */
  onCapture?: () => void;

  /**
   * Extra buttons to show next to the capture button
   *
   * Only works in the browser implementation. This will be ignored for the native camera
   */
  buttons?: Array<EmbeddedCameraAdditionalButton>;
};

export type EmbeddedCameraAdditionalButton = {
  iconName: string;
  iconType: string;
  onClick: () => void;
};
