import { assertNotNullOrUndefined } from 'common/Asserts';
import { ArrayUtils } from 'common/Utils/ArrayUtils';
import { AppEntityManager } from '../EntityManager/entities/AppEntityManager';
import { PropertyCreationBaseData } from '../EntityManager/entities/Property/types';
import { ThingTag } from '../EntityManager/entities/Tag/types';
import { GalleryThingPictureOverviewEntry } from './GalleryThingPictureOverviewEntryHelper';

// TODO: KRP-143 add permission checking for usages
export class GalleryThingPictureBulkEditHelper {
  constructor(
    private readonly bulkEditOptions: GalleryThingPictureBulkEditOptions | null,
    private readonly entityManager: AppEntityManager
  ) {}

  public bulkEditPictures(
    pictureOverviewEntries: Array<GalleryThingPictureOverviewEntry>
  ): void {
    pictureOverviewEntries.forEach((pe) => {
      if (this.bulkEditOptions?.regionId)
        this.editPictureRegionId(pe, this.bulkEditOptions.regionId);
      if (
        this.bulkEditOptions?.personIds &&
        this.bulkEditOptions.personIds.length > 0
      )
        this.editPicturePersonIds(pe, this.bulkEditOptions.personIds);
      if (
        this.bulkEditOptions?.properties &&
        this.bulkEditOptions.properties.length > 0
      )
        this.editPictureProperties(pe, this.bulkEditOptions.properties);
      if (this.bulkEditOptions?.tags && this.bulkEditOptions.tags.length > 0)
        this.editPictureTags(pe, this.bulkEditOptions.tags);
    });
  }

  private editPictureRegionId(
    overviewEntry: GalleryThingPictureOverviewEntry,
    regionId: string
  ): void {
    if (!overviewEntry.entryId) return;

    const entry = this.entityManager.entryRepository.getById(
      overviewEntry.entryId
    );
    if (!entry) return;

    entry.regionId = regionId;
    this.entityManager.entryRepository.update(entry);
  }

  private editPictureTags(
    overviewEntry: GalleryThingPictureOverviewEntry,
    newTags: Array<ThingTag>
  ): void {
    if (!overviewEntry.pictureId) return;

    const picture = this.entityManager.pictureRepository.getRequiredById(
      overviewEntry.pictureId
    );

    for (const tagId of newTags.map((t) => t.id)) {
      ArrayUtils.pushUnique(picture.tagIds, tagId);
    }
    this.entityManager.pictureRepository.update(picture);
  }

  private editPicturePersonIds(
    overviewEntry: GalleryThingPictureOverviewEntry,
    personIds: Array<string>
  ): void {
    assertNotNullOrUndefined(
      overviewEntry.projectId,
      "can't GalleryThingPictureBulkEditHelper.editPicturePersonIds without a ownerProjectId"
    );
    const entryId = overviewEntry.entryId;
    const projectId = overviewEntry.projectId;
    if (!entryId || !projectId) return;

    const entryToPersons =
      this.entityManager.entryToPersonRepository.getByEntryId(entryId);

    // check and add missing relations
    personIds.forEach((personId) => {
      if (
        entryToPersons.findIndex(
          (entryToPerson) => entryToPerson.personId === personId
        ) >= 0
      )
        return;
      this.entityManager.entryToPersonRepository.create({
        entryId: entryId,
        personId: personId,
        ownerUserGroupId: overviewEntry.ownerUserGroupId,
        ownerProjectId: projectId
      });
    });

    // remove superfluous relations
    entryToPersons.forEach((entryToPerson) => {
      if (personIds.includes(entryToPerson.personId)) return;
      this.entityManager.entryToPersonRepository.delete(entryToPerson);
    });
  }

  private editPictureProperties(
    overviewEntry: GalleryThingPictureOverviewEntry,
    properties: Array<PropertyCreationBaseData>
  ): void {
    assertNotNullOrUndefined(
      overviewEntry.projectId,
      "can't GalleryThingPictureBulkEditHelper.editPictureProperties without a ownerProjectId"
    );
    const entryId = overviewEntry.entryId;
    const projectId = overviewEntry.projectId;
    if (!entryId || !projectId) return;

    const entryProperties =
      this.entityManager.propertyRepository.getByEntryId(entryId);

    properties.forEach((property) => {
      const entryProperty = entryProperties.find(
        (entryProp) =>
          entryProp.name === property.name && entryProp.type === property.type
      );
      if (!entryProperty) {
        this.entityManager.propertyRepository.create({
          ...property,
          entry: entryId,
          ownerUserGroupId: overviewEntry.ownerUserGroupId,
          ownerProjectId: projectId
        });
      } else {
        entryProperty.value = property.value ?? null;
        this.entityManager.propertyRepository.update(entryProperty);
      }
    });
  }

  public static createEmptyBulkEditOptions(): GalleryThingPictureBulkEditOptions {
    return {
      regionId: null,
      personIds: [],
      properties: [],
      tags: []
    };
  }
}

export type GalleryThingPictureBulkEditOptions = {
  regionId: string | null;
  personIds: Array<string> | null;
  properties: Array<PropertyCreationBaseData>;
  tags: Array<ThingTag>;
};
