import { autoinject } from 'aurelia-dependency-injection';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { OriginatedFromPictureInfo } from 'common/Types/Entities/Picture/PictureDto';
import { NumberUtils } from 'common/Utils/NumberUtils';
import { GlobalElements } from '../../aureliaComponents/global-elements/global-elements';
import {
  PositionAndSizeChangedEvent,
  RepositionableElementPositionAndSize
} from '../../aureliaComponents/repositionable-element/repositionable-element';
import { ZoomBox } from '../../aureliaComponents/zoom-box/zoom-box';
import {
  TZoomBoxResizerHelperPicturePositionInfo,
  ZoomBoxPictureResizer
} from '../../aureliaComponents/zoom-box/ZoomBoxPictureResizer';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { ImageHelper } from '../../classes/ImageHelper';
import { InstancePreserver } from '../../classes/InstancePreserver/InstancePreserver';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { RecordItFullScreenDialog } from '../../dialogs/record-it-full-screen-dialog/record-it-full-screen-dialog';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import {
  ExistingCutout,
  ExistingCutoutsAdapter
} from './ExistingCutoutsAdapter/ExistingCutoutsAdapter';

@autoinject()
export class CreatePictureCutoutOverlay {
  public static async open(options: OpenOptions): Promise<void> {
    const view = await GlobalElements.ensureGlobalComponentView(this);
    view.getViewModel().open(options);
  }

  private readonly subscriptionManager: SubscriptionManager;

  private picture: Picture | null = null;
  protected title: string | null = null;
  private onCutoutFinished: OnCutoutFinished | null = null;
  private pictureResizer: ZoomBoxPictureResizer | null = null;
  protected picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null =
    null;
  private cutoutPositionInfo: CutoutPositionInfo =
    this.getDefaultCutoutPositionInfo();
  private existingCutouts: Array<ExistingCutout> = [];

  protected dialog: RecordItFullScreenDialog | null = null;
  protected zoomBoxElement: HTMLElement | null = null;
  protected pictureElement: HTMLElement | null = null;
  protected zoomBox: ZoomBox | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public open(options: OpenOptions): void {
    assertNotNullOrUndefined(
      this.pictureElement,
      "can't CreatePictureCutoutOverlay.open without pictureElement"
    );
    assertNotNullOrUndefined(
      this.zoomBoxElement,
      "can't CreatePictureCutoutOverlay.open without zoomBoxElement"
    );
    assertNotNullOrUndefined(
      this.dialog,
      "can't CreatePictureCutoutOverlay.open without dialog"
    );

    this.picture = options.picture;
    this.title = options.title;
    this.onCutoutFinished = options.onCutoutFinished;
    this.resetCutoutPosition();

    this.dialog.open();

    this.pictureResizer?.destroy();
    this.pictureResizer = new ZoomBoxPictureResizer(
      this.pictureElement,
      this.zoomBoxElement
    );
    this.pictureResizer.onAfterUpdate((picturePositionInfo) => {
      this.picturePositionInfo = picturePositionInfo;
    });
    this.pictureResizer.update();

    if (options.existingCutoutsAdapter) {
      this.subscriptionManager.addDisposable(
        options.existingCutoutsAdapter.subscribe({
          setExistingCutouts: (existingCutouts) => {
            this.existingCutouts = InstancePreserver.createNewArray({
              originalArray: this.existingCutouts,
              newArray: existingCutouts,
              getTrackingValue: (item) => item.trackingKey
            });
          }
        })
      );
    }
  }

  protected handleDialogClosed(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.pictureResizer?.destroy();
    this.pictureResizer = null;
    this.picture = null;
    this.onCutoutFinished = null;
    this.resetCutoutPosition();
  }

  protected handleResetClicked(): void {
    this.zoomBox?.resetZoom();
    this.resetCutoutPosition();
  }

  protected handleCenterCutoutClicked(): void {
    assertNotNullOrUndefined(
      this.zoomBox,
      "can't CreatePictureCutoutOverlay.handleCenterCutoutClicked without zoomBox"
    );
    assertNotNullOrUndefined(
      this.picturePositionInfo,
      "can't CreatePictureCutoutOverlay.handleCenterCutoutClicked without picturePositionInfo"
    );

    const centerPointToTopLeftPicture = this.zoomBox
      .getCenterPoint()
      .clone()
      .substractVector(this.picturePositionInfo.topLeftPosition);

    const defaultCutoutInfo = this.getDefaultCutoutPositionInfo();
    const absoluteCutoutSize = this.picturePositionInfo.size
      .clone()
      .multiplyXY(
        defaultCutoutInfo.width / 100,
        defaultCutoutInfo.height / 100
      );
    const absoluteCutoutTopLeftPosition = centerPointToTopLeftPicture
      .clone()
      .substractVector(absoluteCutoutSize.clone().scale(0.5));

    const percentTopLeftPosition = absoluteCutoutTopLeftPosition
      .clone()
      .divideVector(this.picturePositionInfo.size)
      .scale(100);

    this.setCutoutPositionAndSizeClamped({
      positionAndSize: {
        left: percentTopLeftPosition.getX(),
        top: percentTopLeftPosition.getY(),
        width: defaultCutoutInfo.width,
        height: defaultCutoutInfo.width
      }
    });
  }

  protected handleCancelButtonClicked(): void {
    assertNotNullOrUndefined(
      this.dialog,
      "can't CreatePictureCutoutOverlay.handleCancelButtonClicked without dialog"
    );
    this.dialog.close();
  }

  protected async handleFinishButtonClicked(): Promise<void> {
    assertNotNullOrUndefined(
      this.dialog,
      "can't CreatePictureCutoutOverlay.handleCancelButtonClicked without dialog"
    );
    assertNotNullOrUndefined(
      this.picture,
      "can't CreatePictureCutoutOverlay.handleCancelButtonClicked without picture"
    );

    this.onCutoutFinished?.({
      cutoutDataUrl: await this.createCutoutDataUrl(),
      originatedFromPictureInfo: {
        pictureId: this.picture.id,
        topLeft: {
          x: this.cutoutPositionInfo.left,
          y: this.cutoutPositionInfo.top
        },
        bottomRight: {
          x: this.cutoutPositionInfo.left + this.cutoutPositionInfo.width,
          y: this.cutoutPositionInfo.top + this.cutoutPositionInfo.height
        }
      }
    });

    this.dialog.close();
  }

  protected handlePositionAndSizeChanged(
    $event: PositionAndSizeChangedEvent
  ): void {
    this.setCutoutPositionAndSizeClamped({
      positionAndSize: $event.detail.positionAndSize
    });
  }

  private resetCutoutPosition(): void {
    this.cutoutPositionInfo = this.getDefaultCutoutPositionInfo();
  }

  private getDefaultCutoutPositionInfo(): CutoutPositionInfo {
    return {
      top: 45,
      left: 45,
      width: 10,
      height: 10,
      rotation: 0
    };
  }

  private setCutoutPositionAndSizeClamped({
    positionAndSize
  }: {
    positionAndSize: PositionAndSizeWithoutRotation;
  }): void {
    const clampedPositionAndSize = this.clampPositionAndSize({
      positionAndSize
    });

    this.cutoutPositionInfo.left = clampedPositionAndSize.left;
    this.cutoutPositionInfo.top = clampedPositionAndSize.top;
    this.cutoutPositionInfo.width = clampedPositionAndSize.width;
    this.cutoutPositionInfo.height = clampedPositionAndSize.height;
  }

  /**
   * caps the position and size to the boundaries
   */
  private clampPositionAndSize({
    positionAndSize
  }: {
    positionAndSize: PositionAndSizeWithoutRotation;
  }): PositionAndSizeWithoutRotation {
    const clampedTop = NumberUtils.clamp({
      value: positionAndSize.top,
      min: 0,
      max: 100 - positionAndSize.height
    });

    const clampedLeft = NumberUtils.clamp({
      value: positionAndSize.left,
      min: 0,
      max: 100 - positionAndSize.width
    });

    return {
      top: clampedTop,
      left: clampedLeft,
      width: positionAndSize.width,
      height: positionAndSize.height
    };
  }

  private async createCutoutDataUrl(): Promise<string> {
    assertNotNullOrUndefined(
      this.picture,
      "can't CreatePictureCutoutOverlay.createCutoutDataUrl without picture"
    );

    const pictureFile =
      this.entityManager.pictureFileRepository.getPictureFileToDisplayByPictureId(
        this.picture.id
      );
    assertNotNullOrUndefined(pictureFile, 'no pictureFileToDisplay found');

    const src =
      await this.pictureFilePathService.getPictureFileSource(pictureFile);
    assertNotNullOrUndefined(src, 'no src for the pictureFileToDisplay found');

    const img = await ImageHelper.loadImage(src);
    const absolutePositionInfo = this.getAbsoluteCutoutPositionInfo({ img });

    const canvas = document.createElement('canvas');
    canvas.width = absolutePositionInfo.width;
    canvas.height = absolutePositionInfo.height;

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(ctx, "couldn't create a drawing context");

    ctx.drawImage(
      img,
      absolutePositionInfo.left * -1,
      absolutePositionInfo.top * -1
    );

    return canvas.toDataURL('image/jpeg');
  }

  private getAbsoluteCutoutPositionInfo({
    img
  }: {
    img: HTMLImageElement;
  }): AbsoluteCutoutPositionInfo {
    return {
      height: (img.naturalHeight * this.cutoutPositionInfo.height) / 100,
      width: (img.naturalWidth * this.cutoutPositionInfo.width) / 100,
      left: (img.naturalWidth * this.cutoutPositionInfo.left) / 100,
      top: (img.naturalHeight * this.cutoutPositionInfo.top) / 100
    };
  }

  protected getPictureCoverOverlayStyle(
    picturePositionInfo: TZoomBoxResizerHelperPicturePositionInfo | null
  ): Record<string, any> {
    if (!picturePositionInfo) {
      return {};
    }

    const getPercentSize = (size: number, containerSize: number): string => {
      return `${(100 * size) / containerSize}%`;
    };

    return {
      top: getPercentSize(
        picturePositionInfo.topLeftPosition.getY(),
        picturePositionInfo.containerSize.getY()
      ),
      left: getPercentSize(
        picturePositionInfo.topLeftPosition.getX(),
        picturePositionInfo.containerSize.getX()
      ),
      width: getPercentSize(
        picturePositionInfo.size.getX(),
        picturePositionInfo.containerSize.getX()
      ),
      height: getPercentSize(
        picturePositionInfo.size.getY(),
        picturePositionInfo.containerSize.getY()
      )
    };
  }

  protected getBackdropStyle(
    top: number,
    left: number,
    width: number,
    height: number
  ): Record<string, any> {
    const bottom = top + height;
    const right = left + width;

    return {
      'clip-path': `polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%, 0% 0%, ${left}% ${top}%, ${right}% ${top}%, ${right}% ${bottom}%, ${left}% ${bottom}%, ${left}% ${top}%)`
    };
  }

  protected getExistingCutoutStyle(
    topLeftX: number,
    topLeftY: number,
    bottomRightX: number,
    bottomRightY: number
  ): Record<string, any> {
    return {
      left: `${topLeftX}%`,
      top: `${topLeftY}%`,
      width: `${bottomRightX - topLeftX}%`,
      height: `${bottomRightY - topLeftY}%`
    };
  }
}

export type OpenOptions = {
  picture: Picture;
  title: string;
  existingCutoutsAdapter: ExistingCutoutsAdapter | null;
  onCutoutFinished: OnCutoutFinished;
};

export type OnCutoutFinished = (args: {
  cutoutDataUrl: string;
  originatedFromPictureInfo: OriginatedFromPictureInfo;
}) => void;

type CutoutPositionInfo = {
  /**
   * in percent, 0 - 100
   */
  left: number;

  /**
   * in percent, 0 - 100
   */
  top: number;

  /**
   * in percent, 0 - 100
   */
  width: number;

  /**
   * in percent, 0 - 100
   */
  height: number;

  rotation: 0;
};

type AbsoluteCutoutPositionInfo = {
  /**
   * in px
   */
  left: number;

  /**
   * in px
   */
  top: number;

  /**
   * in px
   */
  width: number;

  /**
   * in px
   */
  height: number;
};

type PositionAndSizeWithoutRotation = Omit<
  RepositionableElementPositionAndSize,
  'rotation'
>;
