import {
  EntityRepository,
  PatchLoadedEntityOptions
} from '@record-it-npm/synchro-client';
import { AppSynchronizationEnvironmentTypes } from '../AppSynchronizationEnvironmentTypes';
import { AppEntityManagerEntityTypesByEntityName } from '../entities/AppEntityManagerEntityTypesByEntityName';
import { BaseEntity } from '../entities/BaseEntity';
import { EntityName } from '../entities/types';

export class AppEntityRepository<
  TEntityName extends EntityName
> extends EntityRepository<
  AppSynchronizationEnvironmentTypes,
  TEntityName,
  AppEntityManagerEntityTypesByEntityName
> {
  protected patchLoadedEntity(
    options: PatchLoadedEntityOptions<
      AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
    >
  ): void {
    super.patchLoadedEntity(options);

    const entity = options.entity as BaseEntity;

    if (entity.originalIds) {
      return;
    }

    if (entity.originalId) {
      entity.originalIds = [entity.originalId];
    } else {
      entity.originalIds = [];
    }
  }
}
