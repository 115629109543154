import { computedFrom } from 'aurelia-framework';
import { Observable } from 'rxjs';
import {
  PropertyOption,
  PropertyType
} from 'common/Types/Entities/Property/PropertyDto';
import { PropertyHelper } from 'common/EntityHelper/PropertyHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import { Property } from '../../../../classes/EntityManager/entities/Property/types';
import {
  AllPropertyWidgetBindingFeatures,
  CreateGeneralFileOptions,
  PictureEntityInfo,
  PropertyWidgetBinding,
  PropertyWidgetBindingFeatureMap,
  PropertyWidgetBindingValueData,
  SetPersonValueOptions,
  SetProcessTaskPositionValueOptions
} from '../PropertyWidgetBindingConfiguration/PropertyWidgetBindingConfiguration';
import { PropertyWidgetConfiguration } from '../PropertyWidgetConfiguration/PropertyWidgetConfiguration';
import { Person } from '../../../../classes/EntityManager/entities/Person/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { DisposableItemsCache } from '../../../../classes/DisposableItemsCache/DisposableItemsCache';
import { Picture } from '../../../../classes/EntityManager/entities/Picture/types';
import { ProcessTaskPosition } from '../../../../classes/EntityManager/entities/ProcessTaskPosition/types';
import { GeneralFile } from '../../../../classes/EntityManager/entities/GeneralFile/types';
import { PropertyPersonHandle } from '../shared/PropertyPersonHandle';
import { PropertyGeneralFileHandle } from '../shared/PropertyGeneralFileHandle';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { EntityNameToPermissionsHandle } from '../../../../services/PermissionsService/entityNameToPermissionsConfig';

export function createPropertyInputFieldPropertyWidgetConfiguration({
  afterPropertyUpdated,
  forceDisabled$
}: {
  afterPropertyUpdated: AfterPropertyUpdated;
  forceDisabled$: Observable<boolean>;
}): PropertyInputFieldPropertyWidgetConfiguration {
  return {
    bindingConfiguration: {
      features: {
        default: true,
        person: true,
        generalFile: true,
        picture: true,
        position: true,
        projectId: true
      },
      createBinding: ({ container, context }) => {
        return new PropertyBinding({
          entityManager: container.get(AppEntityManager),
          permissionsService: container.get(PermissionsService),
          subscriptionManagerService: container.get(SubscriptionManagerService),
          afterPropertyUpdated,
          property: context,
          forceDisabled$
        });
      }
    }
  };
}

export type PropertyInputFieldPropertyWidgetConfiguration =
  PropertyWidgetConfiguration<AllPropertyWidgetBindingFeatures, Property>;

class PropertyBinding
  implements PropertyWidgetBinding<AllPropertyWidgetBindingFeatures>
{
  private readonly entityManager: AppEntityManager;
  private readonly property: Property;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly afterPropertyUpdated: AfterPropertyUpdated;
  private readonly propertyPersonHandle: PropertyPersonHandle;
  private readonly propertyGeneralFileHandle: PropertyGeneralFileHandle;
  private readonly propertyPermissionsHandle: EntityNameToPermissionsHandle[EntityName.Property];
  private internalForceDisabled: boolean = false;
  private internalSelectedPicture: Picture | null = null;
  private internalProcessTaskPosition: ProcessTaskPosition | null = null;
  private childrenBindingsCache: DisposableItemsCache<
    Property,
    PropertyBinding
  >;

  constructor(options: PropertyBindingOptions) {
    this.entityManager = options.entityManager;
    this.property = options.property;
    this.afterPropertyUpdated = options.afterPropertyUpdated;

    this.subscriptionManager = options.subscriptionManagerService.create();

    this.propertyPermissionsHandle =
      options.permissionsService.getPermissionsHandleForEntity({
        entityName: EntityName.Property,
        entity: options.property
      });

    this.subscriptionManager.addDisposable(
      this.propertyPermissionsHandle.subscribe()
    );

    this.subscriptionManager.addRxjsSubscription(
      options.forceDisabled$.subscribe((forceDisabled) => {
        this.internalForceDisabled = forceDisabled;
      })
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      this.updateSelectedPicture.bind(this)
    );
    this.updateSelectedPicture();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PropertyToProcessTaskPosition,
      this.updateProcessTaskPosition.bind(this)
    );
    this.updateProcessTaskPosition();

    this.propertyPersonHandle = new PropertyPersonHandle({
      entityManager: options.entityManager,
      property: options.property,
      alreadySubscribedPropertyPermissionsHandle:
        this.propertyPermissionsHandle,
      subscriptionManagerService: options.subscriptionManagerService,
      permissionsService: options.permissionsService
    });

    this.propertyGeneralFileHandle = new PropertyGeneralFileHandle({
      entityManager: options.entityManager,
      property: options.property,
      alreadySubscribedPropertyPermissionsHandle:
        this.propertyPermissionsHandle,
      subscriptionManagerService: options.subscriptionManagerService
    });

    this.childrenBindingsCache = new DisposableItemsCache({
      createDisposableForItem: ({ item }) => {
        return new PropertyBinding({
          ...options,
          property: item
        });
      }
    });
  }

  @computedFrom('property.name')
  public get name(): string | null {
    return this.property.name;
  }

  @computedFrom('property.type')
  public get type(): PropertyType {
    return PropertyHelper.getTypeOrDefault(this.property.type);
  }

  @computedFrom('property.value')
  public get value(): string | null {
    return this.property.value;
  }

  @computedFrom('property.custom_choice')
  public get customChoice(): string | null {
    return this.property.custom_choice;
  }

  @computedFrom('property.choices')
  public get choices(): Array<string> {
    return this.property.choices;
  }

  @computedFrom('property.options')
  public get options(): Array<PropertyOption> {
    return this.property.options;
  }

  @computedFrom('propertyPersonHandle.person')
  public get person(): Person | null {
    return this.propertyPersonHandle.person;
  }

  @computedFrom('property.ownerUserGroupId')
  public get userGroupId(): string {
    return this.property.ownerUserGroupId;
  }

  @computedFrom('internalSelectedPicture')
  public get selectedPicture(): Picture | null {
    return this.internalSelectedPicture;
  }

  @computedFrom('propertyGeneralFileHandle.generalFiles')
  public get generalFiles(): Array<GeneralFile> {
    return this.propertyGeneralFileHandle.generalFiles;
  }

  @computedFrom('internalProcessTaskPosition')
  public get processTaskPosition(): ProcessTaskPosition | null {
    return this.internalProcessTaskPosition;
  }

  @computedFrom('property.ownerProjectId')
  public get projectId(): string | null {
    return this.property.ownerProjectId ?? null;
  }

  @computedFrom('property.children')
  public get children(): Array<PropertyBinding> {
    return this.childrenBindingsCache.mapItems({
      items: (this.property as any).children ?? []
    });
  }

  @computedFrom()
  public get features(): PropertyWidgetBindingFeatureMap<AllPropertyWidgetBindingFeatures> {
    return {
      default: true,
      generalFile: true,
      person: true,
      picture: true,
      position: true,
      projectId: true
    };
  }

  @computedFrom(
    'propertyPersonHandle.canSetPersonValue',
    'internalForceDisabled'
  )
  public get canSetPersonValue(): boolean {
    return (
      this.propertyPersonHandle.canSetPersonValue && !this.internalForceDisabled
    );
  }

  @computedFrom(
    'propertyGeneralFileHandle.canCreateGeneralFile',
    'internalForceDisabled'
  )
  public get canCreateGeneralFile(): boolean {
    return (
      this.propertyGeneralFileHandle.canCreateGeneralFile &&
      !this.internalForceDisabled
    );
  }

  @computedFrom(
    'propertyPermissionsHandle.canEditPropertyToPositions',
    'internalForceDisabled'
  )
  public get canSetProcessTaskPositionValue(): boolean {
    return (
      this.propertyPermissionsHandle.canEditPropertyToPositions &&
      !this.internalForceDisabled
    );
  }

  @computedFrom(
    'propertyPermissionsHandle.canEditField.value',
    'propertyPermissionsHandle.canEditField.custom_choice',
    'internalForceDisabled'
  )
  public get canSetValueData(): boolean {
    return (
      this.propertyPermissionsHandle.canEditField.value &&
      this.propertyPermissionsHandle.canEditField.custom_choice &&
      !this.internalForceDisabled
    );
  }

  public get canEditPictures(): boolean {
    // TODO: KRP-143 this should be changed when the picture permissions are implemented
    return (
      this.propertyPermissionsHandle.canEditField.name &&
      !this.internalForceDisabled
    );
  }

  @computedFrom('internalForceDisabled')
  public get forceDisabled(): boolean {
    return this.internalForceDisabled;
  }

  @computedFrom(
    'property.id',
    'property.ownerProjectId',
    'property.project',
    'property.ownerDefectId',
    'property.thing',
    'property.ownerProcessTaskGroupId'
  )
  public get pictureEntityInfo(): PictureEntityInfo | null {
    const mainEntityInfo = PropertyHelper.getMainEntityInfo(this.property);
    if (!mainEntityInfo) {
      return null;
    }

    return {
      mainEntityId: mainEntityInfo.id,
      mainEntityIdField: mainEntityInfo.name,
      subEntityField: 'property',
      subEntityValue: this.property.id,
      supportsTags: mainEntityInfo.supportsTags,
      ownerUserGroupId: this.property.ownerUserGroupId,
      ownerProjectId: this.property.ownerProjectId
    };
  }

  public setValueData(valueData: PropertyWidgetBindingValueData): void {
    this.property.value = valueData.value;
    this.property.custom_choice = valueData.customChoice;
    this.entityManager.propertyRepository.update(this.property);
    this.afterPropertyUpdated({ property: this.property });
  }

  public getValueData(): PropertyWidgetBindingValueData {
    return {
      value: this.value,
      customChoice: this.customChoice
    };
  }

  public setPersonValue(options: SetPersonValueOptions): void {
    this.propertyPersonHandle.setPersonValue(options);
  }

  public setProcessTaskPositionValue({
    processTaskPosition
  }: SetProcessTaskPositionValueOptions): void {
    assertNotNullOrUndefined(
      this.property,
      'cannot update property to position relation without a property'
    );

    const firstRelation =
      this.entityManager.propertyToProcessTaskPositionRepository.getByPropertyId(
        this.property.id
      )[0];
    if (firstRelation) {
      if (processTaskPosition) {
        firstRelation.processTaskPositionId = processTaskPosition.id;
        this.entityManager.propertyToProcessTaskPositionRepository.update(
          firstRelation
        );
      } else {
        this.entityManager.propertyToProcessTaskPositionRepository.delete(
          firstRelation
        );
      }
    } else {
      if (processTaskPosition) {
        // any casts because typescript can't check this (yet)
        this.entityManager.propertyToProcessTaskPositionRepository.create({
          ownerUserGroupId: this.property.ownerUserGroupId,
          ownerProjectId: this.property.ownerProjectId as any,
          ownerProcessTaskId: this.property.ownerProcessTaskId as any,
          ownerProcessTaskGroupId: this.property.ownerProcessTaskGroupId as any,

          propertyId: this.property.id,
          processTaskPositionId: processTaskPosition.id
        });
      }
    }

    this.internalProcessTaskPosition = processTaskPosition;
  }

  public createGeneralFile(options: CreateGeneralFileOptions): GeneralFile {
    return this.propertyGeneralFileHandle.createGeneralFile(options);
  }

  public dispose(): void {
    this.subscriptionManager.disposeSubscriptions();
    this.childrenBindingsCache.disposeAndClear();
    this.propertyPersonHandle.dispose();
    this.propertyGeneralFileHandle.dispose();
  }

  public getProperty(): Property {
    return this.property;
  }

  private updateSelectedPicture(): void {
    if (PropertyHelper.propertyTypeHasPicture(this.type)) {
      const pictures = this.entityManager.pictureRepository.getByPropertyId(
        this.property.id
      );
      this.internalSelectedPicture =
        pictures.find((picture) => picture.selected) ?? null;
    } else {
      this.internalSelectedPicture = null;
    }
  }

  private updateProcessTaskPosition(): void {
    const firstRelation =
      this.entityManager.propertyToProcessTaskPositionRepository.getByPropertyId(
        this.property.id
      )[0];
    const position = firstRelation
      ? this.entityManager.processTaskPositionRepository.getById(
          firstRelation.processTaskPositionId
        )
      : null;
    this.internalProcessTaskPosition = position;
  }
}

type PropertyBindingOptions = {
  entityManager: AppEntityManager;
  permissionsService: PermissionsService;
  property: Property;
  subscriptionManagerService: SubscriptionManagerService;
  afterPropertyUpdated: AfterPropertyUpdated;
  forceDisabled$: Observable<boolean>;
};

type AfterPropertyUpdated = (options: { property: Property }) => void;
