import { bindable, autoinject } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { DomEventHelper, NamedCustomEvent } from '../../classes/DomEventHelper';
import { EditPersonDialog } from '../edit-person-dialog/edit-person-dialog';
import { PersonUtils } from '../../classes/EntityManager/entities/Person/PersonUtils';
import { PersonContact } from '../../classes/EntityManager/entities/PersonContact/types';
import { ConfirmClickedEvent } from '../person-confirm-select/person-confirm-select';
import { Person } from '../../classes/EntityManager/entities/Person/types';

/**
 * @event {PersonSelectAndContactWidgetPersonChangedEvent} person-changed
 */
@autoinject()
export class PersonSelectAndContactWidget {
  @bindable()
  public person: Person | null = null;

  @bindable()
  public label: string | null = null;

  @bindable()
  public noPersonHintTk: string | null = null;

  @bindable()
  public relatedToPersonId: string | null = null;

  @bindable()
  public filterByCategoryName: string | null = null;

  @bindable()
  public canStartPersonSelectionCallback: () => boolean = () => true;

  @bindable()
  public customEmailHandler:
    | (($scope: { $contact: PersonContact }) => void)
    | null = null;

  @bindable()
  public selectable = true;

  protected showPersonSelect: boolean = false;
  protected PersonUtils = PersonUtils;

  constructor(private readonly element: Element) {}

  protected handleStartSelectPersonClick(): void {
    if (this.canStartPersonSelectionCallback()) {
      this.showPersonSelect = true;
    }
  }

  protected handlePersonConfirm(event: ConfirmClickedEvent): void {
    this.showPersonSelect = false;
    DomEventHelper.fireEvent(this.element, {
      name: 'person-changed',
      detail: event.detail,
      bubbles: false
    });
  }

  protected handlePersonCancel(): void {
    this.showPersonSelect = false;
  }

  protected handleEmailClick(contact: PersonContact): void {
    this.customEmailHandler?.({ $contact: contact });
  }

  protected handleEditPersonClick(): void {
    assertNotNullOrUndefined(
      this.person,
      "can't PersonSelectAndContactWidget.handleEditPersonClick without a person"
    );

    void EditPersonDialog.open({
      person: this.person
    });
  }
}

export type PersonSelectAndContactWidgetPersonChangedEvent = NamedCustomEvent<
  'person-changed',
  { personId: string }
>;
