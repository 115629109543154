import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { RequestWithStatus, Status } from '../classes/RequestWithStatus';
import { SocketService } from './SocketService';

@autoinject()
export class RequestWithStatusService {
  constructor(
    private readonly eventAggregator: EventAggregator,
    private readonly socketService: SocketService
  ) {}

  public createRequestWithStatus(
    requestWorker: () => Promise<Status>
  ): RequestWithStatus {
    return new RequestWithStatus({
      eventAggregator: this.eventAggregator,
      socketService: this.socketService,
      requestWorker
    });
  }
}
