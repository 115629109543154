import { z } from 'zod';

import { DateType, IdType } from '../Base/types';
import { UserGroupSubEntity } from '../Base/UserGroupSubEntity';
import { OptionalProperties } from '../../utilities';
import { BaseEntity } from '../Base/BaseEntity';
import { allKeysOfUnion } from '../../typeUtils';

type FullProcessConfiguration<
  TId extends IdType,
  TDate extends DateType
> = BaseEntity<TDate> &
  UserGroupSubEntity<TId> & {
    name: string | null;
    entityCreationUserGroupId: TId | null;
    offerReportTypeId: TId | null;
    invoiceReportTypeId: TId | null;
    measurePointsReportTypeId: TId | null;

    /**
     * show a warning when there are devices but no bank account data in the field use module
     */
    fieldUseDevicesNoBankAccountDataWarning: boolean;

    emailClosing: string | null;

    appointmentSmsClosing: string | null;

    useAppointmentConfirmDialog: boolean;

    /**
     * if this is null, this should be treated as true to be backwards compatible
     */
    useDetailEnabledForPositionsByDefault: boolean | null;

    /**
     * common/Types/ProcessConfigurationAppointmentConfirmConfiguration.ProcessConfigurationAppointmentConfirmConfiguration
     */
    appointmentConfirmConfigurationJson: string | null;

    /**
     * common/Types/ProcessConfigurationDevicePropertiesConfiguration.ProcessConfigurationDevicePropertiesConfiguration
     */
    devicePropertiesConfigurationJson: string | null;

    /**
     * common/Types/ProcessConfigurationDeviceGroupingConfiguration.IProcessConfigurationDeviceGroupingConfiguration
     */
    deviceGroupingConfigurationJson: string | null;

    /**
     * common/Types/ProcessConfigurationProcessTaskPropertiesConfiguration.ProcessConfigurationProcessTaskPropertiesConfiguration
     */
    processTaskPropertiesConfigurationJson: string | null;

    /**
     * common/Types/ProcessConfigurationPositionPropertiesConfiguration.IProcessConfigurationPositionPropertiesConfiguration
     */
    positionPropertiesConfigurationJson: string | null;

    /**
     * common/Types/ProcessConfigurationConfigPositionConfiguration.ProcessConfigurationConfigPositionConfiguration
     */
    configPositionConfigurationJson: string | null;

    /**
     * common/Types/ProcessConfigurationCustomPositionTypeConfiguration.ProcessConfigurationCustomPositionTypeConfiguration
     */
    customPositionTypeConfigurationJson: string | null;

    /**
     * common/Types/ProcessConfigurationPositionGroupingConfiguration.TProcessConfigurationPositionGroupingConfiguration
     */
    positionGroupingConfigurationJson: string | null;

    /**
     * common/Types/ProcessConfigurationOfferConfiguration.ProcessConfigurationOfferConfiguration
     */
    offerConfigurationJson: string | null;

    showProcessTaskFeatures: {
      ownerPerson?: boolean | null;
      contactPerson?: boolean | null;
      offersAndInvoices?: boolean | null;
      actionStatus?: boolean | null;
    } | null;

    showProcessTaskInfoSections: {
      referenceCode?: boolean | null;
      assignee?: boolean | null;
      notes?: boolean | null;
      followUpButtons?: boolean | null;
      persons?: boolean | null;
      thingInfo?: boolean | null;
      processTaskList?: boolean | null;
    } | null;

    processAppointmentTabsToExpand: {
      address?: boolean | null;
      phone?: boolean | null;
      general?: boolean | null;
      measurePoints?: boolean | null;
      pictures?: boolean | null;
      devices?: boolean | null;
      positions?: boolean | null;
      forms?: boolean | null;
      appointmentOverview?: boolean | null;
      worknote?: boolean | null;
      comments?: boolean | null;
      allOfferedPositions?: boolean | null;
      checklist?: boolean | null;
    } | null;

    showProcessAppointmentTabs: {
      address?: boolean | null;
      phone?: boolean | null;
      general?: boolean | null;
      measurePoints?: boolean | null;
      pictures?: boolean | null;
      devices?: boolean | null;
      positions?: boolean | null;
      forms?: boolean | null;
      appointmentOverview?: boolean | null;
      worknote?: boolean | null;
      comments?: boolean | null;
      allOfferedPositions?: boolean | null;
      checklist?: boolean | null;
    } | null;

    /**
     * If set, a daily email with all appointments missed (i.e. not finished/opened) that day
     * will be sent to that user's email address.
     */
    missedAppointmentContactUserId: TId | null;

    configurableDisplayText?: {
      processTaskGroupOverviewProcessTaskGroupTitle: string | null;
      processTaskGroupOverviewProcessTaskTitle: string | null;
      processTaskAppointmentDefaultName: string | null;
      processTaskAppointmentsInCalendarView: {
        firstLine: string | null;
        secondLine: string | null;
        thirdLine: string | null;
      } | null;
    } | null;
    contactPersonPurposes: Array<ProcessConfigurationContactPersonPurpose>;
    sendEmailAppointmentNotificationsToWorkers?: boolean | null;
    processTaskGroupRelations: {
      enabled?: boolean | null;
      mainInfoDisplayText?: string | null;
      subInfoDisplayText?: string | null;
    } | null;
    processTaskToProjectRelations: {
      enabled?: boolean | null;
      processTaskMainInfoText?: string | null;
      processTaskSubInfoText?: string | null;
    } | null;
  };

type AnyFullProcessConfiguration = Required<FullProcessConfiguration<any, any>>;
export type ProcessAppointmentTabsKeys = keyof NonNullable<
  AnyFullProcessConfiguration['showProcessAppointmentTabs']
>;
export type ShowProcessTaskInfoSectionsKeys = keyof NonNullable<
  AnyFullProcessConfiguration['showProcessTaskInfoSections']
>;
export type ShowProcessTaskFeaturesKeys = keyof NonNullable<
  AnyFullProcessConfiguration['showProcessTaskFeatures']
>;

export const allProcessAppointmentTabs =
  (): Array<ProcessAppointmentTabsKeys> =>
    allKeysOfUnion<ProcessAppointmentTabsKeys>()([
      'address',
      'phone',
      'general',
      'measurePoints',
      'pictures',
      'devices',
      'positions',
      'forms',
      'appointmentOverview',
      'worknote',
      'comments',
      'allOfferedPositions',
      'checklist'
    ]);

export const allExpandableProcessAppointmentTabs =
  (): Array<ProcessAppointmentTabsKeys> =>
    allProcessAppointmentTabs().filter(
      (t) => !['address', 'phone', 'checklist'].includes(t)
    );

export const allProcessTaskInfoSections =
  (): Array<ShowProcessTaskInfoSectionsKeys> =>
    allKeysOfUnion<ShowProcessTaskInfoSectionsKeys>()([
      'assignee',
      'followUpButtons',
      'notes',
      'persons',
      'processTaskList',
      'referenceCode',
      'thingInfo'
    ]);

export const allProcessTaskFeatures = (): Array<ShowProcessTaskFeaturesKeys> =>
  allKeysOfUnion<ShowProcessTaskFeaturesKeys>()([
    'actionStatus',
    'contactPerson',
    'offersAndInvoices',
    'ownerPerson'
  ]);

export type ProcessConfigurationDto<
  TId extends IdType,
  TDate extends DateType,
  TOptionalProperties extends keyof FullProcessConfiguration<
    TId,
    TDate
  > = never,
  TPropertiesToRemove extends keyof FullProcessConfiguration<TId, TDate> = never
> = Omit<
  OptionalProperties<FullProcessConfiguration<TId, TDate>, TOptionalProperties>,
  TPropertiesToRemove
>;

export enum ProcessConfigurationAppointmentFinishActionType {
  SEND_FORM_BY_EMAIL = 'sendFormPerEmail'
}

const processConfigurationContactPersonPurposeSchema = z.strictObject({
  value: z.string().nullable(),
  label: z.string().nullable(),
  restrictToCategory: z.string().nullable().optional(),
  restrictToThingGroupOwnerPersonRelations: z.boolean().nullable().optional()
});

export const processConfigurationContactPersonPurposesSchema = z.array(
  processConfigurationContactPersonPurposeSchema
);

export type ProcessConfigurationContactPersonPurpose = z.infer<
  typeof processConfigurationContactPersonPurposeSchema
>;
