import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ProcessTask } from 'src/classes/EntityManager/entities/ProcessTask/types';
import { ProcessTaskGroup } from 'src/classes/EntityManager/entities/ProcessTaskGroup/types';
import { ProcessTaskAppointment } from 'src/classes/EntityManager/entities/ProcessTaskAppointment/types';
import { SubscriptionManager } from 'src/classes/SubscriptionManager';
import { FormProcessTaskToProject } from '../../classes/EntityManager/entities/ProcessTaskToProject/types';
import { computed } from '../../hooks/computed';
import { expression, model } from '../../hooks/dependencies';
import { SocketService } from '../../services/SocketService';
import { RequestWithStatus, Status } from '../../classes/RequestWithStatus';
import { RequestWithStatusService } from '../../services/RequestWithStatusService';

@autoinject()
export class ProcessTaskAppointmentFormsWidget {
  @bindable public processTask: ProcessTask | null = null;

  @bindable public processTaskGroup: ProcessTaskGroup | null = null;

  @bindable public processTaskAppointment: ProcessTaskAppointment | null = null;

  @bindable public expanded: boolean = false;

  @bindable public enabled: boolean = false;

  /** The ID of the form (e.g. processTaskToProject) that should be expanded immediately */
  @bindable public openedFormId: string | null = null;

  protected sendFormsPerMailRequestWithStatus: RequestWithStatus;

  private readonly subscriptionManager: SubscriptionManager;

  private isAttached = false;
  private isConnected = false;
  protected formRelations: Array<FormProcessTaskToProject> = [];

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly socketService: SocketService,
    private readonly i18n: I18N,
    private readonly requestWithStatusService: RequestWithStatusService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
    this.sendFormsPerMailRequestWithStatus =
      this.requestWithStatusService.createRequestWithStatus(async () => {
        assertNotNullOrUndefined(
          this.processTaskAppointment,
          'cannot send request to send appointment forms per email without processTaskAppointment'
        );
        const response =
          await this.socketService.sendOperationsFormsOfAppointment({
            processTaskAppointmentId: this.processTaskAppointment.id,
            sendFormsOfAppointmentUsingExplicitButton: true
          });

        if (response.success) return Status.OK;

        return Status.ERROR;
      });
  }

  protected attached(): void {
    this.isAttached = true;

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.ProcessTaskToProject,
      this.updateFormRelations.bind(this)
    );

    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isConnected = isConnected;
      })
    );
    this.updateFormRelations();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskAppointmentChanged(): void {
    if (this.isAttached) {
      this.updateFormRelations();
    }
  }

  @computed(
    expression('processTaskGroup.processConfigurationId'),
    model(EntityName.ProcessConfigurationFormSendTarget)
  )
  protected get showFormsSendButton(): boolean {
    if (!this.processTaskGroup?.processConfigurationId) return false;
    return !!this.entityManager.processConfigurationFormSendTargetRepository
      .getByProcessConfigurationId(this.processTaskGroup.processConfigurationId)
      .filter(
        (x) => !!x.sendFormsOfAppointmentUsingExplicitButton && !!x.userId
      ).length;
  }

  private updateFormRelations(): void {
    if (this.processTaskAppointment) {
      this.formRelations =
        this.entityManager.processTaskToProjectRepository.getFormProcessTaskToProjectsByProcessTaskAppointmentId(
          this.processTaskAppointment.id
        );
    } else {
      this.formRelations = [];
    }
  }

  protected handleToggleExpandedClick(): void {
    this.expanded = !this.expanded;
  }

  protected sendFormsPerEmail(): void {
    this.sendFormsPerMailRequestWithStatus.startRequest();
  }
}
