import { bindable, autoinject } from 'aurelia-framework';

import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTask } from '../../classes/EntityManager/entities/ProcessTask/types';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ProcessTaskProperty } from '../../classes/EntityManager/entities/Property/types';
import { PropertyInputFieldListWithDefaultProperties } from '../../aureliaComponents/property-input-field-list-with-default-properties/property-input-field-list-with-default-properties';
import { ProcessTaskPropertyAdapter } from '../../aureliaComponents/property-input-field-list-with-default-properties/PropertyAdapter/ProcessTaskPropertyAdapter';
import { PermissionsService } from '../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessTaskGeneralProperties {
  @bindable()
  public processTask: ProcessTask | null = null;

  @bindable()
  public forceDisabled: boolean = false;

  private readonly subscriptionManager: SubscriptionManager;

  protected list: PropertyInputFieldListWithDefaultProperties<
    ProcessTaskProperty,
    any
  > | null = null;

  private isAttached: boolean = false;
  protected adapter: ProcessTaskPropertyAdapter | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly subscriptionManagerService: SubscriptionManagerService,
    private readonly permissionsService: PermissionsService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public focusProperty(property: ProcessTaskProperty): void {
    this.list?.focusProperty(property);
  }

  protected attached(): void {
    this.isAttached = true;

    this.updateAdapter();
  }

  protected detached(): void {
    this.isAttached = false;

    this.subscriptionManager.disposeSubscriptions();
  }

  protected processTaskChanged(): void {
    if (this.isAttached) {
      this.updateAdapter();
    }
  }

  private updateAdapter(): void {
    if (this.processTask) {
      this.adapter = new ProcessTaskPropertyAdapter({
        processTask: this.processTask,
        entityManager: this.entityManager,
        permissionsService: this.permissionsService,
        subscriptionManagerService: this.subscriptionManagerService,
        processTaskLoggingService: this.processTaskLoggingService
      });
    } else {
      this.adapter = null;
    }
  }
}
