import { DataStorageHelper } from '../classes/DataStorageHelper/DataStorageHelper';
import { EventDispatcher } from '../classes/EventDispatcher/EventDispatcher';

export class SessionService {
  private currentUser: IWebUser | null = null;
  private currentUserLoadedPromise: Promise<void> | null;

  private currentSessionId: string | null = null;

  private eventDispatcher: EventDispatcher<EventDispatcherConfig> =
    new EventDispatcher();
  private initialized = false;

  constructor() {
    this.currentUserLoadedPromise = DataStorageHelper.getItem('web_user').then(
      (currentUser) => {
        // only load the currentUser if not set yet because it could potentionally already set by the setCurrentUser function before it was loaded
        if (!this.currentUser) {
          this.currentUser = currentUser;
        }

        this.currentUserLoadedPromise = null;
      }
    );
  }

  public async initialize(): Promise<void> {
    this.currentSessionId =
      (await DataStorageHelper.getItem('currentSessionId')) ?? null;
    this.initialized = true;
  }

  public async setCurrentUser(user: IWebUser | null): Promise<void> {
    this.currentUser = user;
    await DataStorageHelper.setItem('web_user', user);
    this.eventDispatcher.dispatchEvent('currentUserChanged', user);
  }

  public async getCurrentUserId(): Promise<string | null> {
    const user = await this.getCurrentUser();
    return user ? user.id : null;
  }

  public getCurrentUserIdWithoutWaitingForLoad(): string | null {
    return this.currentUser?.id ?? null;
  }

  /**
   * returns only the instance stored from the login response
   * this will not be the same instance as a user from the UserService
   *
   * async!
   */
  public async getCurrentUser(): Promise<IWebUser | null> {
    await this.currentUserLoadedPromise;
    return this.currentUser;
  }

  public async setCurrentJWT(jwt: string | null): Promise<void> {
    await DataStorageHelper.setItem('web_token', jwt);
  }

  public async getCurrentJWT(): Promise<string | null> {
    return await DataStorageHelper.getItem('web_token');
  }

  public async setCurrentSessionId(sessionId: string | null): Promise<void> {
    this.currentSessionId = sessionId;
    await DataStorageHelper.setItem('currentSessionId', sessionId);
  }

  public getCurrentSessionId(): string | null {
    if (!this.initialized)
      throw new Error('session service was not initialized');
    return this.currentSessionId;
  }

  public subscribeToCurrentUserChanged(
    context: any,
    callback: (currentUser: IWebUser | null) => void
  ): void {
    this.eventDispatcher.addEventListener(
      context,
      'currentUserChanged',
      callback
    );
  }

  public removeEventListenersByContext(context: any): void {
    this.eventDispatcher.removeEventListenersByContext(context);
  }

  /**
   * Only removes the data in the data storage. The data will still be available in this service. Useful for logging out when reloading this page after calling this function.
   */
  public async clearStoredData(): Promise<void> {
    await DataStorageHelper.setItem('web_token', null);
    await DataStorageHelper.setItem('currentSessionId', null);
    await DataStorageHelper.setItem('web_user', null);
  }
}

export interface IWebUser {
  id: string;
  username: string;
  email: string;
}

type EventDispatcherConfig = {
  currentUserChanged: IWebUser | null;
};
