import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import * as socketIoClient from 'socket.io-client';

import {
  GetActualizationsBulkRequest,
  GetActualizationsBulkResponse,
  GetProjectTitlePicturesRequest,
  GetProjectTitlePicturesResponse
} from 'common/EndpointTypes/GetActualizationsEndpointsHandler';

import {
  ExportDefectsRequest,
  ExportDefectsResponse,
  IExportGalleryThingRequest,
  IExportGalleryThingResponse,
  IExportMultipleProjectsRequest,
  IExportMultipleProjectsResponse,
  IExportProjectRequest,
  TExportProjectResponse
} from 'common/EndpointTypes/ReportFunctionsEndpointsHandler';

import {
  TImportFromCsvFileResponse,
  TImportThingGroupsFromCsvFileRequest
} from 'common/EndpointTypes/ImportCsvEndpointsHandler';

import {
  ExportTextBrickTemplatesAsCsvFileRequest,
  ExportTextBrickTemplatesAsCsvFileResponse,
  IExportStructureTemplateAsCsvFileRequest,
  ExportEntitiesAsCsvFileRequest,
  IExportThingsWithContentAsCsvFileRequest,
  TExportStructureTemplateAsCsvFileResponse,
  ExportEntitiesAsCsvFileResponse,
  TExportThingsWithContentAsCsvFileResponse
} from 'common/EndpointTypes/ExportCsvEndpointsHandler';

import {
  IChangeExternalUserCredentialPasswordRequest,
  IChangeUserEmailWithVerificationRequest,
  IChangeUserEmailWithVerificationResponse,
  IChangeUserPasswordRequest,
  ICreateUserRequest,
  IImpersonateUserRequest,
  IInviteUserRequest,
  IInviteUserResponse,
  RequestAccountDeletionRequest,
  RequestAccountDeletionResponse,
  TChangeExternalUserCredentialPasswordResponse,
  TChangeUserPasswordResponse,
  TCreateUserResponse,
  TImpersonateUserResponse
} from 'common/EndpointTypes/UserEndpointsHandler';

import {
  GetCalendarAppointmentsRequest,
  GetCalendarAppointmentsResponse,
  GetProcessTaskGroupsFilter,
  TGetProcessTaskGroupsWithSubEntitiesForProcessTaskRequest,
  TGetProcessTaskGroupsWithSubEntitiesResponse,
  TJoinOrLeaveProcessTaskGroupRequest,
  TJoinOrLeaveProcessTaskGroupResponse,
  SendOperationsEmailRequest,
  SendOperationsEmailResponse,
  GetProcessTaskGroupsAndTasksRequest,
  GetProcessTaskGroupsAndTasksResponse,
  SendOperationsFormsOfAppointmentRequest,
  SendOperationsFormsOfAppointmentResponse
} from 'common/EndpointTypes/OperationsEndpointsTypes';

import {
  CheckUsersAreAvailableRequest,
  CheckUsersAreAvailableResponse
} from 'common/EndpointTypes/ProcessTaskAppointmentEndpointsHandler';
import {
  IUploadDocxTemplateFileRequest,
  IUploadPdfFileRequest,
  UploadPictureFileRequest,
  TUploadDocxTemplateFileResponse,
  TUploadPdfFileResponse,
  UploadPictureFileResponse
} from 'common/EndpointTypes/UploadFileEndpointsHandler';

import {
  TExportPicturesOfGalleryThingAsZipFileRequest,
  TExportPicturesOfGalleryThingAsZipFileResponse,
  TExportPicturesOfThingAsZipFileRequest,
  TExportPicturesOfThingAsZipFileResponse,
  TExportPicturesZipFileRequest,
  TExportPicturesZipFileResponse
} from 'common/EndpointTypes/ExportPictureZipEndpointsHandler';

import {
  GetGstInfoRequest,
  GetGstInfoResponse,
  GetGstMapDataRequest,
  GetGstMapDataResponse,
  IGetGeoJsonTileRequest,
  TGetGeoJsonTileResponse
} from 'common/EndpointTypes/GeoDataEndpointsHandler';
import {
  ICopyPicturesOfGalleryThingToBasicProjectRequest,
  ICopyPicturesOfGalleryThingToBasicProjectResponse,
  TCopyProjectRequest,
  ICopyProjectResponse,
  CopyProjectContentIntoAnotherProjectRequest,
  CopyProjectContentIntoAnotherProjectResponse
} from 'common/EndpointTypes/CopyProjectEndpointsHandler';

import {
  OperationsExportDevicesRequest,
  OperationsExportDevicesResponse,
  OperationsExportFormRequest,
  OperationsExportFormResponse,
  OperationsExportInvoiceRequest,
  OperationsExportInvoiceResponse,
  OperationsExportMeasurePointsRequest,
  OperationsExportMeasurePointsResponse,
  OperationsExportOfferRequest,
  OperationsExportOfferResponse,
  OperationsExportPositionsAsCsvRequest,
  OperationsExportPositionsAsCsvResponse
} from 'common/EndpointTypes/OperationsExportEndpointsHandler';

import {
  TUploadGeneralFileRequest,
  TUploadGeneralFileResponse
} from 'common/EndpointTypes/GeneralFileEndpointsHandler';
import {
  ISaveEntityRequest,
  TSaveEntityResponse
} from 'common/EndpointTypes/SaveEntityEndpointsHandler';
import { AuthRequest, AuthResponse } from 'common/EndpointTypes/Auth';

import {
  ImportDataDumpRequest,
  ImportDataDumpResponse,
  ImportProjectPictureFolderRequest,
  ImportProjectPictureFolderResponse,
  UploadCapturedPictureRequest,
  UploadCapturedPictureResponse,
  UploadFileUploadItemsRequest,
  UploadFileUploadItemsResponse,
  UploadUnsynchedItemsRequest,
  UploadUnsynchedItemsResponse
} from 'common/EndpointTypes/DataRescueEndpointsHandler';

import {
  ExportMarkedPictureRequest,
  ExportMarkedPictureResponse,
  ExportMarkedPicturesRequest,
  ExportMarkedPicturesResponse
} from 'common/EndpointTypes/PictureExportEndpointsHandler';

import {
  AddUserToUserGroupRequest,
  AddUserToUserGroupResponse
} from 'common/EndpointTypes/AddUserToUserGroupEndpointsHandler';

import {
  SearchByNfcTokenIdRequest,
  SearchByNfcTokenIdResponse,
  SearchGloballyRequest,
  SearchGloballyResponse
} from 'common/EndpointTypes/GlobalSearchEndpointsHandler';
import {
  ExportLatestReportsOfThingAsZipFileRequest,
  ExportLatestReportsOfThingAsZipFileResponse
} from 'common/EndpointTypes/ExportReportZipEndpointsHandler';
import {
  JoinProjectRequest,
  JoinProjectResponse,
  LeaveProjectRequest,
  LeaveProjectResponse
} from 'common/EndpointTypes/ProjectSubscriptionEndpointsHandler';
import {
  JoinOrLeaveDefectGroupRequest,
  JoinOrLeaveDefectGroupResponse
} from 'common/EndpointTypes/DefectEndpointTypes';
import {
  ExportPicturesOfGalleryThingAsShapefileRequest,
  ExportPicturesOfGalleryThingAsShapefileResponse
} from 'common/EndpointTypes/GalleryThingEndpointsHandler';
import {
  FileChunkFileInfoResponse,
  FileChunkUploadStartRequest,
  FileChunkUploadStatusRequest,
  FileChunkUploadUploadChunkRequest
} from 'common/EndpointTypes/FileChunkUploadEndpointsHandler';
import {
  MissedAppointmentsNotificationRequest,
  MissedAppointmentsNotificationResponse
} from 'common/EndpointTypes/MissedAppointmentsEndpointsHandler';
import {
  GetPictureOverviewEntryGroupsRequest,
  GetPictureOverviewEntryGroupsResponse
} from 'common/EndpointTypes/GalleryThingPictureOverviewEndpointsHandler';

import {
  GetProcessTaskGroupRelationInfoRequest,
  GetProcessTaskGroupRelationInfoResponse,
  ProcessTaskGroupRelationInfoRequestType,
  SetProcessTaskGroupToProcessTaskGroupRelationRequest,
  SetProcessTaskGroupToProcessTaskGroupRelationResponse
} from 'common/EndpointTypes/ProcessTaskGroupRelationInfoEndpointsHandler';

import { TestingHelper } from '../classes/TestingHelper';

import { SessionService } from './SessionService';
import { UrlManager } from '../classes/UrlManager';
import { DeviceInfoHelper } from '../classes/DeviceInfoHelper';
import { EntityName } from '../classes/EntityManager/entities/types';
import { EntityNameToRequestDtoMap } from 'common/Types/EntitiesDto/EntityNameToRequestDtoMap';
import {
  ExecuteDeveloperCommandRequest,
  ExecuteDeveloperCommandResponse
} from 'common/EndpointTypes/DeveloperCommandEndpointsHandler';
import { DeveloperCommandName } from 'common/DeveloperCommand/developerCommands';
import {
  PropertyBinder,
  PropertyBinderCallback,
  PropertyBinderName
} from '../classes/PropertyBinder/PropertyBinder';
import { Disposable } from '../classes/Utils/DisposableContainer';
import { CommonProject } from 'common/GalleryThing/GalleryThingPictureGroupHelper';
import { SocketIoZipClientPlugin } from 'common/SocketIoZipPlugin/SocketIoZipClientPlugin';
import { SharepointSocketEndpoints } from './SocketService/SharepointSocketEndpoints';
import { PdfSignerModuleEndpoints } from './SocketService/PdfSignerModuleEndpoints';
import {
  GetProcessTaskToProjectRelationInfoRequest,
  GetProcessTaskToProjectRelationInfoResponse,
  SetProcessTaskToProjectRelationRequest,
  SetProcessTaskToProjectRelationResponse
} from 'common/EndpointTypes/ProcessTaskToProjectRelationInfoEndpointsHandler';
import { MoveDefectModuleEndpoints } from './SocketService/MoveDefectModuleEndpoints';
import { SettingsModuleEndpoints } from './SocketService/SettingsModuleEndpoints';

/**
 * @aggregatorevent socket:connected - fired as soon as the client successfully connected to the server, but is not authenticated yet
 * @aggregatorevent socket:authenticated - fired after the connected event as soon as the authentication
 * @aggregatorevent socket:disconnected
 */
@autoinject()
export class SocketService {
  private isAuthenticatedValue = false;

  private callbacksToRunAfterAuthentication: Array<Function> = [];

  private eventAggregator: EventAggregator;
  private sessionService: SessionService;

  private propertyBinder =
    new PropertyBinder<SocketServicePropertyBinderConfig>();

  private io;

  public readonly sharepointSocketEndpoints: SharepointSocketEndpoints;
  public readonly pdfSignerModuleEndpoints: PdfSignerModuleEndpoints;
  public readonly moveDefectModuleEndpoints: MoveDefectModuleEndpoints;
  public readonly settingsModuleEndpoints: SettingsModuleEndpoints;

  constructor(
    eventAggregator: EventAggregator,
    sessionService: SessionService
  ) {
    this.eventAggregator = eventAggregator;
    this.sessionService = sessionService;

    SocketIoZipClientPlugin.patch(socketIoClient);

    this.io = socketIoClient.io(UrlManager.webFolder, {});
    // instant disconnect, to prevent the automatic connection to the socket, which we don't want
    this.io.disconnect();

    this.io.on('connect', () => {
      this.onConnect();
    });
    this.io.on('disconnect', () => {
      this.onDisconnect();
    });

    // further socket listeners can be found in the SocketEventListeners class

    this.sharepointSocketEndpoints = new SharepointSocketEndpoints(this.io);
    this.pdfSignerModuleEndpoints = new PdfSignerModuleEndpoints(this.io);
    this.moveDefectModuleEndpoints = new MoveDefectModuleEndpoints(this.io);
    this.settingsModuleEndpoints = new SettingsModuleEndpoints(this.io);

    TestingHelper.importDataDump = this.importDataDump.bind(this);
    TestingHelper.importProjectPictureFolder =
      this.importProjectPictureFolder.bind(this);
  }

  public connect(): void {
    this.io.connect();
  }

  public disconnect(): void {
    this.io.disconnect();
  }

  public toggleConnection(): void {
    if (this.isConnected()) {
      this.disconnect();
    } else {
      this.connect();
    }
  }

  public isConnected(): boolean {
    return this.io.connected;
  }

  public isAuthenticated(): boolean {
    return this.isAuthenticatedValue;
  }

  /**
   * add a listener to the socket-io instance
   */
  public addListener(
    eventName: string,
    callback: (...args: Array<any>) => void
  ): void {
    this.io.on(eventName, callback);
  }

  public removeListener(
    eventName: string,
    callback: (...args: Array<any>) => void
  ): void {
    this.io.off(eventName, callback);
  }

  public getActualizationsBulk(
    request: GetActualizationsBulkRequest,
    callback: TSocketServiceCallbackFunction<
      GetActualizationsBulkResponse,
      GetActualizationsBulkRequest
    >
  ): void {
    this.io.emit(
      'get_actualizations_bulk/2',
      request,
      (response: GetActualizationsBulkResponse) => {
        this.genericReturnFunction(
          'got bulk actualizations',
          response,
          request,
          callback
        );
      }
    );
  }

  public getProjectTitlePictures(
    callback?: TSocketServiceCallbackFunction<
      GetProjectTitlePicturesResponse,
      GetProjectTitlePicturesRequest
    >
  ): void {
    this.io.emit(
      'get_project_title_pictures/1',
      {},
      (data: GetProjectTitlePicturesResponse) => {
        this.genericReturnFunction(
          'got project title pictures',
          data,
          {},
          callback
        );
      }
    );
  }

  public saveEntity<T extends EntityName>(
    entity: EntityNameToRequestDtoMap[T],
    callback: TSocketServiceCallbackFunction<
      TSaveEntityResponse<T>,
      ISaveEntityRequest<T>
    >
  ): void {
    const sendData = entity;
    this.io.emit(
      'save_entity/1',
      sendData,
      (response: TSaveEntityResponse<T>) => {
        this.genericReturnFunction(
          'saved entity',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public joinProject(
    projectId: string,
    callback: TSocketServiceCallbackFunction<
      JoinProjectResponse,
      JoinProjectRequest
    >
  ): void {
    this.runAfterAuthentication(() => {
      const sendData: JoinProjectRequest = { projectId: projectId };
      this.io.emit('join_project/1', sendData, (data: JoinProjectResponse) => {
        this.genericReturnFunction('joined project', data, sendData, callback);
      });
    });
  }

  public leaveProject(
    projectId: string,
    callback: TSocketServiceCallbackFunction<
      LeaveProjectResponse,
      LeaveProjectRequest
    >
  ): void {
    this.runAfterAuthentication(() => {
      const sendData: LeaveProjectRequest = { projectId: projectId };
      this.io.emit(
        'leave_project/1',
        sendData,
        (data: LeaveProjectResponse) => {
          this.genericReturnFunction('left project', data, sendData, callback);
        }
      );
    });
  }

  public uploadDOCXTemplateFile(
    sendData: IUploadDocxTemplateFileRequest,
    callback?: TSocketServiceCallbackFunction<
      TUploadDocxTemplateFileResponse,
      IUploadDocxTemplateFileRequest
    >
  ): void {
    this.io.emit(
      'upload_docx_template_file/1',
      sendData,
      (response: TUploadDocxTemplateFileResponse) => {
        this.genericReturnFunction(
          'uploaded docx template file',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportProject(
    sendData: IExportProjectRequest,
    callback?: TSocketServiceCallbackFunction<
      TExportProjectResponse,
      IExportProjectRequest
    >
  ): void {
    this.io.emit(
      'export_project/1',
      sendData,
      (response: TExportProjectResponse) => {
        this.genericReturnFunction(
          'exported project',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportMultipleProjects(
    request: IExportMultipleProjectsRequest,
    callback?: TSocketServiceCallbackFunction<
      IExportMultipleProjectsResponse,
      IExportMultipleProjectsRequest
    >
  ): void {
    this.io.emit(
      'export_multiple_projects/1',
      request,
      (data: IExportMultipleProjectsResponse) => {
        this.genericReturnFunction(
          'exported multiple projects',
          data,
          request,
          callback
        );
      }
    );
  }

  public exportGalleryThing(
    sendData: IExportGalleryThingRequest,
    callback: TSocketServiceCallbackFunction<
      IExportGalleryThingResponse,
      IExportGalleryThingRequest
    >
  ): void {
    this.io.emit(
      'export_gallery_thing/3',
      sendData,
      (response: IExportGalleryThingResponse) => {
        this.genericReturnFunction(
          'exported gallery thing',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportPicturesOfGalleryThingAsZipFile(
    sendData: TExportPicturesOfGalleryThingAsZipFileRequest,
    callback: TSocketServiceCallbackFunction<
      TExportPicturesOfGalleryThingAsZipFileResponse,
      TExportPicturesOfGalleryThingAsZipFileRequest
    >
  ): void {
    this.io.emit(
      'export_pictures_of_gallery_thing_as_zip_file/2',
      sendData,
      (response: TExportPicturesOfGalleryThingAsZipFileResponse) => {
        this.genericReturnFunction(
          'exported gallery thing pictures as zip file',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportPicturesOfGalleryThingAsShapefile(
    sendData: ExportPicturesOfGalleryThingAsShapefileRequest,
    callback: TSocketServiceCallbackFunction<
      ExportPicturesOfGalleryThingAsShapefileResponse,
      ExportPicturesOfGalleryThingAsShapefileRequest
    >
  ): void {
    this.io.emit(
      'export_pictures_of_gallery_thing_as_shapefile/1',
      sendData,
      (response: ExportPicturesOfGalleryThingAsShapefileResponse) => {
        this.genericReturnFunction(
          'exported pictures of gallery thing as shapefile',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportDefects(
    sendData: ExportDefectsRequest,
    callback: TSocketServiceCallbackFunction<
      ExportDefectsResponse,
      ExportDefectsRequest
    >
  ): void {
    this.io.emit(
      'export_defects/1',
      sendData,
      (response: ExportDefectsResponse) => {
        this.genericReturnFunction(
          'exported thing defects',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportLatestReportsOfThingAsZipFile(
    sendData: ExportLatestReportsOfThingAsZipFileRequest,
    callback: TSocketServiceCallbackFunction<
      ExportLatestReportsOfThingAsZipFileResponse,
      ExportLatestReportsOfThingAsZipFileRequest
    >
  ): void {
    this.io.emit(
      'export_latest_reports_of_thing_as_zip_file/1',
      sendData,
      (data: ExportLatestReportsOfThingAsZipFileResponse) => {
        this.genericReturnFunction(
          'exported thing reports as zip file',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public uploadImageFile(
    sendData: UploadPictureFileRequest,
    callback?: TSocketServiceCallbackFunction<
      UploadPictureFileResponse,
      UploadPictureFileRequest
    >
  ): void {
    this.io.emit(
      'upload_picture_file/2',
      sendData,
      (data: UploadPictureFileResponse) => {
        this.genericReturnFunction(
          'uploaded picture file',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public uploadPDFFile(
    sendData: IUploadPdfFileRequest,
    callback?: TSocketServiceCallbackFunction<
      TUploadPdfFileResponse,
      IUploadPdfFileRequest
    >
  ): void {
    this.io.emit(
      'upload_pdf_file/1',
      sendData,
      (data: TUploadPdfFileResponse) => {
        this.genericReturnFunction(
          'uploaded pdf file',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public exportPicturesZIPFile(
    projectId: string,
    callback: TSocketServiceCallbackFunction<
      TExportPicturesZipFileResponse,
      TExportPicturesZipFileRequest
    >
  ): void {
    const sendData = {
      projectId: projectId
    };
    this.io.emit(
      'export_pictures_zip_file/1',
      sendData,
      (response: TExportPicturesZipFileResponse) => {
        this.genericReturnFunction(
          'exported picture zip file',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public getGeoJsonTile(
    layerId: string,
    x: number,
    y: number,
    z: number,
    callback: TSocketServiceCallbackFunction<
      TGetGeoJsonTileResponse,
      IGetGeoJsonTileRequest
    >
  ): void {
    const sendData = {
      layerId: layerId,
      x: x,
      y: y,
      z: z
    };
    this.io.emit(
      'get_geo_json_tile/1',
      sendData,
      (data: TGetGeoJsonTileResponse) => {
        this.genericReturnFunction(
          'got geo json tile',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public downloadGstData(
    municipalityCode: string,
    callback: TSocketServiceCallbackFunction<
      GetGstMapDataResponse,
      GetGstMapDataRequest
    >
  ): void {
    const sendData: GetGstMapDataRequest = {
      municipalityCode: municipalityCode
    };
    this.io.emit(
      'get_gst_map_data/1',
      sendData,
      (data: GetGstMapDataResponse) => {
        if (typeof callback === 'function') callback(data, sendData);
      }
    );
  }

  public getGstInfo(
    locationData: GetGstInfoRequest,
    callback: TSocketServiceCallbackFunction<
      GetGstInfoResponse,
      GetGstInfoRequest
    >
  ): void {
    this.io.emit('get_gst_info/1', locationData, (data: GetGstInfoResponse) => {
      callback(data, locationData);
    });
  }

  public copyProject(
    options: TCopyProjectRequest,
    callback?: TSocketServiceCallbackFunction<
      ICopyProjectResponse,
      TCopyProjectRequest
    >
  ): void {
    const sendData = options;
    this.io.emit(
      'copy_project/1',
      sendData,
      (response: ICopyProjectResponse) => {
        this.genericReturnFunction(
          'copied project',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public copyProjectContentIntoAnotherProject(
    request: CopyProjectContentIntoAnotherProjectRequest,
    callback?: TSocketServiceCallbackFunction<
      CopyProjectContentIntoAnotherProjectResponse,
      CopyProjectContentIntoAnotherProjectRequest
    >
  ): void {
    this.io.emit(
      'copy_project_content_into_another_project/1',
      request,
      (response: CopyProjectContentIntoAnotherProjectResponse) => {
        this.genericReturnFunction(
          'copied project',
          response,
          request,
          callback
        );
      }
    );
  }

  public copyPicturesOfGalleryThingToBasicProject(
    sendData: ICopyPicturesOfGalleryThingToBasicProjectRequest,
    callback: TSocketServiceCallbackFunction<
      ICopyPicturesOfGalleryThingToBasicProjectResponse,
      ICopyPicturesOfGalleryThingToBasicProjectRequest
    >
  ): void {
    this.io.emit(
      'copy_pictures_of_gallery_thing_to_basic_project/1',
      sendData,
      (response: ICopyPicturesOfGalleryThingToBasicProjectResponse) => {
        this.genericReturnFunction(
          'copied pictures to basic project',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportThingsAsCsvFile(
    thingIds: Array<string>,
    callback?: TSocketServiceCallbackFunction<
      ExportEntitiesAsCsvFileResponse,
      ExportEntitiesAsCsvFileRequest
    >
  ): void {
    const sendData = {
      ids: thingIds
    };
    this.io.emit(
      'export_things_as_csv_file/1',
      sendData,
      (data: ExportEntitiesAsCsvFileResponse) => {
        this.genericReturnFunction(
          'exported things as csv file',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public exportDefectsAsCsvFile(
    defectIds: Array<string>,
    callback?: TSocketServiceCallbackFunction<
      ExportEntitiesAsCsvFileResponse,
      ExportEntitiesAsCsvFileRequest
    >
  ): void {
    const sendData = {
      ids: defectIds
    };
    this.io.emit(
      'export_defects_as_csv_file/1',
      sendData,
      (data: ExportEntitiesAsCsvFileResponse) => {
        this.genericReturnFunction(
          'exported defects as csv file',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public exportUserDefinedEntitiesAsCsvFile(
    userDefinedEntitityIds: Array<string>,
    callback?: TSocketServiceCallbackFunction<
      ExportEntitiesAsCsvFileResponse,
      ExportEntitiesAsCsvFileRequest
    >
  ): void {
    const sendData = {
      ids: userDefinedEntitityIds
    };
    this.io.emit(
      'export_user_defined_entities_as_csv_file/1',
      sendData,
      (data: ExportEntitiesAsCsvFileResponse) => {
        this.genericReturnFunction(
          'exported user defined entities as csv file',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public exportThingsWithContentAsCsvFile(
    sendData: IExportThingsWithContentAsCsvFileRequest,
    callback: TSocketServiceCallbackFunction<
      TExportThingsWithContentAsCsvFileResponse,
      IExportThingsWithContentAsCsvFileRequest
    >
  ): void {
    this.io.emit(
      'export_things_with_content_as_csv_file/1',
      sendData,
      (data: TExportThingsWithContentAsCsvFileResponse) => {
        this.genericReturnFunction(
          'exported things with content as csv file',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public exportPicturesOfThingIdAsZipFile(
    thingId: string,
    callback?: TSocketServiceCallbackFunction<
      TExportPicturesOfThingAsZipFileResponse,
      TExportPicturesOfThingAsZipFileRequest
    >
  ): void {
    const sendData = {
      thingId: thingId
    };
    this.io.emit(
      'export_pictures_of_thing_as_zip_file/1',
      sendData,
      (response: TExportPicturesOfThingAsZipFileResponse) => {
        this.genericReturnFunction(
          'exported pictures of thing as zip file',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public importThingGroupsFromCsvFile(
    sendData: TImportThingGroupsFromCsvFileRequest,
    callback: TSocketServiceCallbackFunction<
      TImportFromCsvFileResponse,
      TImportThingGroupsFromCsvFileRequest
    >
  ): void {
    this.io.emit(
      'import_thing_groups_from_csv_file/1',
      sendData,
      (response: TImportFromCsvFileResponse) => {
        this.genericReturnFunction(
          'imported thing groups from csv file',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportStructureTemplateAsCsvFile(
    sendData: IExportStructureTemplateAsCsvFileRequest,
    callback: TSocketServiceCallbackFunction<
      TExportStructureTemplateAsCsvFileResponse,
      IExportStructureTemplateAsCsvFileRequest
    >
  ): void {
    this.io.emit(
      'export_structure_template_as_csv_file/1',
      sendData,
      (response: TExportStructureTemplateAsCsvFileResponse) => {
        this.genericReturnFunction(
          'exported structure template as csv file',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public exportTextBrickTemplatesAsCsvFile(
    sendData: ExportTextBrickTemplatesAsCsvFileRequest,
    callback: TSocketServiceCallbackFunction<
      ExportTextBrickTemplatesAsCsvFileResponse,
      ExportTextBrickTemplatesAsCsvFileRequest
    >
  ): void {
    this.io.emit(
      'export_text_brick_templates_as_csv_file/1',
      sendData,
      (response: ExportTextBrickTemplatesAsCsvFileResponse) => {
        this.genericReturnFunction(
          'exported text brick templates as csv file',
          response,
          sendData,
          callback
        );
      }
    );
  }

  /**
   * internal socket command
   * This function is not typed because it currently isn't in use.
   * I think this function was only used for development usage
   * @deprecated
   */
  private importDataDump(
    userId: string,
    email: string,
    timestamp: number,
    callback?: TSocketServiceCallbackFunction<
      ImportDataDumpResponse,
      ImportDataDumpRequest
    >
  ): void {
    const sendData: ImportDataDumpRequest = {
      userId: userId,
      email: email,
      timestamp: timestamp
    };
    this.io.emit('import data dump', sendData, (data: any) => {
      this.genericReturnFunction(
        'imported data dump',
        data,
        sendData,
        callback
      );
    });
  }

  /**
   * This function is not typed because it currently isn't in use.
   * I think this function was only used for development usage
   * @deprecated
   */
  private importProjectPictureFolder(
    projectId: string,
    folderName: string = '',
    callback?: TSocketServiceCallbackFunction<
      ImportProjectPictureFolderResponse,
      ImportProjectPictureFolderRequest
    >
  ): void {
    const sendData: ImportProjectPictureFolderRequest = {
      projectId: projectId,
      folderName: folderName
    };
    this.io.emit('import_project_picture_folder/1', sendData, (data: any) => {
      this.genericReturnFunction(
        'imported project picture folder',
        data,
        sendData,
        callback
      );
    });
  }

  public uploadCapturedPicture(
    userId: string,
    fileName: string,
    dataUrl: string,
    callback?: TSocketServiceCallbackFunction<
      UploadCapturedPictureResponse,
      UploadCapturedPictureRequest
    >
  ): void {
    const sendData: UploadCapturedPictureRequest = {
      userId: userId,
      dataUrl: dataUrl,
      fileName: fileName
    };
    this.io.emit(
      'upload_captured_picture/1',
      sendData,
      (data: UploadCapturedPictureResponse) => {
        this.genericReturnFunction(
          'uploaded captured picture',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public uploadUnsynchedItems(
    sendData: UploadUnsynchedItemsRequest,
    callback: TSocketServiceCallbackFunction<
      UploadUnsynchedItemsResponse,
      UploadUnsynchedItemsRequest
    >
  ): void {
    this.io.emit(
      'upload_unsynched_items/1',
      sendData,
      (data: UploadUnsynchedItemsResponse) => {
        this.genericReturnFunction(
          'uploaded unsynched items',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public uploadFileUploadItem(
    sendData: UploadFileUploadItemsRequest,
    callback: TSocketServiceCallbackFunction<
      UploadFileUploadItemsResponse,
      UploadFileUploadItemsRequest
    >
  ): void {
    this.io.emit(
      'upload_file_upload_item/1',
      sendData,
      (data: UploadFileUploadItemsResponse) => {
        this.genericReturnFunction(
          'uploaded file upload item',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public searchGlobally(
    sendData: SearchGloballyRequest,
    callback?: TSocketServiceCallbackFunction<
      SearchGloballyResponse,
      SearchGloballyRequest
    >
  ): void {
    this.io.emit(
      'search_globally/2',
      sendData,
      (data: SearchGloballyResponse) => {
        this.genericReturnFunction(
          'searched globally',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public searchByNfcTokenId(
    sendData: SearchByNfcTokenIdRequest,
    callback?: TSocketServiceCallbackFunction<
      SearchByNfcTokenIdResponse,
      SearchByNfcTokenIdRequest
    >
  ): void {
    this.io.emit(
      'search_by_nfc_token_id/2',
      sendData,
      (data: SearchByNfcTokenIdResponse) => {
        this.genericReturnFunction(
          'search by nfc token id',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public sendEmailVerification(
    userId: string,
    newEmail: string,
    callback: TSocketServiceCallbackFunction<
      IChangeUserEmailWithVerificationResponse,
      IChangeUserEmailWithVerificationRequest
    >
  ): void {
    const sendData: IChangeUserEmailWithVerificationRequest = {
      userId: userId,
      newEmail: newEmail
    };

    this.io.emit(
      'change_user_email_with_verification/1',
      sendData,
      (data: IChangeUserEmailWithVerificationResponse) => {
        this.genericReturnFunction(
          'sent email verification',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public setNewUserPassword(
    userId: string,
    oldPassword: string | null,
    newPassword: string,
    callback: TSocketServiceCallbackFunction<
      TChangeUserPasswordResponse,
      IChangeUserPasswordRequest
    >
  ): void {
    const sendData: IChangeUserPasswordRequest = {
      userId: userId,
      oldPassword: oldPassword,
      newPassword: newPassword
    };

    this.io.emit(
      'change_user_password/1',
      sendData,
      (data: TChangeUserPasswordResponse) => {
        this.genericReturnFunction(
          'set new user password',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public requestAccountDeletion(
    userId: string,
    callback: TSocketServiceCallbackFunction<
      RequestAccountDeletionResponse,
      RequestAccountDeletionRequest
    >
  ): void {
    const sendData: RequestAccountDeletionRequest = {
      userId
    };

    this.io.emit(
      'request_account_deletion/1',
      sendData,
      (data: RequestAccountDeletionResponse) => {
        this.genericReturnFunction(
          'account deletion requested',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public createUser(
    userName: string,
    email: string,
    password: string,
    callback: TSocketServiceCallbackFunction<
      TCreateUserResponse,
      ICreateUserRequest
    >
  ): void {
    const sendData: ICreateUserRequest = {
      userName: userName,
      email: email,
      password: password
    };
    this.io.emit('create_user/1', sendData, (data: TCreateUserResponse) => {
      this.genericReturnFunction('created new user', data, sendData, callback);
    });
  }

  public inviteUser(
    sendData: IInviteUserRequest,
    callback: TSocketServiceCallbackFunction<
      IInviteUserResponse,
      IInviteUserRequest
    >
  ): void {
    this.io.emit('invite_user/1', sendData, (data: IInviteUserResponse) => {
      this.genericReturnFunction('invited new user', data, sendData, callback);
    });
  }

  public changeExternalUserCredentialPassword(
    externalUserCredentialId: string,
    password: string,
    callback: TSocketServiceCallbackFunction<
      TChangeExternalUserCredentialPasswordResponse,
      IChangeExternalUserCredentialPasswordRequest
    >
  ): void {
    const sendData: IChangeExternalUserCredentialPasswordRequest = {
      externalUserCredentialId: externalUserCredentialId,
      password: password
    };
    this.io.emit(
      'change_external_user_credential_password/1',
      sendData,
      (data: TChangeExternalUserCredentialPasswordResponse) => {
        this.genericReturnFunction(
          'changed external user credential password',
          data,
          sendData,
          callback
        );
      }
    );
  }

  public joinProcessTaskGroup(
    request: TJoinOrLeaveProcessTaskGroupRequest,
    callback: TSocketServiceCallbackFunction<
      TJoinOrLeaveProcessTaskGroupResponse,
      TJoinOrLeaveProcessTaskGroupRequest
    >
  ): void {
    this.io.emit(
      'join_process_task_group/1',
      request,
      (response: TJoinOrLeaveProcessTaskGroupResponse) => {
        this.genericReturnFunction(
          `joined process task group ${request.processTaskGroupId}`,
          response,
          request,
          callback
        );
      }
    );
  }

  public leaveProcessTaskGroup(
    request: TJoinOrLeaveProcessTaskGroupRequest,
    callback: TSocketServiceCallbackFunction<
      TJoinOrLeaveProcessTaskGroupResponse,
      TJoinOrLeaveProcessTaskGroupRequest
    >
  ): void {
    this.io.emit(
      'leave_process_task_group/1',
      request,
      (response: TJoinOrLeaveProcessTaskGroupResponse) => {
        this.genericReturnFunction(
          `left process task group ${request.processTaskGroupId}`,
          response,
          request,
          callback
        );
      }
    );
  }

  public joinDefect(
    request: JoinOrLeaveDefectGroupRequest,
    callback: TSocketServiceCallbackFunction<
      JoinOrLeaveDefectGroupResponse,
      JoinOrLeaveDefectGroupRequest
    >
  ): void {
    this.io.emit(
      'join_defect_group/1',
      request,
      (response: JoinOrLeaveDefectGroupResponse) => {
        this.genericReturnFunction(
          `joined defect group ${request.defectId}`,
          response,
          request,
          callback
        );
      }
    );
  }

  public leaveDefect(
    request: JoinOrLeaveDefectGroupRequest,
    callback: TSocketServiceCallbackFunction<
      JoinOrLeaveDefectGroupResponse,
      JoinOrLeaveDefectGroupRequest
    >
  ): void {
    this.io.emit(
      'leave_defect_group/1',
      request,
      (response: JoinOrLeaveDefectGroupResponse) => {
        this.genericReturnFunction(
          `left defect group ${request.defectId}`,
          response,
          request,
          callback
        );
      }
    );
  }

  public getProcessTaskGroupsWithSubEntities(
    filter: GetProcessTaskGroupsFilter,
    callback: (
      arg0: TGetProcessTaskGroupsWithSubEntitiesResponse<string, string>
    ) => void
  ): void {
    this.io.emit(
      'get_process_task_groups_with_sub_entities/1',
      filter,
      callback
    );
  }

  public getProcessTaskGroupsWithSubEntitiesForProcessTaskGroup(
    request: TGetProcessTaskGroupsWithSubEntitiesForProcessTaskRequest
  ): Promise<TGetProcessTaskGroupsWithSubEntitiesResponse<string, string>> {
    return new Promise((resolve) => {
      this.io.emit(
        'get_process_task_groups_with_sub_entities_for_process_task/1',
        request,
        resolve
      );
    });
  }

  public getCalendarAppointments(
    request: GetCalendarAppointmentsRequest,
    callback: TSocketServiceCallbackFunction<
      GetCalendarAppointmentsResponse,
      GetCalendarAppointmentsRequest
    >
  ): void {
    this.io.emit(
      'get_calendar_appointments/2',
      request,
      (response: GetCalendarAppointmentsResponse) => {
        callback(response, request);
      }
    );
  }

  public getGalleryThingPictureOverviewEntries(
    request: GetPictureOverviewEntryGroupsRequest,
    callback: TSocketServiceCallbackFunction<
      GetPictureOverviewEntryGroupsResponse<CommonProject>,
      GetPictureOverviewEntryGroupsRequest
    >
  ): void {
    this.io.emit(
      'get_gallery_thing_picture_overview_entries/1',
      request,
      (response: GetPictureOverviewEntryGroupsResponse<CommonProject>) => {
        callback(response, request);
      }
    );
  }

  public getProcessTaskGroupRelationInfos<
    T extends ProcessTaskGroupRelationInfoRequestType
  >(
    request: GetProcessTaskGroupRelationInfoRequest<T>,
    callback: TSocketServiceCallbackFunction<
      GetProcessTaskGroupRelationInfoResponse,
      GetProcessTaskGroupRelationInfoRequest<T>
    >
  ): void {
    this.io.emit(
      'get_process_task_group_relation_infos/1',
      request,
      (response: GetProcessTaskGroupRelationInfoResponse) => {
        callback(response, request);
      }
    );
  }

  public setProcessTaskGroupToProcessTaskGroupRelationStatus(
    request: SetProcessTaskGroupToProcessTaskGroupRelationRequest
  ): Promise<SetProcessTaskGroupToProcessTaskGroupRelationResponse> {
    return new Promise((resolve) => {
      this.io.emit(
        'set_process_task_group_to_process_task_group_relation_status/1',
        request,
        resolve
      );
    });
  }

  public getProcessTaskToProjectRelationInfos(
    request: GetProcessTaskToProjectRelationInfoRequest,
    callback: TSocketServiceCallbackFunction<
      GetProcessTaskToProjectRelationInfoResponse,
      GetProcessTaskToProjectRelationInfoRequest
    >
  ): void {
    this.io.emit(
      'get_process_task_to_project_relation_infos/1',
      request,
      (response: GetProcessTaskToProjectRelationInfoResponse) => {
        callback(response, request);
      }
    );
  }

  public setProcessTaskToProjectToRelationStatus(
    request: SetProcessTaskToProjectRelationRequest
  ): Promise<SetProcessTaskToProjectRelationResponse> {
    return new Promise((resolve) => {
      this.io.emit(
        'set_process_task_to_project_relation_status/1',
        request,
        resolve
      );
    });
  }

  public sendOperationsEmail(
    request: SendOperationsEmailRequest,
    callback: TSocketServiceCallbackFunction<
      SendOperationsEmailResponse,
      SendOperationsEmailRequest
    >
  ): void {
    this.io.emit(
      'send_operations_email/1',
      request,
      (response: SendOperationsEmailResponse) => {
        callback(response, request);
      }
    );
  }

  public sendOperationsFormsOfAppointment(
    request: SendOperationsFormsOfAppointmentRequest
  ): Promise<SendOperationsFormsOfAppointmentResponse> {
    return new Promise((resolve) => {
      this.io.emit('send_operations_forms_of_appointment/1', request, resolve);
    });
  }

  public getProcessTaskGroupsAndTasks(
    request: GetProcessTaskGroupsAndTasksRequest,
    callback: TSocketServiceCallbackFunction<
      GetProcessTaskGroupsAndTasksResponse,
      GetProcessTaskGroupsAndTasksRequest
    >
  ): void {
    this.io.emit(
      'get_process_task_groups_and_tasks/1',
      request,
      (response: GetProcessTaskGroupsAndTasksResponse) => {
        this.genericReturnFunction(
          'downloaded process task groups & tasks',
          response,
          request,
          callback
        );
      }
    );
  }

  public checkUsersAreAvailable(
    request: CheckUsersAreAvailableRequest
  ): Promise<CheckUsersAreAvailableResponse> {
    return new Promise((resolve) => {
      this.io.emit('check_users_are_available/1', request, resolve);
    });
  }

  public downloadProcessTaskOffer(
    processTaskOfferId: string,
    callback: TSocketServiceCallbackFunction<
      OperationsExportOfferResponse,
      OperationsExportOfferRequest
    >
  ): void {
    const sendData = {
      processTaskOfferId: processTaskOfferId
    };
    this.io.emit(
      'operations/export_offer/1',
      sendData,
      (response: OperationsExportOfferResponse) => {
        this.genericReturnFunction(
          'downloaded process task offer',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public downloadProcessTaskInvoice(
    processTaskInvoiceId: string,
    callback: TSocketServiceCallbackFunction<
      OperationsExportInvoiceResponse,
      OperationsExportInvoiceRequest
    >
  ): void {
    const sendData = {
      processTaskInvoiceId: processTaskInvoiceId
    };
    this.io.emit(
      'operations/export_invoice/1',
      sendData,
      (response: OperationsExportInvoiceResponse) => {
        this.genericReturnFunction(
          'downloaded process task invoice',
          response,
          sendData,
          callback
        );
      }
    );
  }

  public downloadProcessTaskDevices(
    data: OperationsExportDevicesRequest,
    callback: TSocketServiceCallbackFunction<
      OperationsExportDevicesResponse,
      OperationsExportDevicesRequest
    >
  ): void {
    this.io.emit(
      'operations/export_devices/2',
      data,
      (response: OperationsExportDevicesResponse) => {
        this.genericReturnFunction(
          'downloaded process task devices',
          response,
          data,
          callback
        );
      }
    );
  }

  public downloadProcessTaskForm(
    request: OperationsExportFormRequest,
    callback: TSocketServiceCallbackFunction<
      OperationsExportFormResponse,
      OperationsExportFormRequest
    >
  ): void {
    this.io.emit(
      'operations/export_form/1',
      request,
      (response: OperationsExportFormResponse) => {
        this.genericReturnFunction(
          'downloaded process task form',
          response,
          request,
          callback
        );
      }
    );
  }

  public downloadMeasurePoints(
    request: OperationsExportMeasurePointsRequest,
    callback: TSocketServiceCallbackFunction<
      OperationsExportMeasurePointsResponse,
      OperationsExportMeasurePointsRequest
    >
  ): void {
    this.io.emit(
      'operations/export_measure_points/1',
      request,
      (response: OperationsExportMeasurePointsResponse) => {
        this.genericReturnFunction(
          'downloaded measure points',
          response,
          request,
          callback
        );
      }
    );
  }

  public exportPositionsAsCsvFile(
    request: OperationsExportPositionsAsCsvRequest,
    callback: TSocketServiceCallbackFunction<
      OperationsExportPositionsAsCsvResponse,
      OperationsExportPositionsAsCsvRequest
    >
  ): void {
    this.io.emit(
      'operations/export_positions_as_csv_file/1',
      request,
      (response: OperationsExportPositionsAsCsvResponse) => {
        this.genericReturnFunction(
          'exported process configuration positions',
          response,
          request,
          callback
        );
      }
    );
  }

  public exportMarkedPicture(
    request: ExportMarkedPictureRequest,
    callback: TSocketServiceCallbackFunction<
      ExportMarkedPictureResponse,
      ExportMarkedPictureRequest
    >
  ): void {
    this.io.emit(
      'export_marked_picture/1',
      request,
      (response: ExportMarkedPictureResponse) => {
        this.genericReturnFunction(
          'exported marked picture',
          response,
          request,
          callback
        );
      }
    );
  }

  public exportMarkedPictures(
    request: ExportMarkedPicturesRequest,
    callback: TSocketServiceCallbackFunction<
      ExportMarkedPicturesResponse,
      ExportMarkedPicturesRequest
    >
  ): void {
    this.io.emit(
      'export_marked_pictures/1',
      request,
      (response: ExportMarkedPicturesResponse) => {
        this.genericReturnFunction(
          'exported marked pictures',
          response,
          request,
          callback
        );
      }
    );
  }

  public impersonateUser(
    request: IImpersonateUserRequest,
    callback: TSocketServiceCallbackFunction<
      TImpersonateUserResponse,
      IImpersonateUserRequest
    >
  ): void {
    this.io.emit(
      'impersonate_user/1',
      request,
      (response: TImpersonateUserResponse) => {
        this.genericReturnFunction(
          'impersonated user',
          response,
          request,
          callback
        );
      }
    );
  }

  public registerBinding<
    TName extends PropertyBinderName<SocketServicePropertyBinderConfig>
  >(
    name: TName,
    callback: PropertyBinderCallback<SocketServicePropertyBinderConfig, TName>
  ): Disposable {
    return this.propertyBinder.registerBinding(name, callback);
  }

  public uploadGeneralFile(
    request: TUploadGeneralFileRequest,
    callback: TSocketServiceCallbackFunction<
      TUploadGeneralFileResponse,
      TUploadGeneralFileRequest
    >
  ): void {
    this.io.emit(
      'upload_general_file/2',
      request,
      (response: TUploadGeneralFileResponse) => {
        this.genericReturnFunction(
          'uploaded general file',
          response,
          request,
          callback
        );
      }
    );
  }

  public fileChunkUploadStart(
    request: FileChunkUploadStartRequest
  ): Promise<FileChunkFileInfoResponse> {
    return new Promise((resolve) => {
      this.io.emit('file_chunk_upload_start/1', request, resolve);
    });
  }

  public fileChunkUploadUploadChunk(
    request: FileChunkUploadUploadChunkRequest
  ): Promise<FileChunkFileInfoResponse> {
    return new Promise((resolve) => {
      this.io.emit('file_chunk_upload_upload_chunk/1', request, resolve);
    });
  }

  public fileChunkUploadStatus(
    request: FileChunkUploadStatusRequest
  ): Promise<FileChunkFileInfoResponse> {
    return new Promise((resolve) => {
      this.io.emit('file_chunk_upload_status/1', request, resolve);
    });
  }

  public sendMissedAppointmentsNotification(
    request: MissedAppointmentsNotificationRequest
  ): Promise<MissedAppointmentsNotificationResponse> {
    return new Promise((resolve) => {
      this.io.emit('notify_missed_appointments/1', request, resolve);
    });
  }

  public addUserToUserGroup(
    request: AddUserToUserGroupRequest,
    callback: TSocketServiceCallbackFunction<
      AddUserToUserGroupResponse,
      AddUserToUserGroupRequest
    >
  ): void {
    this.io.emit(
      'add_user_to_user_group/1',
      request,
      (response: AddUserToUserGroupResponse) => {
        this.genericReturnFunction(
          'add user to user group',
          response,
          request,
          callback
        );
      }
    );
  }

  public executeDeveloperCommand<TCommandName extends DeveloperCommandName>(
    request: ExecuteDeveloperCommandRequest<TCommandName>,
    callback: TSocketServiceCallbackFunction<
      ExecuteDeveloperCommandResponse,
      ExecuteDeveloperCommandRequest<TCommandName>
    >
  ): void {
    this.io.emit(
      'execute_developer_command/1',
      request,
      (response: ExecuteDeveloperCommandResponse) => {
        this.genericReturnFunction(
          'executed developer command',
          response,
          request,
          callback
        );
      }
    );
  }

  private runCallbacks(): void {
    for (const callback of this.callbacksToRunAfterAuthentication) {
      callback();
    }

    this.callbacksToRunAfterAuthentication = [];
  }

  // ********** MESSAGE HANDLER **********

  private onConnect(): void {
    console.log('SOCKET SERVICE: connected');
    this.eventAggregator.publish('socket:connected');
    this.propertyBinder.setValue('isConnected', this.isConnected());
    void this.authenticate();
  }

  public async authenticate(): Promise<void> {
    const requestData: AuthRequest = {
      user_id: await this.sessionService.getCurrentUserId(),
      token: await this.sessionService.getCurrentJWT(),
      deviceId: DeviceInfoHelper.getUUID(),
      sessionId: this.sessionService.getCurrentSessionId(),
      clientSupportsEncoding: true
    };

    this.io.emit('auth', requestData, (data: AuthResponse) => {
      void this.onAuthReturn(data);
    });
  }

  private async onAuthReturn(data: AuthResponse): Promise<void> {
    if (data && data.success) {
      console.log('SOCKET SERVICE: authenticated', data);
      if (data.newToken) {
        await this.sessionService.setCurrentJWT(data.newToken);
      }
      if (data.sessionId) {
        await this.sessionService.setCurrentSessionId(data.sessionId);
      }
      this.isAuthenticatedValue = true;
      this.runCallbacks();
      this.eventAggregator.publish('socket:authenticated');
      this.propertyBinder.setValue('isAuthenticated', this.isAuthenticated());
    } else {
      console.log('SOCKET SERVICE: not authenticated', data);
      await this.sessionService.clearStoredData();
      window.location.reload();
    }
  }

  private onDisconnect(): void {
    console.log('SOCKET SERVICE: disconnected');
    this.isAuthenticatedValue = false;
    this.eventAggregator.publish('socket:disconnected');
    this.propertyBinder.setValue('isConnected', this.isConnected());
    this.propertyBinder.setValue('isAuthenticated', this.isAuthenticated());
  }

  private genericReturnFunction<TResponse, TRequest>(
    logMessage: string,
    response: TResponse,
    request: TRequest,
    callback?: TSocketServiceCallbackFunction<TResponse, TRequest>
  ): void {
    console.log('SOCKET SERVICE:', logMessage, response);
    if (typeof callback === 'function') callback(response, request);
  }

  /**
   * executes the runner immediately if we are already authenticated, else it executes it as soon as we are authenticated
   */
  private runAfterAuthentication(runner: Function): void {
    if (!this.isAuthenticatedValue) {
      this.callbacksToRunAfterAuthentication.push(runner);
    } else {
      runner();
    }
  }
}

export type TSocketServiceCallbackFunction<TResponse, TRequest> = (
  response: TResponse,
  request: TRequest
) => void;

export type SocketServicePropertyBinderConfig = {
  isConnected: boolean;
  isAuthenticated: boolean;
};
