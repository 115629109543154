import { observable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';
import { PictureFileType } from 'common/Types/Entities/PictureFile/PictureFileDto';
import { GalleryThingPictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';
import { PictureIconConfig } from 'common/GalleryThing/GalleryThingIconHelper';
import { GalleryThingPictureGroupHelper } from 'common/GalleryThing/GalleryThingPictureGroupHelper';
import { PaginationInfo } from 'common/EndpointTypes/GalleryThingPictureOverviewEndpointsHandler';

import { AppEntityManager } from '../../../../classes/EntityManager/entities/AppEntityManager';
import {
  Disposable,
  DisposableContainer
} from '../../../../classes/Utils/DisposableContainer';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import {
  GalleryThingPictureDataSourceStrategy,
  SubscribeOptions
} from './GalleryThingPictureDataSourceStrategy';
import { Project } from '../../../../classes/EntityManager/entities/Project/types';
import { Defect } from '../../../../classes/EntityManager/entities/Defect/types';
import { Picture } from '../../../../classes/EntityManager/entities/Picture/types';
import { EntityName } from '../../../../classes/EntityManager/entities/types';
import { GalleryThingPictureFilterService } from '../../../../services/GalleryThingPictureFilterService/GalleryThingPictureFilterService';
import {
  GalleryThingPictureFilterHandle,
  SupportedPicture
} from '../../../../services/GalleryThingPictureFilterService/GalleryThingPictureFilterHandle';
import {
  GalleryThingPictureOverviewEntry,
  GalleryThingPictureOverviewEntryGroup,
  GalleryThingPictureOverviewEntryHelper
} from '../../../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { GalleryThingIconGenerator } from '../../../../classes/GalleryThing/GalleryThingIconGenerator';
import { ActiveUserCompanySettingService } from '../../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import {
  PropertyCache,
  PropertyCacheComputer
} from '../../../../computedValues/computers/PropertyCacheComputer/PropertyCacheComputer';
import { ComputedValueService } from '../../../../computedValues/ComputedValueService';
import {
  EntryToPersonCache,
  EntryToPersonCacheComputer
} from '../../../../computedValues/computers/EntryToPersonCacheComputer/EntryToPersonCacheComputer';
import { PictureFilePathService } from '../../../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { PictureFile } from '../../../../classes/EntityManager/entities/PictureFile/types';
import { GalleryThingBaseMapMarkerGenerator } from '../../../../classes/GalleryThing/GalleryThingBaseMapMarkerGenerator';
import { BaseMapDataCallbacks } from '../GalleryThingPictureDataSource';
import { SubscriptionManager } from '../../../../classes/SubscriptionManager';
import { GalleryThingPictureCreatorService } from '../../../../services/GalleryThingPictureCreatorService';
import { MapMarker } from '../../../../map/basemap-map/basemap-map';
import { PermissionsService } from '../../../../services/PermissionsService/PermissionsService';
import { SubscribableArray } from '../../../../classes/SubscribableArray/SubscribableArray';

export class GalleryThingPicturesFromEntityManagerStrategy extends GalleryThingPictureDataSourceStrategy {
  private readonly activeUserCompanySettingService: ActiveUserCompanySettingService;
  private readonly computedValueService: ComputedValueService;
  private readonly pictureFilePathService: PictureFilePathService;
  private readonly subscriptionManager: SubscriptionManager;
  private readonly galleryThingPictureCreatorService: GalleryThingPictureCreatorService;
  private readonly permissionsService: PermissionsService;

  private readonly filterHandle: GalleryThingPictureFilterHandle;
  private availablePictureOverviewEntries: Array<GalleryThingPictureOverviewEntry> =
    [];
  private availablePictures: Array<SupportedPicture> = [];

  private currentFilter: GalleryThingPictureFilter | null = null;
  private paginationInfo: PaginationInfo = {
    currentIndex: 1,
    currentPageSize: 10
  };

  private updatePaginationMaxIndex: (totalCount: number) => void;

  private propertyCache: PropertyCache | null = null;
  private entryToPersonCache: EntryToPersonCache | null = null;

  @observable
  private pictureIconsConfiguration: Array<PictureIconConfig> | null = null;

  constructor(options: {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    galleryThingPictureFilterService: GalleryThingPictureFilterService;
    activeUserCompanySettingService: ActiveUserCompanySettingService;
    computedValueService: ComputedValueService;
    pictureFilePathService: PictureFilePathService;
    galleryThingPictureCreatorService: GalleryThingPictureCreatorService;
    baseMapDataCallbacks: BaseMapDataCallbacks;
    updatePaginationMaxIndex: (totalCount: number) => void;
    permissionsService: PermissionsService;
  }) {
    super(options);
    this.filterHandle = options.galleryThingPictureFilterService.createHandle();
    this.activeUserCompanySettingService =
      options.activeUserCompanySettingService;
    this.computedValueService = options.computedValueService;
    this.pictureFilePathService = options.pictureFilePathService;
    this.permissionsService = options.permissionsService;
    this.subscriptionManager = options.subscriptionManagerService.create();
    this.galleryThingPictureCreatorService =
      options.galleryThingPictureCreatorService;
    this.updatePaginationMaxIndex = options.updatePaginationMaxIndex;
  }

  public subscribe(options: SubscribeOptions): Disposable {
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.Picture,
      options.onDataChanged.bind(this)
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureFile,
      options.onDataChanged.bind(this)
    );

    this.subscriptionManager.addDisposable(this.filterHandle.subscribe());

    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindJSONSettingProperty(
        'via.pictureIconsConfiguration',
        (pictureIconsConfiguration) => {
          this.pictureIconsConfiguration = pictureIconsConfiguration;
        }
      )
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: PropertyCacheComputer,
        computeData: {},
        callback: (propertyCache) => {
          this.propertyCache = propertyCache;
          this.updateIcons();
          this.updateBaseMapMarkers();
        }
      })
    );

    this.subscriptionManager.addDisposable(
      this.computedValueService.subscribe({
        valueComputerClass: EntryToPersonCacheComputer,
        computeData: {},
        callback: (cache) => {
          this.entryToPersonCache = cache;
          this.updateIcons();
        }
      })
    );

    return {
      dispose: () => {
        this.subscriptionManager.disposeSubscriptions();
      }
    };
  }

  public setFilter(
    filter: GalleryThingPictureFilter,
    paginationInfo: PaginationInfo
  ): void {
    this.currentFilter = filter;
    this.paginationInfo = paginationInfo;
    this.filterHandle.setFilter(this.currentFilter);
  }

  public async updatePictures(): Promise<void> {
    assertNotNullOrUndefined(
      this.options,
      'cannot get available pictures without getGalleryThingPicturesOptions'
    );
    const availableDefects = this.options.includeDefects
      ? this.entityManager.defectRepository.getByOwnerThingId(
          this.options.thing.id
        )
      : [];

    this.availablePictures = [
      ...this.getAvailablePicturesOfProjects(this.options.projects),
      ...this.getAvailablePicturesOfDefects(availableDefects)
    ] as Array<SupportedPicture>;

    const pictureFiles = this.getPictureFilesOfPictures(this.availablePictures);
    await this.updatePictureOverviewEntries(
      this.availablePictures,
      pictureFiles
    );
    this.filterHandle.setPicturesAndOverviewEntries(
      this.availablePictures,
      this.availablePictureOverviewEntries
    );
  }

  public bindFilteredPictureGroups(
    callback: (
      filteredPictureGroups: Array<GalleryThingPictureOverviewEntryGroup>
    ) => void
  ): Disposable {
    const disposableContainer = new DisposableContainer();
    const subscribableArray =
      new SubscribableArray<GalleryThingPictureOverviewEntryGroup>({
        getSubscribableFromItem: (item) => item.projectPermissionsHandle
      });

    disposableContainer.add(subscribableArray.subscribe());

    disposableContainer.add(
      this.filterHandle.bindFilteredPictureOverviewEntries(
        (overviewEntries) => {
          void this.createPaginatedGroupsAndUpdateMaxIndex({
            overviewEntries
          }).then((paginatedGroups) => {
            subscribableArray.items = paginatedGroups;
            callback(subscribableArray.items);
          });
        }
      )
    );

    return disposableContainer.toDisposable();
  }

  protected pictureIconsConfigurationChanged(): void {
    this.updateIcons();
  }

  private updateIcons(): void {
    assertNotNullOrUndefined(
      this.availablePictureOverviewEntries,
      'cannot update icons without computed picture overview entries.'
    );
    if (
      !this.propertyCache ||
      !this.entryToPersonCache ||
      !this.pictureIconsConfiguration
    )
      return;

    const iconGenerator = new GalleryThingIconGenerator(
      this.entryToPersonCache,
      this.propertyCache,
      this.pictureIconsConfiguration
    );
    for (const entry of this.availablePictureOverviewEntries) {
      const icons = iconGenerator.generatePictureIcons(entry);
      entry.icons = icons;
    }
    this.filterHandle.setPicturesAndOverviewEntries(
      this.availablePictures,
      this.availablePictureOverviewEntries
    );
  }

  private updateBaseMapMarkers(): void {
    assertNotNullOrUndefined(
      this.availablePictureOverviewEntries,
      'cannot update markers without computed picture overview entries.'
    );
    if (!this.propertyCache) return;

    for (const entry of this.availablePictureOverviewEntries) {
      entry.baseMapMarker =
        GalleryThingBaseMapMarkerGenerator.generateMapMarker(
          entry,
          this.propertyCache,
          this.onMarkerClicked
        );
    }
    this.filterHandle.setPicturesAndOverviewEntries(
      this.availablePictures,
      this.availablePictureOverviewEntries
    );
    this.onMarkersChanged();
  }

  private async updatePictureOverviewEntries(
    allPictures: Array<SupportedPicture>,
    pictureFiles: Array<PictureFile>
  ): Promise<void> {
    this.availablePictureOverviewEntries = [];

    const iconGenerator =
      !!this.propertyCache &&
      !!this.entryToPersonCache &&
      !!this.pictureIconsConfiguration
        ? new GalleryThingIconGenerator(
            this.entryToPersonCache,
            this.propertyCache,
            this.pictureIconsConfiguration
          )
        : null;

    for (const p of allPictures) {
      const entry =
        await GalleryThingPictureOverviewEntryHelper.reducePictureToPictureOverviewEntry(
          p,
          this.entityManager,
          this.getPictureFileToDisplayByPictureId(p.id, pictureFiles),
          iconGenerator,
          this.pictureFilePathService,
          this.propertyCache,
          this.onMarkerClicked
        );

      // TODO after REC-3425 is released + devCommand to repair data has been run: remove this hotfix
      if (!entry.createdAt && entry.ownerDefectId) {
        entry.createdAt =
          this.entityManager.defectRepository.getById(entry.ownerDefectId)
            ?.createdAt ?? '';
      }
      this.availablePictureOverviewEntries?.push(entry);
    }

    this.onMarkersChanged();
  }

  private async createPaginatedGroupsAndUpdateMaxIndex({
    overviewEntries
  }: {
    overviewEntries: Array<GalleryThingPictureOverviewEntry>;
  }): Promise<Array<GalleryThingPictureOverviewEntryGroup>> {
    assertNotNullOrUndefined(
      this.options,
      'Options missing, cannot create picture groups without Projects set in options.'
    );
    const groups = await GalleryThingPictureGroupHelper.groupByProject<
      MapMarker,
      Project
    >(
      this.options.projects,
      overviewEntries,
      this.getOrCreateEmptyPictureGroup.bind(this),
      {
        filterIsActive: this.currentFilter?.isActive ?? false
      }
    );

    this.updatePaginationMaxIndex(groups.length);
    const paginatedGroups =
      GalleryThingPictureGroupHelper.getSliceForPagination<Project>(
        groups,
        this.paginationInfo
      );

    return paginatedGroups.map((g) => {
      return {
        ...g,
        projectPermissionsHandle:
          this.permissionsService.getPermissionsHandleForEntity({
            entityName: EntityName.Project,
            entity: g.project
          })
      };
    });
  }

  private async getOrCreateEmptyPictureGroup(date: Date): Promise<Project> {
    assertNotNullOrUndefined(
      this.options,
      'Options missing, cannot create an empty picture group without a Thing set in options.'
    );

    return this.galleryThingPictureCreatorService.getOrCreateProjectForDate(
      this.options.thing,
      date
    );
  }

  public getPictureFileToDisplayByPictureId(
    pictureId: string,
    allPictureFiles: Array<PictureFile>
  ): PictureFile | null {
    const relevantPictureFiles = allPictureFiles.filter(
      (pf) => pf.picture === pictureId
    );
    return (
      relevantPictureFiles.find((pf) => pf.type === PictureFileType.EDITED) ??
      relevantPictureFiles.find((pf) => pf.type === PictureFileType.ORIGINAL) ??
      null
    );
  }

  private getAvailablePicturesOfProjects(
    projects: Array<Project>
  ): Array<Picture> {
    let availablePictures: Array<Picture> = [];

    projects.forEach((project) => {
      availablePictures = availablePictures.concat(
        this.getAvailablePicturesOfProject(project)
      );
    });

    return availablePictures;
  }

  private getPictureFilesOfPictures(
    pictures: Array<Picture>
  ): Array<PictureFile> {
    const allPictureFiles = this.entityManager.pictureFileRepository.getAll();
    return allPictureFiles.filter((pf) =>
      pictures.some((p) => p.id === pf.picture)
    );
  }

  private getAvailablePicturesOfDefects(
    defects: Array<Defect>
  ): Array<Picture> {
    return defects
      .map(this.getAvailablePicturesOfDefect.bind(this))
      .reduce((prev, cur) => prev.concat(cur), []);
  }

  private getAvailablePicturesOfProject(project: Project): Array<Picture> {
    const pictures =
      this.entityManager.pictureRepository.getAllPicturesByProjectId(
        project.id
      );
    return pictures.filter((picture) => {
      return picture.entry != null;
    });
  }

  private getAvailablePicturesOfDefect(defect: Defect): Array<Picture> {
    return this.entityManager.pictureRepository.getByOwnerDefectId(defect.id);
  }
}
