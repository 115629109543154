import { DateType, IdType } from '../Types/Entities/Base/types';
import { ProcessConfigurationManualFormSendTargets } from '../Types/Entities/ProcessConfigurationFormSendTarget/ProcessConfigurationFormSendTargetDto';
import { ProcessTaskDto } from '../Types/Entities/ProcessTask/ProcessTaskDto';
import { ProcessTaskGroupDto } from '../Types/Entities/ProcessTaskGroup/ProcessTaskGroupDto';
import { ProcessTaskGroupsWithSubEntities } from '../Types/ProcessTaskGroupsWithSubEntities';

type BaseGetProcessTaskGroupsFilter = {
  type: GetProcessTaskGroupsFilterType;
  pageOffset: number;
  itemsPerPage: number;
  includeArchive: boolean;
  assigneeUserId: string | null;
  searchText: string | null;

  /**
   * if this is null, GetProcessTasksGroupsFilterAddressFilterType.ALL will be used
   * this is nullable only for backwards compatibility reasons
   */
  addressFilterType: GetProcessTaskGroupsFilterAddressFilterType | null;

  /** filter by the ProcessTask.currentProcessConfigurationStepId, null or an empty array means do not filter by it */
  currentProcessConfigurationStepIds: Array<string> | null;

  excludedProcessConfigurationStepIds: Array<string> | null;

  processConfigurationActionStatusFilterType: TProcessConfigurationActionStatusFilterType;
  processConfigurationActionStatusId: string | null;
  customActionStatusSearchText: string | null;
  lastEditedBeforeDateIso: string | null;
};

export type GetProcessTaskGroupAllFilter = BaseGetProcessTaskGroupsFilter & {
  type: GetProcessTaskGroupsFilterType.ALL;
};
export type GetProcessTaskGroupsDateFilter = BaseGetProcessTaskGroupsFilter & {
  type: GetProcessTaskGroupsFilterType.DATE;
  dateFromIso: string;
  dateToIso: string;
};
export type GetProcessTaskGroupsNoAppointmentsAfterDateFilter =
  BaseGetProcessTaskGroupsFilter & {
    type: GetProcessTaskGroupsFilterType.NO_APPOINTMENTS_AFTER_DATE;
    dateIso: string;
  };

export type GetProcessTaskGroupsFilter =
  | GetProcessTaskGroupAllFilter
  | GetProcessTaskGroupsDateFilter
  | GetProcessTaskGroupsNoAppointmentsAfterDateFilter;

export enum GetProcessTaskGroupsFilterType {
  ALL = 'all',
  DATE = 'date',
  NO_APPOINTMENTS_AFTER_DATE = 'noAppointmentsAfterDate'
}

export type TProcessConfigurationActionStatusFilterType =
  ProcessConfigurationActionStatusFilterTypeEnum | null;

export enum ProcessConfigurationActionStatusFilterTypeEnum {
  ID = 'id',
  NONE = 'noActionStatus',
  CUSTOM = 'custom'
}

export enum GetProcessTaskGroupsFilterAddressFilterType {
  ALL = 'all',
  WITH_ADDRESS = 'withAddress',
  WITHOUT_ADDRESS = 'withoutAddress'
}

type TGetProcessTaskGroupsWithSubEntitiesResponseExtension = {
  success: boolean;
  maxPageOffset: number;
};

export type TGetProcessTaskGroupsWithSubEntitiesResponse<
  TId extends IdType,
  TDate extends DateType
> = ProcessTaskGroupsWithSubEntities<TId, TDate> &
  TGetProcessTaskGroupsWithSubEntitiesResponseExtension;

export type TGetProcessTaskGroupsWithSubEntitiesForProcessTaskRequest = {
  processTaskId: string;
};

export type TJoinOrLeaveProcessTaskGroupRequest = {
  processTaskGroupId: string;
};

export type TJoinOrLeaveProcessTaskGroupResponse = {
  success: boolean;
};

export type GetCalendarAppointmentsRequest = {
  dateFromIso: string;
  dateToIso: string;
};

export type GetCalendarAppointmentsSuccessResponse = {
  success: true;
  entries: Array<AppointmentCalendarEntry>;
};

export type GetCalendarAppointmentsErrorResponse = {
  success: false;
};

export type GetCalendarAppointmentsResponse =
  | GetCalendarAppointmentsSuccessResponse
  | GetCalendarAppointmentsErrorResponse;

export type AppointmentCalendarEntry =
  | NormalAppointmentCalendarEntry
  | RecurringAppointmentCalendarEntry;

export type BaseAppointmentCalendarEntry = {
  users: Array<{
    id: string;
    dateFrom: string | null;
    dateTo: string | null;
  }>;
  // @deprecated 2024-06-30
  // Client uses line 1 of dayWidget texts where thing group address is the default
  thingGroup: {
    address: string;
  };
  processTaskGroup: {
    id: string;
    note: string | null;
  };
  processTask: {
    id: string;
    note: string | null;
  };
  dayWidgetTexts: BaseAppointmentCalendarEntryDayWidgetTexts;
};

export type BaseAppointmentCalendarEntryDayWidgetTexts = {
  line1: string;
  line2: string;
  line3: string;
};

export type NormalAppointmentCalendarEntry = BaseAppointmentCalendarEntry & {
  appointment: {
    id: string;
    name: string | null;
    processConfigurationStepId: string | null;
    dateFrom: string;
    dateTo: string;
    finishedAt: string | null;
    note: string | null;
  };
};

export type RecurringAppointmentCalendarEntry = BaseAppointmentCalendarEntry & {
  recurringAppointment: {
    id: string;
    name: string | null;
    processConfigurationStepId: string | null;
    dateFrom: string;
    dateTo: string;
    userGroupId: string;
  };
};

export type SendOperationsEmailRequest = {
  personContactId: string;
  processTaskId: string;

  /** entries which to attach to the E-Mail */
  entryIds: Array<string>;

  /** offers which to attach to the E-Mail */
  offerIds: Array<string>;

  /** invoices which to attach to the E-Mail */
  invoiceIds: Array<string>;

  /**
   * forms which to attach to the E-Mail
   * the processTaskToProjects need to be of type "Form"
   */
  processTaskToProjectIds: Array<string>;

  /** processConfigurationDeviceExport ids to export and attach to the E-Mail */
  deviceExportIds: Array<string>;
  exportMeasurePoints: boolean;
};

export type SendOperationsEmailResponseSuccess = {
  success: true;
};

export type SendOperationsEmailResponseError = {
  success: false;
};

export type SendOperationsEmailResponse =
  | SendOperationsEmailResponseSuccess
  | SendOperationsEmailResponseError;

export type SendOperationsFormsOfAppointmentRequest = {
  processTaskAppointmentId: string;
} & ProcessConfigurationManualFormSendTargets;

export type SendOperationsFormsOfAppointmentResponseSuccess = {
  success: true;
};

export type SendOperationsFormsOfAppointmentResponseError = {
  success: false;
};

export type SendOperationsFormsOfAppointmentResponse =
  | SendOperationsFormsOfAppointmentResponseSuccess
  | SendOperationsFormsOfAppointmentResponseError;

export type GetProcessTaskGroupsAndTasksRequest = {};

export type GetProcessTaskGroupsAndTasksResponseSuccess = {
  success: true;
  entities: {
    processTaskGroups: Array<ProcessTaskGroupDto<string, string>>;
    processTasks: Array<ProcessTaskDto<string, string>>;
  };
};

export type GetProcessTaskGroupsAndTasksResponseError = {
  success: false;
};

export type GetProcessTaskGroupsAndTasksResponse =
  | GetProcessTaskGroupsAndTasksResponseSuccess
  | GetProcessTaskGroupsAndTasksResponseError;
