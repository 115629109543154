import { autoinject, bindable } from 'aurelia-framework';

import { assertNotNullOrUndefined } from 'common/Asserts';

import { computed } from '../../hooks/computed';
import { expression } from '../../hooks/dependencies';

import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { MoreButtonChoice } from '../../aureliaComponents/more-button/more-button';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { FileDownloadService } from '../../services/FileDownloadService';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { Dialogs } from '../../classes/Dialogs';
import { SocketService } from '../../services/SocketService';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';

@autoinject()
export class DownloadPictureButton {
  @bindable public picture: Picture | null = null;

  private isOnline = true;

  protected moreButtonChoices: Array<MoreButtonChoice> = [
    {
      name: 'download-original-picture',
      labelTk: 'picture.downloadPictureButton.downloadOriginalPicture',
      disabledContext: this,
      disabledPropertyName: 'cannotDownloadPicture'
    },
    {
      name: 'download-picture-with-sketch',
      labelTk: 'picture.downloadPictureButton.downloadPictureWithSketch',
      disabledContext: this,
      disabledPropertyName: 'cannotDownloadPicture'
    },
    {
      name: 'download-marked-picture',
      labelTk: 'picture.downloadPictureButton.downloadMarkedPicture',
      disabledContext: this,
      disabledPropertyName: 'cannotDownloadPicture'
    }
  ];

  private readonly subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly fileDownloadService: FileDownloadService,
    private readonly socketService: SocketService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding(
        'isConnected',
        (isConnected) => (this.isOnline = isConnected)
      )
    );
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  @computed(expression('picture'), expression('isOnline'))
  protected get cannotDownloadPicture(): boolean {
    return !this.picture || !this.isOnline;
  }

  protected handleDownloadOriginalPictureClick(): void {
    assertNotNullOrUndefined(
      this.picture,
      'cannot download original picture without a picture'
    );

    const pictureFile =
      this.entityManager.pictureFileRepository.getOriginalPictureFileByPictureId(
        this.picture.id
      );
    assertNotNullOrUndefined(
      pictureFile,
      'picture has no original picture file'
    );

    const filePath = this.pictureFilePathService.getRelativeOnlinePicPath({
      pictureFile,
      fullSize: true,
      download: true
    });
    void this.fileDownloadService.downloadFile(filePath);
  }

  protected handleDownloadPictureWithSketchClick(): void {
    assertNotNullOrUndefined(
      this.picture,
      'cannot download picture with sketch without a picture'
    );

    const pictureFile =
      this.entityManager.pictureFileRepository.getPictureFileToDisplayByPictureId(
        this.picture.id
      );
    assertNotNullOrUndefined(pictureFile, 'picture has no edited picture file');

    const filePath = this.pictureFilePathService.getRelativeOnlinePicPath({
      pictureFile,
      fullSize: true,
      download: true
    });
    void this.fileDownloadService.downloadFile(filePath);
  }

  protected handleDownloadMarkedPictureClick(): void {
    if (!this.picture) return;

    Dialogs.waitDialog();
    this.socketService.exportMarkedPicture(
      { pictureId: this.picture.id },
      (response) => {
        if (response.success) {
          Dialogs.closeAllDialogs();
          void this.fileDownloadService.downloadFileByToken(response.token);
        } else {
          void Dialogs.errorDialogTk(
            'general.error',
            `serverResponses.exportMarkedPicture.${response.status}`
          );
        }
      }
    );
  }
}
