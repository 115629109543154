import { bindable, autoinject } from 'aurelia-framework';
import $ from 'jquery';
import { Vector } from 'common/Geometry/Vector';
import { AngleHelper } from 'common/Geometry/AngleHelper';
import { CanvasPointerEventNormalizer } from '../../drawingComponents/drawing-area/tools/CanvasPointerEventNormalizer';
import { DomEventHelper } from '../../classes/DomEventHelper';
import { ImageHelper } from '../../classes/ImageHelper';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { MouseDraggingDetector } from '../../classes/DomUtilities/MouseDraggingDetector';
import { TouchDraggingDetector } from '../../classes/DomUtilities/TouchDraggingDetector';
import { SvgLoader } from '../../classes/Svg/SvgLoader';
import { SvgRotator } from '../../classes/Svg/SvgRotator';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';

/**
 * @event editing-aborted will fire when the editing is aborted
 * @event editing-finished will fire when the editing is finished
 * @event stopped-editing will fire when the editing gets stopped (aborted or is finished), doesn't bubble
 */
@autoinject()
export class PictureEditor {
  @bindable()
  public picture: Picture | null = null;

  private domElement: HTMLElement;
  private zoom: number = 100;
  private maxZoom: number = 400;
  private minZoom: number = 100;
  private canvasOffsetX: number = 0;
  private canvasOffsetY: number = 0;
  private rotation: number = 0;
  private mouseDraggingDetector: MouseDraggingDetector | null = null;
  private touchDraggingDetector: TouchDraggingDetector | null = null;
  private lastDraggingPosition: Vector | null = null;
  private originalImage: HTMLImageElement | null = null;
  private errorTextTk: string | null = null;
  private isAttached: boolean = false;
  private croppingContainer: HTMLElement | null = null;
  private drawingCanvas: HTMLCanvasElement | null = null;
  private sliderWrapper: HTMLElement | null = null;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    private readonly pictureFilePathService: PictureFilePathService,
    element: Element
  ) {
    this.domElement = element as HTMLElement;
  }

  protected attached(): void {
    assertNotNullOrUndefined(
      this.sliderWrapper,
      'sliderWrapper is not available'
    );
    this.isAttached = true;

    // TODO: provide own slider implementation
    // @ts-ignore
    $(this.sliderWrapper).slider({
      min: this.minZoom,
      max: this.maxZoom,
      value: this.zoom,
      // @ts-ignore
      slide: (e, ui) => {
        this.zoom = ui.value;
        this.canvasOffsetX = this.limitCanvasOffset(this.canvasOffsetX, 'x');
        this.canvasOffsetY = this.limitCanvasOffset(this.canvasOffsetY, 'y');
      }
    });

    this.setupMouseDraggingDetector();
    this.setupTouchDraggingDetector();

    if (this.picture) {
      this.updateOriginalImage();
    }
  }

  protected detached(): void {
    this.isAttached = false;

    if (this.mouseDraggingDetector) {
      this.mouseDraggingDetector.destroy();
      this.mouseDraggingDetector = null;
    }

    if (this.touchDraggingDetector) {
      this.touchDraggingDetector.destroy();
      this.touchDraggingDetector = null;
    }
  }

  private pictureChanged(): void {
    if (this.isAttached) {
      this.updateOriginalImage();
    }
  }

  private setupMouseDraggingDetector(): void {
    assertNotNullOrUndefined(
      this.croppingContainer,
      "can't setupMouseDraggingDetector without a croppingContainer"
    );

    this.mouseDraggingDetector = new MouseDraggingDetector(
      this.croppingContainer
    );
    this.mouseDraggingDetector.onDragStart((event) => {
      this.lastDraggingPosition = Vector.createHtmlVector(
        event.clientX,
        event.clientY
      );
    });

    this.mouseDraggingDetector.onDrag((event) => {
      this.applyDragging(Vector.createHtmlVector(event.clientX, event.clientY));
    });
  }

  private setupTouchDraggingDetector(): void {
    assertNotNullOrUndefined(
      this.croppingContainer,
      "can't setupMouseDraggingDetector without a croppingContainer"
    );

    this.touchDraggingDetector = new TouchDraggingDetector(
      this.croppingContainer
    );
    this.touchDraggingDetector.onDragStart((event) => {
      const touch = event.touches[0];
      this.lastDraggingPosition = Vector.createHtmlVector(
        touch.clientX,
        touch.clientY
      );
    });

    this.touchDraggingDetector.onDrag((event) => {
      const touch = event.touches[0];
      this.applyDragging(Vector.createHtmlVector(touch.clientX, touch.clientY));
    });
  }

  private async updateOriginalImage(): Promise<void> {
    // since we can't abort pending requests, we have to check if the loaded image is really for our picture
    const usedPicture = this.picture;

    const pictureFile = usedPicture
      ? this.entityManager.pictureFileRepository.getOriginalPictureFileByPictureId(
          usedPicture.id
        )
      : null;
    const src = pictureFile
      ? await this.pictureFilePathService.getPictureFileSource(pictureFile)
      : null;

    try {
      this.clearCanvas();
      this.errorTextTk = null;

      if (!src) {
        throw new Error('no src found for original image');
      }

      const image = await ImageHelper.loadImage(src);
      if (this.picture === usedPicture) {
        this.originalImage = image;
        this.updateCanvas(this.originalImage);
      }
    } catch (e) {
      console.error(e);
      if (this.picture === usedPicture) {
        this.errorTextTk = 'aureliaComponents.pictureEditor.loadError';
      }
    }
  }

  /**
   * @param direction - pass a 1 to turn clockwise, or -1 to turn counterclockwise
   */
  private handleRotateClick(direction: -1 | 1): void {
    assertNotNullOrUndefined(
      this.originalImage,
      "can't handleRotateClick without an originalImage"
    );
    this.resetOffsetAndZoom();

    const rotation = (this.rotation + (direction >= 0 ? 90 : -90)) % 360;
    this.rotation = AngleHelper.simplifyDegAngle(rotation);

    this.updateCanvas(this.originalImage);
  }

  private handleAbortClick(): void {
    DomEventHelper.fireEvent(this.domElement, {
      name: 'editing-aborted',
      detail: null
    });
    this.stopEditing();
  }

  private async handleFinishClick(): Promise<void> {
    try {
      await this.saveImages();
      DomEventHelper.fireEvent(this.domElement, {
        name: 'editing-finished',
        detail: null
      });
      this.stopEditing();
    } catch (e) {
      console.error(e);
      this.errorTextTk = 'aureliaComponents.pictureEditor.saveError';
    }
  }

  private handleResetCanvasPositionClick(): void {
    this.resetOffsetAndZoom();
    this.resetRotation();
  }

  private handleCanvasWheel(event: WheelEvent): boolean {
    const scrollDirection =
      (event.deltaX ? event.deltaX : 0) +
      (event.deltaY ? event.deltaY : 0) +
      (event.deltaZ ? event.deltaZ : 0); // we don't care about the axis, so we use all of them

    const offset = scrollDirection < 0 ? 20 : -20;
    const zoom = Math.max(
      Math.min(this.zoom + offset, this.maxZoom),
      this.minZoom
    );
    this.setZoom(zoom);

    return false; // preventDefault of event
  }

  private stopEditing(): void {
    this.resetOffsetAndZoom();
    this.resetRotation();
    this.originalImage = null;

    DomEventHelper.fireEvent(this.domElement, {
      name: 'stopped-editing',
      detail: null
    });
  }

  private async loadOriginalImage(picture: Picture): Promise<HTMLImageElement> {
    const pictureFile =
      this.entityManager.pictureFileRepository.getOriginalPictureFileByPictureId(
        picture.id
      );
    const src = pictureFile
      ? await this.pictureFilePathService.getPictureFileSource(pictureFile)
      : null;

    if (src) {
      return ImageHelper.loadImage(src);
    } else {
      throw new Error(`no src for an original picture found "${picture.id}"`);
    }
  }

  private async saveImages(): Promise<void> {
    // cache the variables because we have a lot of async stuff and it could be null in the meantime
    const picture = this.picture;
    const originalImage = this.originalImage;
    assertNotNullOrUndefined(
      picture,
      "can't handleFinishClick without a picture"
    );
    assertNotNullOrUndefined(
      originalImage,
      "can't handleFinishClick without an originalImage"
    );

    const loadSketchResult = await this.loadSketchImage(picture);

    if (this.rotation) {
      this.savePictureFileDataUrlService.saveOriginalPictureDataUrl(
        picture,
        this.rotateImage(originalImage, 'image/jpeg')
      );

      if (loadSketchResult) {
        await this.saveRotatedSketch(
          loadSketchResult.pictureFile,
          picture,
          loadSketchResult.image
        );
      }
    }

    const res = this.renderCroppedImage(loadSketchResult?.image ?? null);
    this.savePictureFileDataUrlService.saveEditedPictureDataUrl(
      picture,
      res.editedDataUrl
    );
    this.savePictureFileDataUrlService.saveCroppedPictureDataUrl(
      picture,
      res.croppedDataUrl
    );
  }

  private async loadSketchImage(
    picture: Picture
  ): Promise<{ image: HTMLImageElement; pictureFile: PictureFile } | null> {
    const pictureFile =
      this.entityManager.pictureFileRepository.getSketchPictureFileByPictureId(
        picture.id
      );
    if (!pictureFile) {
      return null;
    }

    const src =
      await this.pictureFilePathService.getPictureFileSource(pictureFile);

    if (src) {
      return {
        image: await ImageHelper.loadImage(src),
        pictureFile: pictureFile
      };
    } else {
      throw new Error('no sketch src found');
    }
  }

  private async saveRotatedSketch(
    sketchPictureFile: PictureFile,
    picture: Picture,
    sketchImage: HTMLImageElement
  ): Promise<void> {
    if (sketchPictureFile.file_extension === 'svg') {
      await this.generateRotatedSketch(sketchImage.src);
      this.savePictureFileDataUrlService.saveSketchPictureDataUrl(
        picture,
        await this.generateRotatedSketch(sketchImage.src)
      );
    } else {
      this.savePictureFileDataUrlService.saveSketchPictureDataUrl(
        picture,
        this.rotateImage(sketchImage, 'image/png')
      );
    }
  }

  private async generateRotatedSketch(src: string): Promise<string> {
    const loader = new SvgLoader();
    const svg = await loader.load(src);

    const rotator = new SvgRotator(svg, this.rotation);
    rotator.rotate();

    return ImageHelper.svgElementToDataUrl(svg);
  }

  /**
   * canvas has to already have the size of the _img
   *
   * also rotates the canvas if needed
   *
   * if an overlayImage is given, the merged image is available in editedDataUrl
   * else editedDataUrl is the same as the croppedDataUrl
   */
  private renderCroppedImage(overlayImage: HTMLImageElement | null): {
    croppedDataUrl: string;
    editedDataUrl: string;
  } {
    const originalImage = this.originalImage;
    assertNotNullOrUndefined(
      this.drawingCanvas,
      "can't renderCroppedImage without a drawingCanvas"
    );
    assertNotNullOrUndefined(
      originalImage,
      "can't renderCroppedImage without an originalImage"
    );

    const zoomFactor = this.zoom / 100;
    const topLeftPoint =
      CanvasPointerEventNormalizer.normalizeOffsetCoordinates(
        {
          x: this.getMaxOffsetForCanvas('x') - this.canvasOffsetX,
          y: this.getMaxOffsetForCanvas('y') - this.canvasOffsetY
        },
        this.drawingCanvas
      );

    const canvas = document.createElement('canvas');
    canvas.width = this.drawingCanvas.width;
    canvas.height = this.drawingCanvas.height;

    const imgWidth = this.drawingCanvas.width / zoomFactor;
    const imgHeight = this.drawingCanvas.height / zoomFactor;

    const ctx = canvas.getContext('2d');
    assertNotNullOrUndefined(ctx, "canvas didn't generate a drawingContext");

    ctx.drawImage(
      this.drawingCanvas,
      topLeftPoint.x, // src image rect x start
      topLeftPoint.y, // src image rect y start
      imgWidth, // src image rect width
      imgHeight, // src image rect height
      0, // destination x start
      0, // destination y start
      this.drawingCanvas.width, // destination width
      this.drawingCanvas.height
    ); // destination height

    const dataUrl = canvas.toDataURL('image/jpeg');
    const returnValue = {
      croppedDataUrl: dataUrl,
      editedDataUrl: dataUrl
    };

    if (overlayImage) {
      this.renderOverlayImage(overlayImage, originalImage, canvas);
      returnValue.editedDataUrl = canvas.toDataURL('image/jpeg');
    }

    return returnValue;
  }

  private renderOverlayImage(
    overlayImage: HTMLImageElement,
    originalImage: HTMLImageElement,
    canvas: HTMLCanvasElement
  ): void {
    ImageHelper.drawInRotatedCanvas(canvas, this.rotation, (rotatedCtx) => {
      // since it has 0 rotation in here, we have to use the _img dimensions instead of the canvas ones
      rotatedCtx.drawImage(
        overlayImage,
        -(originalImage.naturalWidth / 2), // destination x start
        -(originalImage.naturalHeight / 2), // destination y start
        originalImage.naturalWidth, // destination width
        originalImage.naturalHeight
      ); // destination height
    });
  }

  /**
   * @param img
   * @param type
   * @returns - a png base64 of the rotated image
   * @private
   */
  private rotateImage(
    img: HTMLImageElement,
    type: 'image/jpeg' | 'image/png'
  ): string {
    const canvas = document.createElement('canvas');
    if (this.rotation === 0 || this.rotation === 180) {
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
    } else {
      canvas.width = img.naturalHeight;
      canvas.height = img.naturalWidth;
    }

    ImageHelper.drawInRotatedCanvas(canvas, this.rotation, (ctx) => {
      ctx.drawImage(
        img,
        -(img.naturalWidth / 2), // destination x start
        -(img.naturalHeight / 2) // destination y start
      );
    });

    return canvas.toDataURL(type);
  }

  private calculateImageContainerPaddingBottom(
    width: number,
    height: number,
    rotation: number
  ): string {
    if (rotation === 0 || rotation === 180) {
      return (height / width) * 100 + '%';
    } else {
      return (width / height) * 100 + '%';
    }
  }

  private calculateCanvasStyling(
    zoom: number,
    canvasOffsetX: number,
    canvasOffsetY: number
  ): Record<string, any> {
    const z = zoom / 100;
    return {
      transform: `scale(${z}, ${z}) translate(${canvasOffsetX}px, ${canvasOffsetY}px)`
    };
  }

  private clearCanvas(): void {
    assertNotNullOrUndefined(
      this.drawingCanvas,
      "can't updateCanvas without a drawingCanvas"
    );
    const ctx = this.drawingCanvas.getContext('2d');
    assertNotNullOrUndefined(
      ctx,
      "canvas didn't generate a context in clearCanvas"
    );
    ctx.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);
    this.drawingCanvas.height = 0;
    this.drawingCanvas.width = 0;
  }

  private updateCanvas(img: HTMLImageElement): void {
    assertNotNullOrUndefined(
      this.drawingCanvas,
      "can't updateCanvas without a drawingCanvas"
    );

    if (this.rotation === 0 || this.rotation === 180) {
      this.drawingCanvas.height = img.naturalHeight;
      this.drawingCanvas.width = img.naturalWidth;
    } else {
      this.drawingCanvas.height = img.naturalWidth;
      this.drawingCanvas.width = img.naturalHeight;
    }

    ImageHelper.drawInRotatedCanvas(
      this.drawingCanvas,
      this.rotation,
      (ctx) => {
        ctx.drawImage(
          img,
          -(img.naturalWidth / 2),
          -(img.naturalHeight / 2),
          img.naturalWidth,
          img.naturalHeight
        );
      }
    );
  }

  /**
   * applies the dragging and updates the canvas offset + the lastDraggingPosition
   */
  private applyDragging(newDraggingPosition: Vector): void {
    assertNotNullOrUndefined(
      this.lastDraggingPosition,
      "can't applyDragging without a lastDraggingPosition"
    );

    const diff = newDraggingPosition
      .clone()
      .substractVector(this.lastDraggingPosition)
      .scale(100 / this.zoom);

    this.canvasOffsetX = this.limitCanvasOffset(
      this.canvasOffsetX + diff.getX(),
      'x'
    );
    this.canvasOffsetY = this.limitCanvasOffset(
      this.canvasOffsetY + diff.getY(),
      'y'
    );

    this.lastDraggingPosition = newDraggingPosition;
  }

  private resetOffsetAndZoom(): void {
    this.setZoom(this.minZoom);
    this.canvasOffsetX = 0;
    this.canvasOffsetY = 0;
  }

  private resetRotation(): void {
    this.rotation = 0;
    if (this.originalImage) {
      this.updateCanvas(this.originalImage);
    }
  }

  private setZoom(zoom: number): void {
    assertNotNullOrUndefined(
      this.sliderWrapper,
      "can't setZoom without a sliderWrapper"
    );

    this.zoom = zoom;
    // @ts-ignore
    $(this.sliderWrapper).slider('value', zoom);
    this.canvasOffsetX = this.limitCanvasOffset(this.canvasOffsetX, 'x');
    this.canvasOffsetY = this.limitCanvasOffset(this.canvasOffsetY, 'y');
  }

  private limitCanvasOffset(value: number, dimension: 'x' | 'y'): number {
    const maxOffset = this.getMaxOffsetForCanvas(dimension);
    return Math.max(Math.min(value, maxOffset), -1 * maxOffset);
  }

  private getMaxOffsetForCanvas(dimension: 'x' | 'y'): number {
    assertNotNullOrUndefined(
      this.drawingCanvas,
      "can't getMaxOffsetForCanvas without a drawingCanvas"
    );

    const computed = window.getComputedStyle(this.drawingCanvas);
    // since the scaling point is in the center of the element, we need to use half of the size
    const halfSize =
      parseFloat(dimension === 'x' ? computed.width : computed.height) / 2;
    const zoomFactor = this.zoom / 100; // convert it to a percentual number

    // halfSize / zoomFactor = the width of the canvas/window
    return halfSize - halfSize / zoomFactor;
  }
}
