import { EntityUtils } from '@record-it-npm/synchro-client';

import { GalleryThingPictureFilter } from 'common/Types/GalleryThingPictureFilter/GalleryThingPictureFilter';
import { PaginationInfo } from 'common/EndpointTypes/GalleryThingPictureOverviewEndpointsHandler';

import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import {
  Disposable,
  DisposableContainer
} from '../../../classes/Utils/DisposableContainer';
import { GalleryThingPictureFilterService } from '../../../services/GalleryThingPictureFilterService/GalleryThingPictureFilterService';
import { SubscriptionManagerService } from '../../../services/SubscriptionManagerService';
import {
  GalleryThingPictureDataSourceStrategy,
  GetGalleryThingPicturesOptions
} from './strategies/GalleryThingPictureDataSourceStrategy';
import { GalleryThingPicturesFromEntityManagerStrategy } from './strategies/GalleryThingPicturesFromEntityManagerStrategy';
import { GalleryThingPicturesFromServerStrategy } from './strategies/GalleryThingPicturesFromServerStrategy';
import { IUtilsRateLimitedFunction, Utils } from '../../../classes/Utils/Utils';
import {
  GalleryThingPictureOverviewEntryGroup,
  OnBaseMapMarkerClicked
} from '../../../classes/GalleryThing/GalleryThingPictureOverviewEntryHelper';
import { ActiveUserCompanySettingService } from '../../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { ComputedValueService } from '../../../computedValues/ComputedValueService';
import { PictureFilePathService } from '../../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { SingleSocketRequestService } from '../../../services/SingleSocketRequestService/SingleSocketRequestService';
import { SocketService } from '../../../services/SocketService';
import { GalleryThingPictureCreatorService } from '../../../services/GalleryThingPictureCreatorService';
import { InstancePreserver } from '../../../classes/InstancePreserver/InstancePreserver';
import { PictureFileUploadService } from '../../../classes/EntityManager/entities/PictureFile/PictureFileUploadService';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

export class GalleryThingPictureDataSource implements Disposable {
  private readonly disposableContainer = new DisposableContainer();

  private readonly entityManagerStrategy: GalleryThingPicturesFromEntityManagerStrategy;
  private readonly serverStrategy: GalleryThingPicturesFromServerStrategy;
  private activeStrategy: GalleryThingPictureDataSourceStrategy;

  private availablePictureGroups: Array<GalleryThingPictureOverviewEntryGroup> | null =
    null;

  private updateRateLimited: IUtilsRateLimitedFunction;

  constructor(injectables: {
    entityManager: AppEntityManager;
    subscriptionManagerService: SubscriptionManagerService;
    galleryThingPictureFilterService: GalleryThingPictureFilterService;
    activeUserCompanySettingService: ActiveUserCompanySettingService;
    computedValueService: ComputedValueService;
    pictureFilePathService: PictureFilePathService;
    pictureFileUploadService: PictureFileUploadService;
    singleSocketRequestService: SingleSocketRequestService;
    socketService: SocketService;
    galleryThingPictureCreatorService: GalleryThingPictureCreatorService;
    baseMapDataCallbacks: BaseMapDataCallbacks;
    updatePaginationMaxIndex: (totalCount: number) => void;
    permissionsService: PermissionsService;
  }) {
    this.entityManagerStrategy =
      new GalleryThingPicturesFromEntityManagerStrategy(injectables);
    this.serverStrategy = new GalleryThingPicturesFromServerStrategy(
      injectables
    );

    this.activeStrategy = this.entityManagerStrategy;
    this.setupSubscriptions();

    this.updateRateLimited = Utils.rateLimitFunction(
      this.update.bind(this),
      500
    );
  }

  public dispose(): void {
    this.disposableContainer.disposeAll();
  }

  public updateGetGalleryThingPicturesOptions(
    galleryThingPicturesOptions: GetGalleryThingPicturesOptions
  ): void {
    this.activeStrategy.setGalleryThingPicturesOptions(
      galleryThingPicturesOptions
    );
    this.updateRateLimited();
  }

  public setFilter(
    filter: GalleryThingPictureFilter,
    paginationInfo: PaginationInfo
  ): void {
    this.activeStrategy.setFilter(filter, paginationInfo);
  }

  public async update(): Promise<void> {
    await this.activeStrategy.updatePictures();
  }

  public bindFilteredPictureGroups(
    callback: (
      filteredPictureGroups: Array<GalleryThingPictureOverviewEntryGroup>
    ) => void
  ): [Disposable, ...Array<Disposable>] {
    return [
      this.entityManagerStrategy.bindFilteredPictureGroups((newGroups) => {
        this.handleNewGroups(newGroups, callback);
      }),
      this.serverStrategy.bindFilteredPictureGroups((newGroups) => {
        this.handleNewGroups(newGroups, callback);
      })
    ];
  }

  public async useServerStrategy(): Promise<void> {
    await this.updateStrategy(this.serverStrategy);
  }

  public async useEntityManagerStrategy(): Promise<void> {
    await this.updateStrategy(this.entityManagerStrategy);
  }

  private handleNewGroups(
    newGroups: Array<GalleryThingPictureOverviewEntryGroup>,
    callback: (
      filteredPictureGroups: Array<GalleryThingPictureOverviewEntryGroup>
    ) => void
  ): void {
    this.availablePictureGroups = this.preserveInstances(newGroups);
    callback(this.availablePictureGroups);
  }

  private preserveInstances(
    newPictureGroups: Array<GalleryThingPictureOverviewEntryGroup>
  ): Array<GalleryThingPictureOverviewEntryGroup> {
    if (!this.availablePictureGroups) return newPictureGroups;
    return InstancePreserver.createNewArray({
      originalArray: this.availablePictureGroups,
      newArray: newPictureGroups,
      getTrackingValue: (item) => EntityUtils.getTrackingKey(item.project),
      valueMerger: {
        pictureOverviewEntries: ({
          oldValue: oldEntries,
          newValue: newEntries
        }) => {
          return InstancePreserver.createNewArray({
            originalArray: oldEntries,
            newArray: newEntries,
            getTrackingValue: (item) => item.pictureId,
            valueMerger: {
              icons: ({ oldValue: oldIcons, newValue: newIcons }) => {
                return InstancePreserver.createNewArray({
                  originalArray: oldIcons || [],
                  newArray: newIcons || [],
                  getTrackingValue: (item) =>
                    item.iconName + item.color + item.iconType
                });
              }
            }
          });
        }
      }
    });
  }

  private setupSubscriptions(): void {
    this.disposableContainer.add(
      this.activeStrategy.subscribe({
        onDataChanged: () => {
          void this.update();
        }
      })
    );
  }

  private async updateStrategy(
    strategy: GalleryThingPictureDataSourceStrategy
  ): Promise<void> {
    this.activeStrategy = strategy;
    this.dispose();
    this.setupSubscriptions();
    await this.update();
  }
}

export type GalleryThingThumbnailPath = string | null;
export type BaseMapDataCallbacks = {
  onMarkersChanged: () => void;
  onMarkerClicked: OnBaseMapMarkerClicked;
};
