import { autoinject, bindable } from 'aurelia-framework';

import { Dialogs } from '../../classes/Dialogs';
import { EditMapLayerDialog } from '../../dialogs/edit-map-layer-dialog/edit-map-layer-dialog';
import { DateUtils } from '../../../../common/src/DateUtils';
import { EntityListItemHelper } from '../../classes/EntityListItemHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { MapLayer } from '../../classes/EntityManager/entities/MapLayer/types';

@autoinject()
export class MapLayerListItem {
  @bindable public mapLayer: MapLayer | null = null;

  @bindable public editable = false;

  private listItemElement: HTMLElement | null = null;

  private panelOpen = false;

  private DateUtils: DateUtils = DateUtils;

  constructor(private readonly entityManager: AppEntityManager) {}

  public highlight(): void {
    if (this.listItemElement)
      EntityListItemHelper.highlightListItemElement(this.listItemElement);
  }

  private handleMoreButtonClicked(): void {
    this.panelOpen = !this.panelOpen;
  }

  private handleEditMapLayerClicked(): void {
    if (!this.mapLayer) return;

    EditMapLayerDialog.open({
      mapLayer: this.mapLayer
    });
  }

  private handleDeleteMapLayerClicked(): void {
    const mapLayer = this.mapLayer;
    if (!mapLayer) return;

    Dialogs.deleteEntityDialog(mapLayer).then(() => {
      this.entityManager.mapLayerRepository.delete(mapLayer);
    });
  }
}
