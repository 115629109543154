import { computedFrom } from 'aurelia-binding';
import { AppEntityManagerEntityTypesByEntityName } from '../../../classes/EntityManager/entities/AppEntityManagerEntityTypesByEntityName';
import { Disposable } from '../../../classes/Utils/DisposableContainer';
import {
  EntityNameToAdapter,
  EntityNameToAdapterContainer,
  SupportedEntityName
} from '../entityNameToPermissionsConfig';

/**
 * This class is for usecases, where you have multiple entities and you want to check if those entities have certain permissions.
 *
 * A use case for this would be a multiselect with actions/buttons:
 * There are 3 entities: two can be deleted by the current user, but one can not.
 * If the user only selects entities which can be deleted, then the delete button should be enabled.
 * But as soon as an entity which can't be deleted is selected, the button should be disabled.
 * The getter for the buttons disabled attribute should look like this:
 *
 * @computedFrom('selectedEntities', 'entitiesPermissionChecker.revision')
 * protected get deletedButtonIsDisabled(): boolean {
 *   return this.entitiesPermissionChecker.everyEntityHasPermission({
 *     entities: this.selectedEntities,
 *     permissionName: 'canDeleteEntity'
 *   })
 * }
 */
export class EntitiesPermissionChecker<
  TEntityName extends SupportedEntityName
> {
  private readonly adapterContainer: EntityNameToAdapterContainer[TEntityName];

  private adapter: EntityNameToAdapter[TEntityName] | null = null;
  private adapterRevision: number | null = null;

  constructor({
    adapterContainer
  }: {
    adapterContainer: EntityNameToAdapterContainer[TEntityName];
  }) {
    this.adapterContainer = adapterContainer;
  }

  public subscribe(): Disposable {
    const adapterDisposable = this.adapterContainer.bindAdapter(
      ({ adapter, revision }) => {
        this.adapter = adapter;
        this.adapterRevision = revision;
      }
    );

    return {
      dispose: () => {
        adapterDisposable.dispose();
        this.adapter = null;
        this.adapterRevision = null;
      }
    };
  }

  /**
   * If the permissionChecker is used in a getter with @computedFrom etc., then add this field to the dependencies.
   */
  @computedFrom('adapterRevision')
  public get revision(): string {
    return `${this.adapterRevision}`;
  }

  public allEntitiesHavePermission({
    entities,
    checkPermission
  }: {
    entities: Array<
      AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
    >;
    checkPermission: (options: {
      adapter: EntityNameToAdapter[TEntityName];
      entity: AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'];
    }) => boolean;
  }): boolean {
    const adapter = this.adapter;
    if (!adapter) {
      return false;
    }

    return entities.every((entity) => {
      return checkPermission({
        adapter,
        entity
      });
    });
  }

  public filterEntitiesByPermission({
    entities,
    checkPermission
  }: {
    entities: Array<
      AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']
    >;
    checkPermission: (options: {
      adapter: EntityNameToAdapter[TEntityName];
      entity: AppEntityManagerEntityTypesByEntityName[TEntityName]['entity'];
    }) => boolean;
  }): Array<AppEntityManagerEntityTypesByEntityName[TEntityName]['entity']> {
    const adapter = this.adapter;
    if (!adapter) {
      return [];
    }

    return entities.filter((entity) => {
      return checkPermission({
        adapter,
        entity
      });
    });
  }

  public useAdapter(
    worker: (options: { adapter: EntityNameToAdapter[TEntityName] }) => boolean
  ): boolean {
    if (!this.adapter) {
      return false;
    }

    return worker({
      adapter: this.adapter
    });
  }
}
