import { autoinject, observable } from 'aurelia-framework';

import { DeviceInfoHelper } from '../../classes/DeviceInfoHelper';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { FilterHelper } from '../../classes/FilterHelper';
import { SorterSortOption } from '../../aureliaAttributes/sorter';
import { EditMapLayerDialog } from '../../dialogs/edit-map-layer-dialog/edit-map-layer-dialog';
import { ScrollHelper } from '../../classes/ScrollHelper';
import { PermissionHelper } from '../../classes/PermissionHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { MapLayer } from '../../classes/EntityManager/entities/MapLayer/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { UserGroup } from '../../classes/EntityManager/entities/UserGroup/types';
import { User } from '../../classes/EntityManager/entities/User/types';
import { CurrentUserService } from '../../classes/EntityManager/entities/User/CurrentUserService';

@autoinject()
export class EditMapLayers {
  private subscriptionManager: SubscriptionManager;

  private currentUser: User | null = null;

  private editableUserGroups: Array<UserGroup> = [];

  private availableMapLayers: Array<MapLayer> = [];

  @observable private mapLayerFilterString: string;

  private filteredMapLayers: Array<MapLayer> = [];

  private sortOptions: {
    name: SorterSortOption<MapLayer>;
    updatedAt: SorterSortOption<MapLayer>;
  } = {
    name: {
      name: 'name',
      sortFunction: (a, b) => {
        const aName = a.name ? a.name : 'Kartenebene';
        const bName = b.name ? b.name : 'Kartenebene';
        return aName.localeCompare(bName);
      }
    },
    updatedAt: {
      name: 'updatedAt',
      sortFunction: (a, b) => {
        const aUpdatedAt = a.updatedAt || a.createdAt || '';
        const bUpdatedAt = b.updatedAt || b.createdAt || '';
        return aUpdatedAt.localeCompare(bUpdatedAt);
      }
    }
  };

  private currentSortOption = this.sortOptions.name;

  private sortedMapLayers = [];

  private isMobile = false;

  protected readonly EntityName = EntityName;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly currentUserService: CurrentUserService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();

    this.mapLayerFilterString = '';
  }

  protected attached(): void {
    this.subscriptionManager.addDisposable(
      DeviceInfoHelper.registerBinding('isMobile', (isMobile) => {
        this.isMobile = isMobile;
      })
    );

    this.subscriptionManager.addDisposable(
      this.currentUserService.subscribeToCurrentUserChanged(
        this.updateCurrentUser.bind(this)
      )
    );
    this.updateCurrentUser();

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.UserGroup,
      this.updateEditableUserGroups.bind(this)
    );

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.MapLayer,
      this.updateAvailableMapLayers.bind(this)
    );
    this.updateAvailableMapLayers();
  }

  protected detached(): void {
    this.subscriptionManager.disposeSubscriptions();
  }

  private mapLayerFilterStringChanged(): void {
    this.updateFilteredMapLayers();
  }

  private updateCurrentUser(): void {
    this.currentUser = this.currentUserService.getCurrentUser();
    this.updateEditableUserGroups();
  }

  private updateEditableUserGroups(): void {
    if (this.currentUser) {
      this.editableUserGroups =
        this.entityManager.userGroupRepository.getEditableGroupsForUser(
          this.currentUser
        );
    } else {
      this.editableUserGroups = [];
    }
  }

  private updateAvailableMapLayers(): void {
    this.availableMapLayers = this.entityManager.mapLayerRepository.getAll();
    this.updateFilteredMapLayers();
  }

  private updateFilteredMapLayers(): void {
    this.filteredMapLayers = FilterHelper.filterItems(
      this.availableMapLayers,
      (mapLayer) => mapLayer.name ?? '',
      this.mapLayerFilterString
    );
  }

  private handleCreateMapLayerClicked(userGroupId: string): void {
    const mapLayer = this.entityManager.mapLayerRepository.create({
      ownerUserGroupId: userGroupId
    });

    this.editMapLayer(mapLayer);
  }

  private editMapLayer(mapLayer: MapLayer): void {
    void EditMapLayerDialog.open({
      mapLayer: mapLayer,
      onDialogClosed: () => {
        this.scrollToMapLayer(mapLayer);
      }
    });
  }

  private scrollToMapLayer(mapLayer: MapLayer): void {
    void ScrollHelper.autoScrollToListItem(
      `#edit-map-layers--map-layer-${mapLayer.id}`
    );
  }

  private mapLayerIsEditable(
    mapLayer: MapLayer,
    user: User,
    editableUserGroups: Array<UserGroup>
  ): boolean {
    return PermissionHelper.userCanEditOwnerUserGroupIdEntity(
      mapLayer,
      user,
      editableUserGroups
    );
  }
}
