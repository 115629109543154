import { autoinject, computedFrom, observable } from 'aurelia-framework';
import {
  PermissionBindingService,
  PermissionBindingHandle
} from '../../services/PermissionBindingService';
import { DateUtils } from '../../../../common/src/DateUtils';
import { Router } from 'aurelia-router';
import {
  ProcessTaskAppointmentCalendarWidgetMode,
  processTaskAppointmentCalendarWidgetModesForFieldUse
} from 'common/Enums/ProcessTaskAppointmentCalendarWidgetMode';
import { User } from '../../classes/EntityManager/entities/User/types';
import { SubscriptionManagerService } from '../../services/SubscriptionManagerService';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { ActiveUserCompanySettingService } from '../../classes/EntityManager/entities/UserCompanySetting/ActiveUserCompanySettingService';
import { NFCHelper } from '../../classes/Nfc/NFCHelper';
import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskLoggingService } from '../../services/ProcessTaskLoggingService';
import { Dialogs } from '../../classes/Dialogs';
import { CalendarEntryDataSource } from '../../operationsComponents/process-task-appointment-calendar-widget/CalendarEntryDataSource/CalendarEntryDataSource';
import { CalendarEntriesFromEntityManagerStrategy } from '../../operationsComponents/process-task-appointment-calendar-widget/CalendarEntryDataSource/strategies/CalendarEntriesFromEntityManagerStrategy';
import { ComputedValueService } from '../../computedValues/ComputedValueService';
import {
  CalendarEntryClickedEvent,
  ProcessTaskAppointmentCalendarWidgetConfig
} from '../../operationsComponents/process-task-appointment-calendar-widget/process-task-appointment-calendar-widget';
import { CreateAppointmentWorkerDialog } from '../../dialogs/create-appointment-worker-dialog/create-appointment-worker-dialog';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SocketService } from '../../services/SocketService';
import { CalendarEntryType } from '../../operationsComponents/process-task-appointment-calendar-widget/CalendarEntryDataSource/CalendarEntry';
import { TemporaryAppointmentService } from '../../services/TemporaryAppointmentService';
import { computed } from '../../hooks/computed';
import { activeUserCompanySetting, expression } from '../../hooks/dependencies';
import { ValueWithLabel } from '../../types/ValueWithLabel';
import { SelectChangedEvent } from '../../inputComponents/custom-select/custom-select';
import {
  OperationsFieldUseCalendarSettings,
  OperationsFieldUseCalendarSettingsService
} from '../../services/OperationsFieldUseCalendarSettingsService/OperationsFieldUseCalendarSettingsService';

@autoinject()
export class ShowUserCalendar {
  @observable private currentUser: User | null;

  private router: Router;
  private permissionBindingHandle: PermissionBindingHandle;
  private subscriptionManager: SubscriptionManager;

  protected readonly dataSource: CalendarEntryDataSource;

  protected userFilter: Array<User> = [];

  private appointmentOpenWithNfcTag = false;

  protected navBarDates: Array<Date> = [];

  protected isOnline: boolean = false;

  protected DateUtils = DateUtils;

  private calendarSettings: OperationsFieldUseCalendarSettings;

  constructor(
    router: Router,
    permissionBindingService: PermissionBindingService,
    subscriptionManagerService: SubscriptionManagerService,
    private readonly activeUserCompanySettingService: ActiveUserCompanySettingService,
    private readonly entityManager: AppEntityManager,
    private readonly processTaskLoggingService: ProcessTaskLoggingService,
    private readonly temporaryAppointmentService: TemporaryAppointmentService,
    computedValueService: ComputedValueService,
    private readonly socketService: SocketService,
    private readonly operationsFieldUseCalendarSettingsService: OperationsFieldUseCalendarSettingsService
  ) {
    this.router = router;

    this.permissionBindingHandle = permissionBindingService.create({
      context: this,
      currentUserPropertyName: 'currentUser'
    });

    this.subscriptionManager = subscriptionManagerService.create();
    this.currentUser = null;

    this.dataSource = new CalendarEntryDataSource(
      new CalendarEntriesFromEntityManagerStrategy({
        entityManager,
        computedValueService,
        subscriptionManagerService
      })
    );
    this.calendarSettings =
      this.operationsFieldUseCalendarSettingsService.getSettings();
  }

  protected attached(): void {
    this.permissionBindingHandle.subscribe();
    this.subscriptionManager.addDisposable(
      this.activeUserCompanySettingService.bindSettingProperty(
        'operations.appointmentOpenWithNfcTag',
        (appointmentOpenWithNfcTag) => {
          this.appointmentOpenWithNfcTag = appointmentOpenWithNfcTag;
        }
      )
    );
    this.subscriptionManager.addDisposable(
      this.socketService.registerBinding('isConnected', (isConnected) => {
        this.isOnline = isConnected;
      })
    );
    this.subscriptionManager.addDisposable(
      this.operationsFieldUseCalendarSettingsService.bindSettings(
        (settings) => {
          this.calendarSettings = settings;
          this.selectWeekForDay(this.calendarSettings.selectedDate);
        }
      )
    );
  }

  protected detached(): void {
    this.permissionBindingHandle.unsubscribe();
    this.subscriptionManager.disposeSubscriptions();
  }

  protected currentUserChanged(): void {
    this.userFilter = this.currentUser ? [this.currentUser] : [];
  }

  private selectWeekForDay(day: Date): void {
    let currentDay = DateUtils.getStartDateOfWeek(day);
    this.navBarDates = [currentDay];
    for (let i = 1; i < 7; i++) {
      currentDay = DateUtils.getNextDay(currentDay);
      this.navBarDates.push(currentDay);
    }
  }

  protected handleDaySelected(day: Date): void {
    this.operationsFieldUseCalendarSettingsService.modifySettings({
      selectedDate: day
    });
  }

  protected handleJumpToTodayClick(): void {
    const newDate = new Date();
    this.operationsFieldUseCalendarSettingsService.modifySettings({
      selectedDate: newDate
    });
  }

  protected handleCreateAppointmentClick(): void {
    assertNotNullOrUndefined(
      this.currentUser,
      'cannot handleCreateAppointmentClick without currentUser'
    );
    void CreateAppointmentWorkerDialog.open();
  }

  protected handlePrevWeekClick(): void {
    const newDate = DateUtils.getDateOneWeekBefore(
      this.calendarSettings.selectedDate
    );
    this.operationsFieldUseCalendarSettingsService.modifySettings({
      selectedDate: newDate
    });
  }

  protected handleNextWeekClick(): void {
    const newDate = DateUtils.getDateOneWeekAfter(
      this.calendarSettings.selectedDate
    );
    this.operationsFieldUseCalendarSettingsService.modifySettings({
      selectedDate: newDate
    });
  }

  protected handlePrevDayClick(): void {
    const newDate = DateUtils.getDateWithDayOffset(
      this.calendarSettings.selectedDate,
      -1
    );
    this.operationsFieldUseCalendarSettingsService.modifySettings({
      selectedDate: newDate
    });
  }

  protected handleNextDayClick(): void {
    const newDate = DateUtils.getDateWithDayOffset(
      this.calendarSettings.selectedDate,
      1
    );
    this.operationsFieldUseCalendarSettingsService.modifySettings({
      selectedDate: newDate
    });
  }

  protected handleCalendarEntryClicked(event: CalendarEntryClickedEvent): void {
    switch (event.detail.entry.type) {
      case CalendarEntryType.NORMAL:
        void this.openAppointment(event.detail.entry.id, false);
        break;
      case CalendarEntryType.RECURRING:
        const appointment = this.temporaryAppointmentService.createEntity(
          event.detail.entry
        );
        void this.openAppointment(appointment.id, true);
        break;
      default:
        throw new Error(
          `Type ${(event.detail as any).type} of CalendarEntry is not handled.`
        );
    }
  }

  private async openAppointment(
    appointmentId: string,
    isTemporaryEntity: boolean = false
  ): Promise<void> {
    if (this.appointmentOpenWithNfcTag) {
      void this.tryToOpenAppointmentWithNfcTag(
        appointmentId,
        isTemporaryEntity
      );
    } else {
      this.router.navigateToRoute('show_process_appointment', {
        appointment_id: appointmentId
      });
    }
  }

  private async tryToOpenAppointmentWithNfcTag(
    entryId: string,
    isTemporaryEntity: boolean = false
  ): Promise<void> {
    const processTaskAppointment =
      this.entityManager.processTaskAppointmentRepository.getById(entryId);
    if (!processTaskAppointment) return;

    const processTaskId = processTaskAppointment.ownerProcessTaskId;
    const processTask =
      this.entityManager.processTaskRepository.getById(processTaskId);
    if (!processTask) return;

    if (!(await NFCHelper.isNfcEnabled())) {
      void Dialogs.errorDialogTk(
        'generalPages.showUserCalendar.cannotOpenAppointment',
        'generalPages.showUserCalendar.nfcIsNotEnabled'
      );
      return;
    }

    NFCHelper.scanSingleUID((error, tagId) => {
      if (error) {
        if (isTemporaryEntity) {
          this.temporaryAppointmentService.clearTemporaryAppointments();
        }
        return false;
      } else if (tagId) {
        const nfc = this.entityManager.nfcTokenRepository.getByTokenId(tagId);
        const firstRelation = nfc
          ? this.entityManager.nfcTokenToPersonRepository.getByNfcTokenId(
              nfc.id
            )[0]
          : null;
        const person = firstRelation
          ? this.entityManager.personRepository.getById(firstRelation.personId)
          : null;
        if (!person) {
          void Dialogs.errorDialogTk(
            'generalPages.showUserCalendar.cannotOpenAppointment',
            'generalPages.showUserCalendar.personNotFound'
          );
          if (isTemporaryEntity) {
            this.temporaryAppointmentService.clearTemporaryAppointments();
          }
          return false;
        }

        if (!isTemporaryEntity) {
          void this.processTaskLoggingService.logAppointmentOpenedByPerson(
            processTask,
            entryId,
            person.id
          );
          this.router.navigateToRoute('show_process_appointment', {
            appointment_id: entryId
          });
        } else {
          this.router.navigateToRoute('show_process_appointment', {
            appointment_id: entryId,
            log_opened_by: person.id
          });
        }
      }
      return true;
    });
  }

  private handleCalendarModeChanged(
    event: SelectChangedEvent<
      ProcessTaskAppointmentCalendarWidgetMode,
      ValueWithLabel<ProcessTaskAppointmentCalendarWidgetMode>
    >
  ): void {
    this.operationsFieldUseCalendarSettingsService.modifySettings({
      calendarMode:
        event.detail.value ??
        ProcessTaskAppointmentCalendarWidgetMode.SINGLE_DAY
    });
  }

  @computedFrom()
  protected get viewModeChoices(): Array<
    ValueWithLabel<ProcessTaskAppointmentCalendarWidgetMode>
  > {
    return processTaskAppointmentCalendarWidgetModesForFieldUse().map((m) => ({
      value: m,
      label: `modelsDetail.UserCompanySettingModel.operations.processTaskAppointmentCalendarWidgetModes.${m}`
    }));
  }

  @computed(
    activeUserCompanySetting(
      'operationsFieldUse.allowCustomSelectionOfCalendarWidgetMode'
    )
  )
  protected get showCalendarViewModeSelection(): boolean {
    return (
      this.activeUserCompanySettingService.getSettingProperty(
        'operationsFieldUse.allowCustomSelectionOfCalendarWidgetMode'
      ) ?? false
    );
  }

  @computed(
    activeUserCompanySetting(
      'operationsFieldUse.scrollToFirstAppointmentInDayCalendarWidgetMode'
    )
  )
  protected get calendarWidgetConfig(): ProcessTaskAppointmentCalendarWidgetConfig {
    const shouldAutoScrollToFirstAppointmentInSingleDayView =
      this.activeUserCompanySettingService.getSettingProperty(
        'operationsFieldUse.scrollToFirstAppointmentInDayCalendarWidgetMode'
      ) ?? false;
    return {
      shouldAutoScrollToFirstAppointmentInSingleDayView
    };
  }
}
