import { assertNotNullOrUndefined } from '../../../Asserts';
import { DtoUtils, ParentEntityInfo } from '../../BaseEntities/DtoUtils';
import { EntityName } from '../Base/ClientEntityName';
import { createPictureEntityInfo } from './createPictureEntityInfo';
import { PictureDto } from './PictureDto';

export class PictureDtoUtils {
  private static readonly pictureEntityInfo = createPictureEntityInfo({
    additionalFieldInfos: []
  });

  private constructor() {}

  public static getParentEntityInfo({
    pictureDto
  }: {
    pictureDto: PictureDto<string, string>;
  }): ParentEntityInfo {
    if (pictureDto.is_global_project_picture) {
      assertNotNullOrUndefined(
        pictureDto.ownerProjectId,
        `found a picture with is_global_project_picture but it has no ownerProjectId`
      );

      return {
        id: pictureDto.ownerProjectId,
        entityName: EntityName.Project
      };
    }

    return DtoUtils.getParentEntityInfo<PictureDto<string, string>>({
      dto: pictureDto,
      parentEntityIdFieldNames: [
        'picture_of_project',
        'entry',
        'property',
        'galleryThingId',
        'titleThingId',
        'globalThingId',
        'thing',
        'thingSectionId',
        'defect',
        'defectComment'
      ],
      entityInfo: this.pictureEntityInfo
    });
  }
}
