import { autoinject } from 'aurelia-dependency-injection';
import { EntityIdAdapter } from '@record-it-npm/synchro-common';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { ClientEntityIdInfoAdapter } from 'common/Types/BaseEntities/idAdapter/ClientEntityIdInfoAdapter/ClientEntityIdInfoAdapter';
import { SessionService } from '../../../../../services/SessionService';
import { DataStorageHelper } from '../../../../DataStorageHelper/DataStorageHelper';
import { DeviceInfoHelper } from '../../../../DeviceInfoHelper';

@autoinject()
export class EntityIdService {
  private static readonly DEVICE_ID_STORAGE_KEY =
    'EntityIdFormattingService::deviceId';

  private clientEntityIdAdapter: ClientEntityIdAdapter | null = null;

  private deviceId: string = 'xxx';

  constructor(private readonly sessionService: SessionService) {}

  public async init(): Promise<void> {
    const loadedDeviceId = await this.loadDeviceIdFromStorage();
    if (loadedDeviceId) {
      this.deviceId = loadedDeviceId;
    } else {
      await DeviceInfoHelper.waitForDeviceReady();
      this.deviceId = DeviceInfoHelper.getUUID() ?? 'xxx';
      await this.saveDeviceId();
    }

    this.clientEntityIdAdapter = new ClientEntityIdAdapter({
      sessionService: this.sessionService,
      deviceId: this.deviceId
    });
  }

  public destroy(): void {}

  public getClientEntityIdAdapter(): ClientEntityIdAdapter {
    assertNotNullOrUndefined(
      this.clientEntityIdAdapter,
      'no clientEntityIdAdapter available, is the EntityIdService not initialized?'
    );
    return this.clientEntityIdAdapter;
  }

  private async loadDeviceIdFromStorage(): Promise<string | null> {
    let deviceId =
      (await DataStorageHelper.getItem(
        EntityIdService.DEVICE_ID_STORAGE_KEY
      )) ?? null;

    if (!deviceId) {
      deviceId = (await DataStorageHelper.getItem('newEntityIdPrefix')) ?? null; // load from the old storage key of the SynchronizationService

      if (deviceId) {
        await DataStorageHelper.setItem(
          EntityIdService.DEVICE_ID_STORAGE_KEY,
          deviceId
        );
        await DataStorageHelper.removeItem('newEntityIdPrefix');
      }
    }

    return deviceId;
  }

  private async saveDeviceId(): Promise<void> {
    await DataStorageHelper.setItem(
      EntityIdService.DEVICE_ID_STORAGE_KEY,
      this.deviceId
    );
  }
}

export class ClientEntityIdAdapter
  extends ClientEntityIdInfoAdapter
  implements EntityIdAdapter
{
  private readonly sessionService: SessionService;
  private readonly deviceId: string;
  private lastCurrentBrowserSessionId = 0;

  constructor({
    sessionService,
    deviceId
  }: {
    sessionService: SessionService;
    deviceId: string;
  }) {
    super();

    this.sessionService = sessionService;
    this.deviceId = deviceId;
  }

  public generateNewId(): string {
    return `${
      this.deviceId
    }_${this.sessionService.getCurrentSessionId()}_${Date.now()}_${++this
      .lastCurrentBrowserSessionId}`;
  }
}
