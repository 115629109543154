import { computedFrom } from 'aurelia-binding';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SocketService } from '../services/SocketService';

import {
  EventAggregatorPromiseHelper,
  PromiseReactedToEventError
} from './Promise/EventAggregatorPromiseHelper';

export class RequestWithStatus {
  private readonly eventAggregator: EventAggregator;
  private readonly socketService: SocketService;

  private readonly requestWorker;
  private readonly iconClassesForStatus: Partial<Record<Status, string>> = {
    [Status.OK]: 'record-it-request-status-green-icon fal fa-circle-check',
    [Status.ERROR]: 'record-it-request-status-red-icon fal fa-circle-xmark',
    [Status.ABORTED]: 'record-it-request-status-grey-icon fal fa-circle-xmark'
  };

  private internalInProgress = false;
  private internalStatus: Status = Status.NO_STATUS;

  constructor({
    requestWorker,
    eventAggregator,
    socketService
  }: {
    requestWorker: () => Promise<Status>;
    eventAggregator: EventAggregator;
    socketService: SocketService;
  }) {
    this.eventAggregator = eventAggregator;
    this.socketService = socketService;

    this.requestWorker = requestWorker;
  }

  public startRequest(): void {
    this.internalStatus = Status.NO_STATUS;
    if (!this.socketService.isAuthenticated()) {
      this.internalStatus = Status.ABORTED;
      return;
    }

    this.internalInProgress = true;

    void EventAggregatorPromiseHelper.createConnectedPromise<Status>(
      this.eventAggregator,
      this.requestWorker()
    )
      .then((status) => {
        this.internalStatus = status;
      })
      .catch((error) => {
        if (error instanceof PromiseReactedToEventError) {
          this.internalStatus = Status.ABORTED;
        } else {
          this.internalStatus = Status.ERROR;
          throw error;
        }
      })
      .finally(() => {
        this.internalInProgress = false;
      });
  }

  @computedFrom('internalInProgress')
  public get inProgress(): boolean {
    return this.internalInProgress;
  }

  @computedFrom('internalStatus')
  public get status(): Status {
    return this.internalStatus;
  }

  @computedFrom('status')
  public get iconClass(): string | null {
    return this.iconClassesForStatus[this.internalStatus] ?? null;
  }
}

export enum Status {
  NO_STATUS = 'no_status',
  OK = 'ok',
  ERROR = 'error',
  ABORTED = 'aborted'
}
