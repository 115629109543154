import { AppEntityRepository } from '../../base/AppEntityRepository';
import { BaseEntity } from '../BaseEntity';
import { Project } from '../Project/types';
import { QuestionCategory } from '../QuestionCategory/types';
import { EntityName } from '../types';
import { ProjectQuestionCategory } from './types';

export class ProjectQuestionCategoryRepository extends AppEntityRepository<EntityName.ProjectQuestionCategory> {
  public createFromQuestionCategory(
    questionCategory: Pick<
      QuestionCategory,
      'id' | 'name' | 'ownerUserGroupId'
    >,
    project: Pick<Project, 'id' | 'ownerUserGroupId'>,
    options?: Pick<Partial<BaseEntity>, 'shadowEntity' | 'temporaryGroupName'>
  ): ProjectQuestionCategory {
    return this.create({
      name: questionCategory.name,
      ownerUserGroupId: project.ownerUserGroupId,
      createdFromQuestionCategoryId: questionCategory.id,
      ownerProjectId: project.id,
      temporaryGroupName: options?.temporaryGroupName,
      shadowEntity: options?.shadowEntity
    });
  }

  public getByProjectId(projectId: string): Array<ProjectQuestionCategory> {
    return this.getAll().filter((p) => p.ownerProjectId === projectId);
  }
}
