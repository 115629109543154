import { BaseEntityInfoUtils, EntityInfo } from '@record-it-npm/synchro-common';
import { AppCommonSynchronizationEnvironmentTypes } from '../../AppCommonSynchronizationEnvironmentTypes';
import { CreateEntityInfoOptions } from '../../BaseEntities/CreateEntityInfoOptions';
import { EntityName } from '../../BaseEntities/EntityName';
import { ProjectSubEntityUtils } from '../../BaseEntities/ProjectSubEntityUtils';
import { ProjectQuestionDto } from './ProjectQuestionDto';

export function createProjectQuestionEntityInfo<
  TEntity extends ProjectQuestionDto<string, string>
>(
  options: CreateEntityInfoOptions<ProjectQuestionDto<string, string>, TEntity>
): EntityInfo<
  AppCommonSynchronizationEnvironmentTypes,
  EntityName.ProjectQuestion,
  TEntity
> {
  return {
    entityName: EntityName.ProjectQuestion,
    synchronizeRelativeTo: [],
    trackGlobalRevision: true,
    fieldInfos: BaseEntityInfoUtils.createFieldInfos<
      AppCommonSynchronizationEnvironmentTypes,
      ProjectQuestionDto<string, string>,
      TEntity
    >(
      {
        ...ProjectSubEntityUtils.createBaseFieldInfos(),
        text: {
          name: 'text',
          defaultValue: ''
        },
        choices: {
          name: 'choices',
          innerInfo: [
            {
              choiceText: {
                name: 'choiceText'
              },
              actions: {
                name: 'actions',
                innerInfo: [
                  {
                    actionType: {
                      name: 'actionType'
                    },
                    questionId: {
                      name: 'questionId',
                      defaultValue: null
                    },
                    questionSetId: {
                      name: 'questionSetId',
                      defaultValue: null
                    },
                    redirectButtonText: {
                      name: 'redirectButtonText',
                      defaultValue: null
                    },
                    redirectUrl: {
                      name: 'redirectUrl',
                      defaultValue: null
                    },
                    dialogTitle: {
                      name: 'dialogTitle',
                      defaultValue: null
                    },
                    dialogText: {
                      name: 'dialogText',
                      defaultValue: null
                    },
                    dialogIconType: {
                      name: 'dialogIconType',
                      defaultValue: null
                    }
                  }
                ],
                defaultValue: () => []
              }
            }
          ],
          defaultValue: () => []
        },
        answer: {
          name: 'answer',
          defaultValue: null
        },
        note: {
          name: 'note',
          defaultValue: null
        },
        projectQuestionCategoryId: {
          name: 'projectQuestionCategoryId',
          ref: EntityName.ProjectQuestionCategory,
          defaultValue: null
        },
        projectQuestionSetId: {
          name: 'projectQuestionSetId',
          ref: EntityName.ProjectQuestionSet
        },
        units: {
          name: 'units',
          innerInfo: [
            {
              title: {
                name: 'title'
              },
              unit: {
                name: 'unit'
              },
              value: {
                name: 'value',
                defaultValue: ''
              }
            }
          ],
          defaultValue: () => []
        },
        recommendationTemplates: {
          name: 'recommendationTemplates',
          innerInfo: [
            {
              content: {
                name: 'content'
              }
            }
          ],
          defaultValue: () => []
        },
        createdFromQuestionId: {
          name: 'createdFromQuestionId',
          ref: EntityName.Question,
          defaultValue: null
        }
      },
      options.additionalFieldInfos
    )
  };
}
