import { autoinject, PLATFORM } from 'aurelia-framework';
import { SocketService } from '../../services/SocketService';
import { AuthenticationService } from '../../services/AuthenticationService';

@autoinject()
export class finish_invite {
  protected email: string = '';

  protected emailErrorTextTk: string = '';

  protected password: string = '';

  protected showPassword: boolean = false;

  protected passwordErrorTextTk: string = '';

  protected repeatPassword: string = '';

  protected showRepeatPassword: boolean = false;

  protected repeatPasswordErrorTextTk: string = '';

  protected isLoading: boolean = false;

  protected isDisabled: boolean = false;

  protected hasSuccessfullyRegistered: boolean = false;

  private resetPasswordToken: string = '';

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly socketService: SocketService
  ) {}

  public async activate(params: { token: string }): Promise<void> {
    const data = await this.authenticationService.loginWithInviteToken(
      params.token
    );
    if (data) {
      this.resetPasswordToken = data.token;
      this.email = data.email;
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }

  private resetErrorMessages(): void {
    this.passwordErrorTextTk = '';
    this.repeatPasswordErrorTextTk = '';
  }

  protected handleToggleShowPasswordClick(): void {
    this.showPassword = !this.showPassword;
  }

  protected async handleFinishInviteClick(): Promise<void> {
    this.resetErrorMessages();

    if (this.password === '') {
      this.passwordErrorTextTk =
        'generalPages.finishInvite.inputErrors.passwordMissing';
      return;
    }

    if (this.repeatPassword === '') {
      this.repeatPasswordErrorTextTk =
        'generalPages.finishInvite.inputErrors.passwordMissing';
      return;
    }

    if (this.password !== this.repeatPassword) {
      this.repeatPasswordErrorTextTk =
        'generalPages.finishInvite.inputErrors.passwordsDontMatch';
      return;
    }

    this.isLoading = true;

    await this.authenticationService.resetPassword(
      this.resetPasswordToken,
      this.password,
      this.repeatPassword
    );
    await this.socketService.authenticate();
    this.authenticationService.navigateToNewRoot(
      'edit_via_worker_defects',
      PLATFORM.moduleName('pageRoots/app/app')
    );
  }
}
