import { autoinject } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { SessionService } from '../../../../services/SessionService';
import { SubscriptionManagerService } from '../../../../services/SubscriptionManagerService';
import { EventDispatcher } from '../../../EventDispatcher/EventDispatcher';
import { SubscriptionManager } from '../../../SubscriptionManager';
import { Disposable } from '../../../Utils/DisposableContainer';
import { Utils } from '../../../Utils/Utils';
import { AppEntityManager } from '../AppEntityManager';
import { EntityName } from '../types';
import { User } from './types';

@autoinject()
export class CurrentUserService {
  private currentWebUserId: string | null = null;

  private currentUser: User | null = null;
  private initialized: boolean = false;
  private eventDispatcher: EventDispatcher<EventDispatcherConfig> =
    new EventDispatcher();

  private subscriptionManager: SubscriptionManager;

  constructor(
    private readonly entityManager: AppEntityManager,
    private readonly sessionService: SessionService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async init(): Promise<void> {
    const updateCurrentUserRateLimited = Utils.rateLimitFunction(
      this.updateCurrentUser.bind(this),
      0
    );
    this.subscriptionManager.addDisposable(
      updateCurrentUserRateLimited.toCancelDisposable()
    );
    this.subscriptionManager.subscribeToModelChanges(
      EntityName.User,
      updateCurrentUserRateLimited,
      0
    );

    this.sessionService.subscribeToCurrentUserChanged(this, (webUser) => {
      this.updateCurrentWebUserId(webUser?.id ?? null);
    });
    await this.loadCurrentUser();

    this.initialized = true;
  }

  public destroy(): void {
    this.sessionService.removeEventListenersByContext(this);
    this.subscriptionManager.disposeSubscriptions();
    this.initialized = false;
  }

  public subscribeToCurrentUserChanged(
    callback: (currentUser: User | null) => void
  ): Disposable {
    this.assertInitialized();

    return this.eventDispatcher.addDisposableEventListener(
      'currentUserChanged',
      callback
    );
  }

  public bindCurrentUser(callback: (user: User | null) => void): Disposable {
    const disposable = this.subscribeToCurrentUserChanged(callback);
    callback(this.getCurrentUser());
    return disposable;
  }

  public waitForCurrentUser(): Promise<User> {
    this.assertInitialized();

    return new Promise((resolve) => {
      const currentUser = this.getCurrentUser();
      if (currentUser) {
        resolve(currentUser);
        return;
      }

      const context = Symbol('context');
      this.eventDispatcher.addEventListener(
        context,
        'currentUserChanged',
        (newCurrentUser) => {
          if (newCurrentUser) {
            this.eventDispatcher.removeEventListenersByContext(context);
            resolve(newCurrentUser);
          }
        }
      );
    });
  }

  public getCurrentUser(): User | null {
    this.assertInitialized();

    return this.currentUser;
  }

  public getRequiredCurrentUser(): User {
    this.assertInitialized();

    assertNotNullOrUndefined(this.currentUser, 'no currentUser available');
    return this.currentUser;
  }

  private async loadCurrentUser(): Promise<void> {
    const currentWebUser = await this.sessionService.getCurrentUser();
    this.updateCurrentWebUserId(currentWebUser?.id ?? null);
  }

  private updateCurrentWebUserId(currentWebuserId: string | null): void {
    this.currentWebUserId = currentWebuserId;
    this.updateCurrentUser();
  }

  private updateCurrentUser(): void {
    const currentUser = this.currentWebUserId
      ? this.entityManager.userRepository.getById(this.currentWebUserId)
      : null;
    this.setCurrentUser(currentUser);
  }

  private setCurrentUser(user: User | null): void {
    this.currentUser = user;
    this.eventDispatcher.dispatchEvent('currentUserChanged', this.currentUser);
  }

  private assertInitialized(): void {
    if (!this.initialized) {
      console.error(new Error().stack);

      throw new Error(
        "can't use the CurrentUserService because it isn't initialized"
      );
    }
  }
}

type EventDispatcherConfig = {
  currentUserChanged: User | null;
};
