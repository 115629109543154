import { autoinject, bindable } from 'aurelia-framework';
import { assertNotNullOrUndefined } from 'common/Asserts';
import { Dialogs } from '../../../classes/Dialogs';
import { AppEntityManager } from '../../../classes/EntityManager/entities/AppEntityManager';
import { ProcessTaskGroupAuthorization } from '../../../classes/EntityManager/entities/ProcessTaskGroupAuthorization/types';
import { EntityName } from '../../../classes/EntityManager/entities/types';
import { computed } from '../../../hooks/computed';
import { expression, model } from '../../../hooks/dependencies';
import { subscribableLifecycle } from '../../../hooks/subscribableLifecycle';
import { EntityNameToPermissionsHandle } from '../../../services/PermissionsService/entityNameToPermissionsConfig';
import { PermissionsService } from '../../../services/PermissionsService/PermissionsService';

@autoinject()
export class ProcessTaskGroupAuthorizationsWidgetListItem {
  @bindable()
  public processTaskGroupAuthorization: ProcessTaskGroupAuthorization | null =
    null;

  @subscribableLifecycle()
  protected readonly processTaskGroupAuthorizationPermissionsHandle: EntityNameToPermissionsHandle[EntityName.ProcessTaskGroupAuthorization];

  constructor(
    private readonly entityManager: AppEntityManager,
    permissionsService: PermissionsService
  ) {
    this.processTaskGroupAuthorizationPermissionsHandle =
      permissionsService.getPermissionsHandleForProperty({
        entityName: EntityName.ProcessTaskGroupAuthorization,
        context: this as ProcessTaskGroupAuthorizationsWidgetListItem,
        propertyName: 'processTaskGroupAuthorization'
      });
  }

  protected handleDeleteClicked(): void {
    const processTaskGroupAuthorization = this.processTaskGroupAuthorization;
    assertNotNullOrUndefined(
      processTaskGroupAuthorization,
      "can't ProcessTaskGroupAuthorizationsWidgetListItem.handleDeleteClicked"
    );

    void Dialogs.deleteEntityDialog(
      processTaskGroupAuthorization,
      EntityName.ProcessTaskGroupAuthorization
    ).then(() => {
      this.entityManager.processTaskGroupAuthorizationRepository.delete(
        processTaskGroupAuthorization
      );
    });
  }

  @computed(
    expression('processTaskGroupAuthorization.userId'),
    model(EntityName.User)
  )
  protected get userName(): string {
    if (!this.processTaskGroupAuthorization) {
      return '';
    }

    const user = this.entityManager.userRepository.getById(
      this.processTaskGroupAuthorization.userId
    );

    return user?.username ?? '';
  }

  @computed(
    expression(
      'processTaskGroupAuthorization.processConfigurationAuthorizationTypeId'
    ),
    model(EntityName.ProcessConfigurationAuthorizationType)
  )
  protected get authorizationTypeName(): string {
    if (!this.processTaskGroupAuthorization) {
      return '';
    }

    const authorizationType =
      this.entityManager.processConfigurationAuthorizationTypeRepository.getById(
        this.processTaskGroupAuthorization
          .processConfigurationAuthorizationTypeId
      );

    return authorizationType?.name ?? '';
  }
}
