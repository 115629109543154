import { assertNotNullOrUndefined } from 'common/Asserts';
import { PromiseContainer } from 'common/PromiseContainer/PromiseContainer';

import { AppEntityManager } from '../../classes/EntityManager/entities/AppEntityManager';
import { Picture } from '../../classes/EntityManager/entities/Picture/types';
import { PictureFilePathService } from '../../classes/EntityManager/entities/PictureFile/PictureFilePathService';
import { SavePictureFileDataUrlService } from '../../classes/EntityManager/entities/PictureFile/SavePictureFileDataUrlService';
import { PictureFile } from '../../classes/EntityManager/entities/PictureFile/types';
import { EntityName } from '../../classes/EntityManager/entities/types';
import { ImageHelper } from '../../classes/ImageHelper';
import { SubscriptionManager } from '../../classes/SubscriptionManager';
import { SvgLoader } from '../../classes/Svg/SvgLoader';
import { SketcherOverlayImageHandler } from '../../drawingComponents/sketcher-overlay/SketcherOverlayImageHandler';
import { SubscriptionManagerService } from '../SubscriptionManagerService';

export class PictureSketcherOverlayImageHandler extends SketcherOverlayImageHandler {
  private backgroundImageLoader: BackgroundImageLoader;

  constructor(
    private readonly picture: Picture,
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService,
    private readonly savePictureFileDataUrlService: SavePictureFileDataUrlService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    super();

    this.backgroundImageLoader = new BackgroundImageLoader(
      picture,
      entityManager,
      pictureFilePathService,
      subscriptionManagerService
    );
  }

  public async load(image: HTMLImageElement): Promise<void> {
    await this.backgroundImageLoader.load(image);
  }

  public destroy(): void {
    this.backgroundImageLoader.destroy();
  }

  public async waitForImageLoad(): Promise<void> {
    await this.backgroundImageLoader.waitForImageLoad();
  }

  public async loadSketch(): Promise<SVGSVGElement | null> {
    const loader = new SketchLoader(
      this.picture,
      this.entityManager,
      this.pictureFilePathService
    );
    return loader.loadSketch();
  }

  public async save(
    sketchDataUrl: string,
    sketchDataUrlForCanvas: string
  ): Promise<void> {
    const url = await this.getCroppedOrOriginalPictureFileUrl();
    assertNotNullOrUndefined(url, 'no original or cropped picture found');

    const croppedOrOriginalImage = await ImageHelper.loadImage(url);

    const sketchImage = await ImageHelper.loadImage(sketchDataUrlForCanvas);

    this.savePictureFileDataUrlService.saveEditedPictureDataUrl(
      this.picture,
      ImageHelper.mergeImages(croppedOrOriginalImage, sketchImage)
    );

    this.savePictureFileDataUrlService.saveSketchPictureDataUrl(
      this.picture,
      sketchDataUrl
    );
  }

  private async getCroppedOrOriginalPictureFileUrl(): Promise<string | null> {
    let pictureFile =
      this.entityManager.pictureFileRepository.getCroppedPictureFileByPictureId(
        this.picture.id
      );
    if (!pictureFile) {
      pictureFile =
        this.entityManager.pictureFileRepository.getOriginalPictureFileByPictureId(
          this.picture.id
        );
    }

    return pictureFile
      ? await this.pictureFilePathService.getPictureFileSource(pictureFile)
      : null;
  }
}

class BackgroundImageLoader {
  private subscriptionManager: SubscriptionManager;
  private image: HTMLImageElement | null = null;
  private boundHandleImageLoad = this.handleImageLoad.bind(this);
  private boundHandleImageError = this.handleImageError.bind(this);
  private promiseContainer: PromiseContainer<void> = new PromiseContainer();

  constructor(
    private readonly picture: Picture,
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService,
    subscriptionManagerService: SubscriptionManagerService
  ) {
    this.subscriptionManager = subscriptionManagerService.create();
  }

  public async load(image: HTMLImageElement): Promise<void> {
    this.destroy();

    this.image = image;
    this.image.addEventListener('load', this.boundHandleImageLoad);
    this.image.addEventListener('error', this.boundHandleImageError);
    this.image.src = '';

    this.subscriptionManager.subscribeToModelChanges(
      EntityName.PictureFile,
      () => {
        void this.updateImage();
      }
    );
    await this.updateImage();
  }

  public destroy(): void {
    if (this.image) {
      this.image.removeEventListener('load', this.boundHandleImageLoad);
      this.image.removeEventListener('error', this.boundHandleImageError);
      this.image = null;
    }

    this.subscriptionManager.disposeSubscriptions();
  }

  public async waitForImageLoad(): Promise<void> {
    await this.promiseContainer.create();
  }

  private async updateImage(): Promise<void> {
    assertNotNullOrUndefined(
      this.image,
      "can't PictureSketcherOverlayImageHandler.loadImage without an image"
    );

    const pictureFile = this.getBackgroundPictureFile();
    const newSrc = pictureFile
      ? (await this.pictureFilePathService.getPictureFileSource(pictureFile)) ??
        ''
      : '';
    if (newSrc !== this.image.src) {
      this.promiseContainer.resetPermanentStatus();
      this.image.src = newSrc;
    }
  }

  private getBackgroundPictureFile(): PictureFile | null {
    let pictureFile =
      this.entityManager.pictureFileRepository.getCroppedPictureFileByPictureId(
        this.picture.id
      );
    if (!pictureFile) {
      pictureFile =
        this.entityManager.pictureFileRepository.getOriginalPictureFileByPictureId(
          this.picture.id
        );
    }

    return pictureFile ?? null;
  }

  private handleImageLoad(): void {
    this.promiseContainer.resolvePermanent();
  }

  private handleImageError(): void {
    this.promiseContainer.rejectPermanent(
      new Error(`error loading image "${this.picture.id}"`)
    );
  }
}

class SketchLoader {
  constructor(
    private readonly picture: Picture,
    private readonly entityManager: AppEntityManager,
    private readonly pictureFilePathService: PictureFilePathService
  ) {}

  public async loadSketch(): Promise<SVGSVGElement | null> {
    const sketchPictureFile =
      this.entityManager.pictureFileRepository.getSketchPictureFileByPictureId(
        this.picture.id
      );
    if (sketchPictureFile) {
      return await this.loadSketchPictureFile(sketchPictureFile);
    }

    return null;
  }

  private async loadSketchPictureFile(
    sketchPictureFile: PictureFile
  ): Promise<SVGSVGElement | null> {
    const sketchPictureSrc =
      await this.pictureFilePathService.getPictureFileSource(sketchPictureFile);
    if (!sketchPictureSrc) {
      return null;
    }

    if (sketchPictureFile.file_extension === 'svg') {
      return this.loadSvgSketch(sketchPictureSrc);
    } else {
      return this.loadOldSketch(sketchPictureSrc);
    }
  }

  private async loadSvgSketch(src: string): Promise<SVGSVGElement> {
    const loader = new SvgLoader();
    return await loader.load(src);
  }

  private async loadOldSketch(src: string): Promise<SVGSVGElement> {
    const img = await ImageHelper.loadImage(src);
    return this.createSvgFromImage(img);
  }

  private createSvgFromImage(image: HTMLImageElement): SVGSVGElement {
    const svgElement = Msvg.Msvg.createSvgElement('svg') as SVGSVGElement;
    svgElement.setAttribute(
      'viewBox',
      `0 0 ${image.naturalWidth} ${image.naturalHeight}`
    );
    svgElement.setAttribute('xmlns', Msvg.Utils.Definitions.svgNamespace);
    svgElement.setAttribute(
      'xmlns:xlink',
      Msvg.Utils.Definitions.xlinkNamespace
    );
    svgElement.appendChild(this.createSvgImageFromImage(image));
    return svgElement;
  }

  private createSvgImageFromImage(image: HTMLImageElement): SVGImageElement {
    const svgImage = Msvg.Msvg.createSvgElement('image') as SVGImageElement;
    svgImage.setAttribute('width', image.naturalWidth.toString());
    svgImage.setAttribute('height', image.naturalHeight.toString());
    svgImage.setAttribute('data-old-sketch', '');
    svgImage.setAttributeNS(
      Msvg.Utils.Definitions.xlinkNamespace,
      'href',
      ImageHelper.imageToDataUrl(image)
    );
    return svgImage;
  }
}
